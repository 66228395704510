import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {useDispatch} from 'react-redux'
import { onlyUseKR, notOnlyUseKR } from '../../../../../Reducer/useOnlyKR'


const VIX = (props) => {    
	const { t, i18n } = useTranslation()

	const lang = i18n.language === 'ko'

	const ref220s = useRef()

	const dispatch = useDispatch()

	useEffect(()=> {
		dispatch(onlyUseKR(true))

		return () => {
			dispatch(notOnlyUseKR(false))
		}
	},[])
    
	const findImage = (path) => {
		let imgArray = path.split('images/')
		let img = imgArray[imgArray.length - 1]

		return require(`MainAssets/assets/images/${img}`)
	}

    useEffect(() => {
		if (props.hash && ref220s.current) {
			let location = ref220s.current.offsetTop
			window.scrollTo({ top: location })
		}
	}, [])

    return (
        <>
        <div id="business_panel3" className="business_panel">
            <div className="business_img img6">
                <div className="business_txt">
                    <strong>출입 통제</strong>
                    <p>승인되지 않은 사용자 출입을 제한하여 인명과 자산을 안전하게 보호하고, 출입하는 사용자를 효과적으로 관리하고 제어하기 위한 시스템으로 사용자의 안전성, 보안 및 편의성을 향상시킵니다.</p>
                </div>
            </div>
            <div className="sub_con">
                <strong className="business_tit">왜 필요할까요?</strong>
                <p className="business_use">출입통제 시스템은 다양한 환경에서 사용자에게 보안, 안전, 편의성을 제공하며, 불법 침입 방지, 출입기록 관리 등으로 보안강화, 비인가구역의 출입 통제를 통해 기밀성 보호, 특정 영역 인가자 출입 관리로 근로자의 
                    안전, 서버실, 연구실등 출입 통제로 인한 <span className="highlighter">자산 보호, 근태관리, 식수관리 등을 통한 비즈니스 프로세스 개선, 비인가 출입 방지를 통해 무단 침입, 도난, 범죄등으로 부터 시설 보호에 도움</span>을 줍니다.</p>
                <div className="bsn_martop_80">
                    <strong className="business_tit">이렇게 활용할 수 있습니다.</strong>
                    <ul className="business_dot">
                        <li>비대면, 비접촉, 워크스루를 통한 출입 통제 시스템 제공</li>
                        <li>다중 생체 인식을 통해 보안성 강화 및 사용자 선호에 따른 인식 선택 가능</li>
                        <li>스마트폰을 이용한 편리한 모바일 인증 솔루션 제공</li>
                        <li>산업군 용도별 제품 라인업 완료</li>
                    </ul>
                </div>
            </div>
            
            {/* VIXFace */}
            <div className="product_panel blue_back">
                <div className="sub_con">
                    <div className="product_box clear">
                        <strong className="business_tit">주요 제품</strong>
                        <div className="product_txt w_half pr_0 martop_40"> {/*20240221 클래스 'pr_0', 'martop_40'추가*/}
                            <strong>VIXFace <span className="dis_inblock">(출입인식 단말기)</span></strong>
                            <ul className="business_dot">
                                <li>딥러닝 기반의 얼굴인식 알고리즘을 통해 사용자의 출입을 인가 하는 장치로 출입통제를 필요로 하는 출입구 근처에 주변 출입 제어 장치와 함께 설치하여 해당 구역의 출입을 통제 합니다.</li>
                                <li>다중 인식을 통해 사용자의 편리성을 향상 및 비접촉으로 사용자의 불안감을 해소하였습니다.</li>
                                <li>모바일 출입을 통해 사용자 및 관리자의 출입 관리 기능을 향상하였으며, 모바일 신분증 전환에 따라 비대면 인증 시장 확장을 기대하고 있습니다.</li>
                            </ul>
                        </div>
                        <div className="product_imgBox w_half">
                            {/* 2020240304 추가 */}
                            <div className="product_img imgVixFace"></div>
                        </div>
                    </div>
                </div>
                <div className="event_box">
                    <div className="sub_con">
                        <strong className="event_tit martop_40">해당 제품</strong>
                        <div className="event_imgBox item_4_box">
                            <div className="event_imgBox_item">
                                <strong>VIXMobi3.5</strong>
                                <div>
                                    <img src={findImage("../assets/images/technology/vixface_01.png")} alt=""/>
                                    <ul className="business_dot martop_20">
                                        <li>모바일 출입 단말기 (LCD 2.5”)</li>
                                        <li>비접촉 출입통제 설치 용이</li>
                                        <li>중/소규모 사업장 적합</li>
                                        <li>RF카드, 모바일 인식</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="event_imgBox_item">
                                <strong>VIXFace2.8</strong>
                                <div>
                                    <img src={findImage("../assets/images/technology/vixface_02.png")} alt=""/>
                                    <ul className="business_dot martop_20">
                                        <li>슬림형 단말기 (LCD 2.8”)</li>
                                        <li>출입구 프레임에 설치 용이</li>
                                        <li>소규모 사업장 적합</li>
                                        <li>RF카드, 비밀번호 인식</li>
                                        <li>지문/얼굴/모바일 인식</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="event_imgBox_item">
                                <strong>VIXFace5.0</strong>
                                <div>
                                    <img src={findImage("../assets/images/technology/vixface_03.png")} alt=""/>
                                    <ul className="business_dot martop_20">
                                        <li>벽부형 단말기 (LCD 5”)</li>
                                        <li>출입구 벽에 설치 용이</li>
                                        <li>중/소규모 사업장 적합</li>
                                        <li>RF카드, 비밀번호</li>
                                        <li>장정맥/얼굴/모바일 인식</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="event_imgBox_item">
                                <strong>VIXFace8.0</strong>
                                <div>
                                    <img src={findImage("../assets/images/technology/vixface_04.png")} alt=""/>
                                    <ul className="business_dot martop_20">
                                        <li>고급형 단말기 (LCD 8”)</li>
                                        <li>스피드게이트 설치 용이</li>
                                        <li>대규모 사업장 적합</li>
                                        <li>RF카드, 비밀번호</li>
                                        <li>지문/얼굴/모바일 인식</li>
                                        <li>장정맥 인식 (옵션)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="event_box martop_50">
                    <div className="sub_con">
                        <strong className="event_tit">대표 인증</strong>
                        <ul className="event_list">
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixface_icon01.png")} alt=""/>
                                    <span className="martop_5">지문</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixface_icon02.png")} alt=""/>
                                    <span className="martop_5">RF카드</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixface_icon03.png")} alt=""/>
                                    <span className="martop_5">얼굴</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixface_icon04.png")} alt=""/>
                                    <span className="martop_5">장정맥</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixface_icon05.png")} alt=""/>
                                    <span className="martop_5">BLE</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixface_icon06.png")} alt=""/>
                                    <span className="martop_5">NFC</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* VIXSap */}
            <div className="product_panel blue_back">
                <div className="sub_con">
                    <div className="product_box clear">
                        <strong className="business_tit">주요 제품</strong>
                        <div className="product_txt w_half pr_0 martop_40"> {/*20240221 클래스 'pr_0', 'martop_40'추가*/}
                            <strong>VIXSap <span className="dis_inblock">(스마트출입통제솔루션)</span></strong>
                            <ul className="business_dot">
                                <li>물리보안 AI통합플랫폼을 위해 On Premise 및 Cloud 2가지 타입으로구성</li>
                                <li>다양한 인증 수단을 통한 출입기록 관리 및 모니터링 통계 지원</li>
                                <li>일반구역, 제한구역, 금지구역, 엘리베이터등 출입통제 구역 관리</li>
                                <li>사용자 App 및 관리자 App 제공</li>
                            </ul>
                        </div>
                        <div className="product_imgBox w_half">
                            {/* 2020240304 추가 */}
                            <div className="product_img imgVixSap"></div>
                            
                        </div>
                    </div>
                </div>
                <div className="event_box">
                    <div className="sub_con">
                        <strong className="event_tit martop_40">사용 예시</strong>
                        <div className="event_imgBox item_3_box">
                            <div className="event_imgBox_item">
                                <strong>로비/메인 출입관리</strong>
                                <div>
                                    <div className="vixsap_img img1"></div>
                                </div>
                            </div>
                            <div className="event_imgBox_item">
                                <strong>건물내부 출입관리</strong>
                                <div>
                                    <div className="vixsap_img img2"></div>
                                </div>
                            </div>
                            <div className="event_imgBox_item">
                                <strong>출입통제 시스템</strong>
                                <div>
                                    <div className="vixsap_img img3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="event_box martop_50">
                    <div className="sub_con">
                        <strong className="event_tit">대표 기능</strong>
                        <ul className="event_list">
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixsap_icon01.png")} alt=""/>
                                    <span className="martop_5">출입관리</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixsap_icon02.png")} alt=""/>
                                    <span className="martop_5">근태관리</span> {/*20240304 오타수정*/}
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixsap_icon03.png")} alt=""/>
                                    <span className="martop_5">식수관리</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixsap_icon04.png")} alt=""/>
                                    <span className="martop_5">통계관리</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixsap_icon05.png")} alt=""/>
                                    <span className="martop_5">방문자 관리</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixsap_icon06.png")} alt=""/>
                                    <span className="martop_5">방범관리</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* VIXGate */}
            <div className="product_panel blue_back">
                <div className="sub_con">
                    <div className="product_box clear">
                        <strong className="business_tit">주요 제품</strong>
                        <div className="product_txt w_half pr_0 martop_40"> {/*20240221 클래스 'pr_0', 'martop_40'추가*/}
                            <strong>VIXGate <span className="dis_inblock">(출입통제게이트)</span></strong>
                            <ul className="business_dot">
                                <li>Brushless Motor 적용으로 부드럽고 조용한 작동</li>
                                <li>Compact Design으로 보안과 편리한 공간 제공</li>
                                <li>강력한 Tailgating 감지와 안전을 위한 10 Pair 적외선 센서 (VIXGate7.0/7.2)</li>
                                <li>Acrylic 재질의 Barrier 적용으로 안전성 확보(VIXGate7.0/7.2)</li>
                                <li>인체 공학적 디자인으로 빠른 카드 및 지문 인증</li>
                                <li>양방향 LED 통로 표시, KC 인증, MAS 성능검사</li>
                                <li>구조와 연결을 단순화하여 간편한 설치 및 편리한 유지관리</li>
                            </ul>
                        </div>
                        <div className="product_imgBox w_half">
                            {/* 2020240304 추가 */}
                            <div className="product_img imgVixGate"></div>                            
                        </div>
                    </div>
                </div>
                <div className="event_box">
                    <div className="sub_con">
                        <strong className="event_tit martop_40">사용 예시</strong>
                        <div className="event_imgBox item_3_box">
                            <div className="event_imgBox_item">
                                <strong>VIXGate7.0</strong>
                                <div>
                                    <div className="vixgate_img img1"></div>
                                    <span className="img_tit">Single Type Flap Gate</span>
                                </div>
                            </div>
                            <div className="event_imgBox_item">
                                <strong>VIXGate7.2</strong>
                                <div>
                                    <div className="vixgate_img img2"></div>
                                    <span className="img_tit">Dual Type Flap Gate</span>
                                </div>
                            </div>
                            <div className="event_imgBox_item">
                                <strong>VIXGate2.0</strong>
                                <div>
                                    <div className="vixgate_img img3"></div>
                                    <span className="img_tit">Single Type Turn Gate</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="event_box martop_50">
                    <div className="sub_con">
                        <strong className="event_tit">주요 사용처</strong>
                        <ul className="event_list">
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixgate_icon01.png")} alt=""/>
                                    <span className="martop_5">공공기관</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixgate_icon02.png")} alt=""/>
                                    <span className="martop_5">오피스</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixgate_icon03.png")} alt=""/>
                                    <span className="martop_5">오피스</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixgate_icon04.png")} alt=""/>
                                    <span className="martop_5">건설</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixgate_icon05.png")} alt=""/>
                                    <span className="martop_5">의료</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={findImage("../assets/images/icon/vixgate_icon06.png")} alt=""/>
                                    <span className="martop_5">리테일</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
        </>
    )
}

export default VIX
