import react,{useEffect} from 'react'
import { BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom'
import Login from 'Views/Admin/Login'
import adminMain from 'Views/Admin/Main'
import PostNotice from '../Views/Admin/Main/Notice/PostNotice'
//import 'AdminAssets/assets/css/common.css'
//import 'AdminAssets/assets/css/datepicker.css'
import PrivateRoute from './PrivateRouter'



const AdminRouter = () => {


	return (
		<>
				<meta charSet="UTF-8" />
					<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
						<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
							<meta name="format-detection" content="telephone=no, address=no, email=no" />
								<title>[IntelliVIX] admin</title>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<Switch>

					<Route exact path={'/admin/login'} component={Login}/>
					<PrivateRoute exact path={'/admin'} component={() => { return (<Redirect to='/admin/login' />) }} />

					<PrivateRoute exact path={'/admin/main'} component={adminMain} />
					<PrivateRoute exact path={'/admin/main/notice/post'} component={PostNotice} />


				</Switch>
			</BrowserRouter>
		</>
	)
}

export default AdminRouter
