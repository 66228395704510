export default {
	pad (n, width) {
		n = n + ''
		return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
	},
	mobilePhoneFormatter (num) {
		let number = num.replace(/[^0-9]/g, '')
		let phone = ''
		if (number.length < 4) {
			return number
		} else if (number.length < 7) {
			phone += number.substr(0, 3)
			phone += '-'
			phone += number.substr(3)
		} else if (number.length < 11) {
			phone += number.substr(0, 3)
			phone += '-'
			phone += number.substr(3, 3)
			phone += '-'
			phone += number.substr(6)
		} else {
			phone += number.substr(0, 3)
			phone += '-'
			phone += number.substr(3, 4)
			phone += '-'
			phone += number.substr(7)
		}
		return phone
	},
	telePhoneFormatter (num) {
		let number = num.replace(/[^0-9]/g, '')
		let phone = ''
		if (number.length === 8) {
			phone += number.substr(0, 4)
			phone += '-'
			phone += number.substr(4)
		} else if (number.length === 9) {
			phone += number.substr(0, 2)
			phone += '-'
			phone += number.substr(2, 3)
			phone += '-'
			phone += number.substr(5)
		} else {
			phone = this.mobilePhoneFormatter(num)
		}
		return phone
	},
	getArrayPhoneNumber (phoneNumberString) {
		const phoneNumber = this.telePhoneFormatter(phoneNumberString.onlyNumber())
		return phoneNumber.split('-')
	},
	isValidMobilePhoneNumber (phoneNumber) {
		// let phoneRegExp = /^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$/;
		let phoneRegExp = /^(?:(010\d{4})|(01[1|6|7|8|9]\d{3,4}))(\d{4})$/
		return phoneRegExp.test(phoneNumber)
	},
	isSmartPhoneNumber (phoneNumber) {
		return phoneNumber && phoneNumber.length >= 3 && phoneNumber.slice(0, 3) === '010'
	},
	priceToString (price) {
		return price.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
}

