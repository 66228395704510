import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'


const History = () => {

	const [selectedMenu, setSelectedMenu] = useState('current')
	const { t, i18n } = useTranslation()

	return i18n.language === 'ko' ? (
		<>
			<div className="sub_imgBox img5" />
			<div className="sub_con">
				<div className="history_tab">
					<button className={selectedMenu === 'current' ? 'active' : ''} onClick={()=> setSelectedMenu('current')}>현재-2017</button>
					<button className={selectedMenu === 'past' ? 'active' : ''} onClick={()=> setSelectedMenu('past')}>2016-2000</button>
				</div>

				<div className={`history_panel type1 ${selectedMenu === 'current' ? '' : 'dis_none'}`}>
					{/*20240221 HJ 2023년도 연혁 추가*/}
					<div className="clear history_box">
                    <div className="history_tit left">2023</div>
                    <ul className="history_list right">
                        <li>
                            <strong>12월</strong>
                            <div className="dis_inblock">
                                <p>인텔리빅스 - 웹케시 ATM 이상행동탐지 솔루션 마케팅 업무제휴 체결</p>
                                <p>인텔리빅스 - 국민대 산학협력 MOU 체결</p>
                                <p>신기술(NEP) 인증 획득</p>
                                <p>산업통상자원부 산업융합 선도기업 선정</p>
                            </div>
                        </li>
                        <li>
                            <strong>11월</strong>
                            <div className="dis_inblock">
                                <p>인텔리빅스 - 한국과학기술연구원(KIST) 기술협력 MOU 체결</p>
                                <p>Good AI Award 특별상 수상</p>
                                <p>중소벤처기업부 장관상 수상 (디지털경영혁신대상)</p>
                            </div>
                        </li>
                        <li>
                            <strong>10월</strong>
                            <div className="dis_inblock">
                                <p>서울시 핼로윈 대비 훈련 'AI 인파감지시스템' 시연</p>
                                <p>중소벤처기업부 장관상 수상(중소기업 기술 · 경영혁신대전)</p>
                                <p>국방부 우수상용품 시범사용 평가 A등급 획득</p>
                            </div>
                        </li>
                        <li>
                            <strong>09월</strong>
                            <div className="dis_inblock">
                                <p>행정안전부 장관상 수상(2023 대한민국 안전기술대상)</p>
                            </div>
                        </li>
                        <li>
                            <strong>08월</strong>
                            <div className="dis_inblock">
                                <p>최은수 인텔리빅스 대표이사 취임</p>
                            </div>
                        </li>
                        <li>
                            <strong>06월</strong>
                            <div className="dis_inblock">
                                <p>'2023년도 사회복합재난 대응 기술개발 공모사업' 주관기업 선정</p>
                            </div>
                        </li>
                        <li>
                            <strong>05월</strong>
                            <div className="dis_inblock">
                                <p>과학기술정보통신부 장관상 수상(과학기술 진흥유공)</p>
                            </div>
                        </li>
                        <li>
                            <strong>04월</strong>
                            <div className="dis_inblock">
                                <p>KISA 바이오인식시스템 성능시험 인증 획득</p>
                            </div>
                        </li>
                        <li>
                            <strong>03월</strong>
                            <div className="dis_inblock">
                                <p>특허청 직무발명보상우수기업 인증 획득</p>
                            </div>
                        </li>
                        <li>
                            <strong>01월</strong>
                            <div className="dis_inblock">
                                <p>2023 Emerging AI +X Top 100, 인텔리빅스 선정</p>
                            </div>
                        </li>
                    </ul>
                	</div>


					{/*230130 HJ 연혁추가 2022년도 */}
					<div className="clear history_box">
						<div className="history_tit left">2022</div>
						<ul className="history_list right">
							<li>
								<strong>12월</strong>
								<div className="dis_inblock">
									<p>제9회 대한민국 SW제품 품질대상 수상</p>
									<p>신한은행 AI 이상행동탐지 ATM 기술 공급</p>
								</div>
							</li>
							<li>
								<strong>11월</strong>
								<div className="dis_inblock">
									<p>2022년 대한민국 혁신 정보보호 제품 대상 수상(물리보안)</p>
								</div>
							</li>
							<li>
								<strong>10월</strong>
								<div className="dis_inblock">
									<p>코오롱글로벌 MOU 체결</p>
									<p>중소기업혁신대전(ITS 2022) K-혁신기업 선정</p>
								</div>
							</li>
							<li>
								<strong>07월</strong>
								<div className="dis_inblock">
									<p>과학기술정보통신부 상반기 우수연구개발 혁신제품 선정</p>
								</div>
							</li>
							<li>
								<strong>06월</strong>
								<div className="dis_inblock">
									<p>2022년 인공지능 보안시제품 개발사업 수행(KISA)</p>
								</div>
							</li>
							<li>
								<strong>04월</strong>
								<div className="dis_inblock">
									<p>엔비디아(NVIDIA) 메트로폴리스 파트너사 선정</p>
								</div>
							</li>
							<li>
								<strong>03월</strong>
								<div className="dis_inblock">
									<p>서울디지털재단 AI기반 건축공사장 위험요소 관제솔루션 구축 사업 수주</p>
								</div>
							</li>
							<li>
								<strong>02월</strong>
								<div className="dis_inblock">
									<p>Emerging AI+X Top 100선정 (NIA)</p>
								</div>
							</li>
						</ul>
					</div>
					<div className="clear history_box">
						<div className="history_tit left">2021</div>
						<ul className="history_list right">
							<li>
								<strong>12월</strong>
								<div className="dis_inblock">
									<p>SW 유공 수상 (대통령상 수상)</p>
								</div>
							</li>
							<li>
								<strong>11월</strong>
								<div className="dis_inblock">
									<p>AI Edge Box 출시</p>
									<p>산업통상자원부 산업융합 혁신 품목 지정 (AI Edge Box)</p>
								</div>
							</li>
							<li>
								<strong>10월</strong>
								<div className="dis_inblock">
									<p>조달청 우수조달제품 지정 (AI Edge Box)</p>
									<p>한국건설기술연구원 돌발상황검지시스템 최상급 획득</p>
								</div>
							</li>
							<li>
								<strong>08월</strong>
								<div className="dis_inblock">
									<p>에스앤피아이 코퍼레이션 산업안전 솔루션 구축</p>
									<p>삼성중공업 선박안전 모니터링 시스템 구축</p>
								</div>
							</li>
							<li>
								<strong>07월</strong>
								<div className="dis_inblock">
									<p>기아자동차(소하리) 중대재해 예방 시스템 구축</p>
									<p>하이퍼랩 합병</p>
									<p>디엘이엔씨 현장통합관리 플랫폼 구축</p>
								</div>
							</li>
							<li>
								<strong>05월</strong>
								<div className="dis_inblock">
									<p>삼성물산(월드컵대교) 산업 안전 솔루션 구축</p>
								</div>
							</li>
							<li>
								<strong>01월</strong>
								<div className="dis_inblock">
									<p>[실증 과제] 2021년 인공지능식별추적시스템 구축 실증 및 검증 트랙1<br/>(AI기반 안면인식 및 이상행동인지 플랫폼 구축)</p>
									<p>일하기 좋은 기업 청년친화 강소기업 선정 (2년 연속)</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear history_box">
						<div className="history_tit left">2020</div>
						<ul className="history_list right">
							<li>
								<strong>11월</strong>
								<div className="dis_inblock">
									<p>AI-Farm, Sow Health Care Project SW 스마트팜 솔루션 사업</p>
								</div>
							</li>
							<li>
								<strong>09월</strong>
								<div className="dis_inblock">
									<p>포스코(포항) 기술 연구소 portable smart 감시 시스템 영상 감시 시스템 구축</p>
								</div>
							</li>
							<li>
								<strong>08월</strong>
								<div className="dis_inblock">
									<p>일본 JR Line 사회적 약자 보호 시스템 구축</p>
								</div>
							</li>
							<li>
								<strong>07월</strong>
								<div className="dis_inblock">
									<p>부천시 지능형 스마트 선별관제 시스템 구축</p>
								</div>
							</li>
							<li>
								<strong>06월</strong>
								<div className="dis_inblock">
									<p>LGU+ 매장 BI 프로젝트 개발 및 납품</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear history_box">
						<div className="history_tit left">2019</div>
						<ul className="history_list right">
							<li>
								<strong>12월</strong>
								<div className="dis_inblock">
									<p>삼성전자 비전기반 개체 인식 솔루션 개발</p>
									<p>일본 소프트뱅크 로보틱스 AI 객체 인식 시스템 구축 사업 Project SKY (사물인식 및 행위인식 시스템 구축)</p>
								</div>
							</li>
							<li>
								<strong>09월</strong>
								<div className="dis_inblock">
									<p>LGU+ 스마트드론 차별화 기술 선행 개발 참여</p>
									<p>TBEYE향 철도 PJ_TAS-MRS 개발 (사회적 약자 보호 시스템)</p>
								</div>
							</li>
							<li>
								<strong>07월</strong>
								<div className="dis_inblock">
									<p>서울시 C-ITS 지능형영상분석 SW 개발 및 납품</p>
								</div>
							</li>
							<li>
								<strong>01월</strong>
								<div className="dis_inblock">
									<p>산업안전 솔루션 220S 출시</p>
									<p>[실증과제] 2019년도 국가 인프라 지능정보화 사업<br/>(빅데이터와 딥러닝을 이용한 스마트 안전사회 구축 솔루션 개발 및 실증(2차년도))</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear history_box">
						<div className="history_tit left">2018</div>
						<ul className="history_list right">
							<li>
								<strong>12월</strong>
								<div className="dis_inblock">
									<p>2018년 GIGAeyes 3rd Party VA솔루션 연동 개발_인텔리빅스</p>
								</div>
							</li>
							<li>
								<strong>05월</strong>
								<div className="dis_inblock">
									<p>AI 지능형 CCTV 플랫폼 구축 (LGU+)</p>
								</div>
							</li>
							<li>
								<strong>04월</strong>
								<div className="dis_inblock">
									<p>[실증 과제] 범부처 GIGA KOREA 사업 (5G 기반의 스마트시티 서비스 개발 및 실증)</p>
								</div>
							</li>
							<li>
								<strong>03월</strong>
								<div className="dis_inblock">
									<p>CJ대한통운 수도권메가허브센터 DMS 구축 (지능형 CCTV) 계약</p>
								</div>
							</li>
							<li>
								<strong>01월</strong>
								<div className="dis_inblock">
									<p>스마트관제 솔루션 출시</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear history_box">
						<div className="history_tit left">2017</div>
						<ul className="history_list right">
							<li>
								<strong>11월</strong>
								<div className="dis_inblock">
									<p>2018 평창동계올림픽 CCTV 통합관제센터 구축</p>
								</div>
							</li>
							<li>
								<strong>10월</strong>
								<div className="dis_inblock">
									<p>대전 동구청 산불 감시 솔루션 납품</p>
								</div>
							</li>
							<li>
								<strong>09월</strong>
								<div className="dis_inblock">
									<p>한국도로공사 사고자동감지시스템 유지관리 사업 참여</p>
									<p>사명 변경 (인텔리빅스)</p>
								</div>
							</li>
							<li>
								<strong>07월</strong>
								<div className="dis_inblock">
									<p>Search (고속검색) 솔루션 출시</p>
								</div>
							</li>
						</ul>
					</div>
				</div>


				<div className={`history_panel type2 ${selectedMenu === 'past' ? '' : 'dis_none'}`}>
					<div className="clear">
						<div className="history_tit left">2016</div>
						<ul className="history_list right">
							<li>
								<strong>09월</strong>
								<div className="dis_inblock">
									<p>R200 (도로유고) 솔루션 출시</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2015</div>
						<ul className="history_list right">
							<li>
								<strong>12월</strong>
								<div className="dis_inblock">
									<p>M200P (차량번호판 인식) 솔루션 / M200F (얼굴인식) 솔루션 출시</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2014</div>
						<ul className="history_list right">
							<li>
								<strong>04월</strong>
								<div className="dis_inblock">
									<p>GOP 중서부 군경계 감시 시스템 구축 사업 참여</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2011</div>
						<ul className="history_list right">
							<li>
								<strong>07월</strong>
								<div className="dis_inblock">
									<p>4대궁 종묘 종합경비시스템 성능개선사업의 영상분석 시스템 납품</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2008</div>
						<ul className="history_list right">
							<li>
								<strong>01월</strong>
								<div className="dis_inblock">
									<p>Intellivix-G100 상품화 완료 및 출시</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2003</div>
						<ul className="history_list right">
							<li>
								<strong>03월</strong>
								<div className="dis_inblock">
									<p>i.Vista, i.Modeler, iFusion2D SW 패키지 상품화 및 출시</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2000</div>
						<ul className="history_list right">
							<li>
								<strong>06월</strong>
								<div className="dis_inblock">
									<p>법인 설립</p>
								</div>
							</li>
						</ul>
					</div>
				</div>

			</div>
		</>
	) : (
		<>
			<div className="sub_imgBox img5" />
			<div className="sub_con">
				<div className="history_tab">
					<button className={selectedMenu === 'current' ? 'active' : ''} onClick={()=> setSelectedMenu('current')}>Now-2017</button>
					<button className={selectedMenu === 'past' ? 'active' : ''} onClick={()=> setSelectedMenu('past')}>2016-2000</button>
				</div>

				<div className={`history_panel type1 ${selectedMenu === 'current' ? '' : 'dis_none'}`}>

					{/*230224 HJ 연혁추가 2022년도 */}
					<div className="clear history_box">
						<div className="history_tit left">2022</div>
						<ul className="history_list right">
							<li>
								<strong>Dec</strong>
								<div className="dis_inblock">
									<p>Win the prize (9th Korea Software Quality) </p>
									<p>Shinhan Bank AI ATM Voice Phising Detection system </p>
								</div>
							</li>
							<li>
								<strong>Nov</strong>
								<div className="dis_inblock">
									<p>Win the prize (2022 Korea innovative information protection product (Physical security) </p>
								</div>
							</li>
							<li>
								<strong>Oct</strong>
								<div className="dis_inblock">
									<p>MOU partnership with kolon global </p>
									<p>Selected as a K-Innovative Company (Ministry of SMEs and Startups) </p>
								</div>
							</li>
							<li>
								<strong>Jul</strong>
								<div className="dis_inblock">
									<p>Certificate of Innovative product (Ministry of science and ICT) </p>
								</div>
							</li>
							<li>
								<strong>Jun</strong>
								<div className="dis_inblock">
									<p>AI Security product development project (KISA) </p>
								</div>
							</li>
							<li>
								<strong>Apr</strong>
								<div className="dis_inblock">
									<p>Join NVIDIA Metropolis Partner Program</p>
								</div>
							</li>
							<li>
								<strong>Mar</strong>
								<div className="dis_inblock">
									<p>Seoul Digital Foundation AI Safety Solution for construction site </p>
								</div>
							</li>
							<li>
								<strong>Feb</strong>
								<div className="dis_inblock">
									<p>Designation of Emerging AI+X Top 100 (NIA)</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear history_box">
						<div className="history_tit left">2021</div>
						<ul className="history_list right">
							<li>
								<strong>Dec</strong>
								<div className="dis_inblock">
									<p>Win the president prize</p>
								</div>
							</li>
							<li>
								<strong>Nov</strong>
								<div className="dis_inblock">
									<p>AI Edge Box release</p>
									<p>Innovative items for industrial convergence designation by The Ministry of Trade, Industry and
										Energy (AI Edge Box)</p>
								</div>
							</li>
							<li>
								<strong>Oct</strong>
								<div className="dis_inblock">
									<p>Designation of excellent procurement products by the Public Procurement Service (AI Edge Box)</p>
									<p>Acquisition the highest level of unexpected situation detection system in Korea Construction
										Technology Research Institute</p>
								</div>
							</li>
							<li>
								<strong>Aug</strong>
								<div className="dis_inblock">
									<p>S&I coporation industrial safety system</p>
									<p>Samsung Heavy Industries intelligent video analytics system</p>
								</div>
							</li>
							<li>
								<strong>Jul</strong>
								<div className="dis_inblock">
									<p>KIA Motors industrial safety system</p>
									<p>DL E&C industrial safety system</p>
								</div>
							</li>
							<li>
								<strong>May</strong>
								<div className="dis_inblock">
									<p>Samsung C&T industrial safety system</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear history_box">
						<div className="history_tit left">2020</div>
						<ul className="history_list right">
							<li>
								<strong>Nov</strong>
								<div className="dis_inblock">
									<p>AI-Farm, Sow Health Care Project SW AI farm</p>
								</div>
							</li>
							<li>
								<strong>Sep</strong>
								<div className="dis_inblock">
									<p>POSCO portable smart monitoring system deployment</p>
								</div>
							</li>
							<li>
								<strong>Aug</strong>
								<div className="dis_inblock">
									<p>Japan JR line intelligent video monitoring system deployment</p>
								</div>
							</li>
							<li>
								<strong>Jul</strong>
								<div className="dis_inblock">
									<p>Bucheon intelligent smart monitoring system deployment</p>
								</div>
							</li>
							<li>
								<strong>Jun</strong>
								<div className="dis_inblock">
									<p>LG U+ Business Intelligence development</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear history_box">
						<div className="history_tit left">2019</div>
						<ul className="history_list right">
							<li>
								<strong>Dec</strong>
								<div className="dis_inblock">
									<p>Samsung vision based object recognition solution development</p>
									<p>Softbank Japan AI object recognition system deployment</p>
								</div>
							</li>
							<li>
								<strong>Jul</strong>
								<div className="dis_inblock">
									<p>Seoul C-ITS intelligent video analytics SW development</p>
								</div>
							</li>
							<li>
								<strong>Jan</strong>
								<div className="dis_inblock">
									<p>Video analytics based industiral safety solution release (VIX-220S) </p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear history_box">
						<div className="history_tit left">2018</div>
						<ul className="history_list right">
							<li>
								<strong>May</strong>
								<div className="dis_inblock">
									<p>AI intelligent CCTV Platform deployment (LG U+)</p>
								</div>
							</li>
							<li>
								<strong>Mar</strong>
								<div className="dis_inblock">
									<p>CJ Logistics Corporation Mega-hub center DMS empolyment</p>
								</div>
							</li>
							<li>
								<strong>Jan</strong>
								<div className="dis_inblock">
									<p>Video analytics based smart monitoring solution release </p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear history_box">
						<div className="history_tit left">2017</div>
						<ul className="history_list right">
							<li>
								<strong>Nov</strong>
								<div className="dis_inblock">
									<p>Pyeongchang Winter Olympics CCTV monitoring center deployment</p>
								</div>
							</li>
							<li>
								<strong>Sep</strong>
								<div className="dis_inblock">
									<p>Korea Expressway Corporation tunnel accident automatic detection system</p>
									<p>Changing the company name (Illisis -> IntelliVIX)</p>
								</div>
							</li>
							<li>
								<strong>Jul</strong>
								<div className="dis_inblock">
									<p>High-speed search solution based on video analytics release</p>
								</div>
							</li>
						</ul>
					</div>
				</div>

				<div className={`history_panel type2 ${selectedMenu === 'past' ? '' : 'dis_none'}`}>
					<div className="clear">
						<div className="history_tit left">2016</div>
						<ul className="history_list right">
							<li>
								<strong>Sep</strong>
								<div className="dis_inblock">
									<p>Road accident solution release</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2015</div>
						<ul className="history_list right">
							<li>
								<strong>Dec</strong>
								<div className="dis_inblock">
									<p>Face recognition and plate number recognition solution release</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2014</div>
						<ul className="history_list right">
							<li>
								<strong>Apr</strong>
								<div className="dis_inblock">
									<p>GOP mid-west intelligent CCTV system (2014~2015)</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2011</div>
						<ul className="history_list right">
							<li>
								<strong>Jul</strong>
								<div className="dis_inblock">
									<p>4 Great Palaces and Jongmyo : CCTV quality improvement and maintain</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2008</div>
						<ul className="history_list right">
							<li>
								<strong>Jan</strong>
								<div className="dis_inblock">
									<p>Intelligent video analytics solution release (IntelliVIX product series)</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2003</div>
						<ul className="history_list right">
							<li>
								<strong>Mar</strong>
								<div className="dis_inblock">
									<p>i.Vista, i.Modeler, iFusion2D SW package release</p>
								</div>
							</li>
						</ul>
					</div>

					<div className="clear">
						<div className="history_tit left">2000</div>
						<ul className="history_list right">
							<li>
								<strong>June</strong>
								<div className="dis_inblock">
									<p>Beginning of IntelliVIX </p>
								</div>
							</li>
						</ul>
					</div>
				</div>

			</div>
		</>
	)
}

export default History
