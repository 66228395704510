import StackModal from '../Stack/StackModal'

const Alert = (props) => {

	return (
		<StackModal {...props}>
			<div className="modal_wrap alert active">
				<div className="modal_section">
					<button className="hide_btn delete_modal close_modal" onClick={()=>props.onClose()}/>
					<p className="modal_text" dangerouslySetInnerHTML={{ __html: props.contents }}/>
					<div className="btn_wrap">
						<button type="button" className="blue_btn w_100 delete_modal close_modal" onClick={()=>props.onClose()}>확인</button>
					</div>
				</div>
			</div>
		</StackModal>
	)
}

export default Alert
