import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PopupProvider} from 'Libs/popupManager'
import { I18nextProvider } from 'react-i18next';
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import 'Lang/i18n'
import rootReducer from './Reducer'

// 배포 레벨에서는 리덕스 발동시 찍히는 logger를 사용하지 않습니다.
const enhancer =
	process.env.NODE_ENV === "production"
		? compose(applyMiddleware())
		: composeWithDevTools(applyMiddleware(logger));

// 위에서 만든 reducer를 스토어 만들때 넣어줍니다
const store = createStore(rootReducer, enhancer);

window.checkInputNum = (e) => {
	e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
	if (e.target.value.length > e.target.maxLength) {
		e.target.value = e.target.value.slice(0, e.target.maxLength)
	}
}

ReactDOM.render(

	<Provider store={store}>
	<I18nextProvider>
	<PopupProvider>
		<App />
	</PopupProvider>
	</I18nextProvider>
	</Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
