import React, { useEffect, useState } from 'react'
import Logo from '../../../MainAssets/assets/images/logo.png'

import {useSelector, useDispatch} from 'react-redux'


import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'

const NavBar = () => {

	const dispatch = useDispatch()

	const [mQuery, setMQuery] = useState(window.innerWidth > 1025)

	const screenChange = e => {
		const matched = e.matches
		setMQuery(matched)
	}

	useEffect(()=> {
		let mql = window.matchMedia('screen and (min-width: 1025px)');
		mql.addEventListener("change", screenChange);
		return () => mql.removeEventListener("change", screenChange)
	},[])

	useEffect(() => {
		if (mQuery) {
			window.jQuery('.menu_list li').off('click').on('click', function () {
				window.jQuery(this).toggleClass('active').siblings('li').removeClass('active')
			})
		} else {
			window.jQuery('.menu_list li').off('click').on('click', function () {
				window.jQuery(this).children('div').slideToggle(300)
				window.jQuery(this).toggleClass('active').siblings('li').removeClass('active').children('div').slideUp()
			})
			// 메뉴 오픈
			window.jQuery('.m_menuBtn').off('click').on('click', function () {
				window.jQuery('.menu_dim').show()
				window.jQuery('.menu_list').addClass('active')
			})
			// 메뉴 닫힘
			window.jQuery('.M_menuX, .menu_dim').off('click').on('click', function () {
				window.jQuery('.menu_dim').hide()
				window.jQuery('.menu_list').removeClass('active')
			})

			window.jQuery('.menu_list>ul').find('a').off('click').on('click', function () {
				window.jQuery('.menu_dim').hide()
				window.jQuery('.menu_list').removeClass('active')
			})
		}
	}, [mQuery])


	const { useKr } = useSelector(state => state.useOnlyKR)
	const { t, i18n} = useTranslation()

	const history = useHistory()

	const changeLanguageToKo = () => i18n.changeLanguage('ko').then(r => localStorage.setItem('language', 'ko'))
	const changeLanguageToEn = () => i18n.changeLanguage('en').then(r => localStorage.setItem('language', 'en'))
	const currentLanguage = i18n.languages[0]


	return (
		<header>
			<h1 className="left">
				<a>
					<img src={Logo} alt="" onClick={() => history.push("/")} />
				</a>
			</h1>
			<div className="menu_dim" />
			<div className="menu_list">
				<div className="M_logo">
					<img src={Logo} alt="" />
					<button className="M_menuX" />
				</div>
				<ul>
					<li>
						<span className="depth_01">{t("main_intellivix")}</span>
						<div>
							<Link to={"/main/CompanyInfo"}>{t("main_company_introduction")}</Link>
						</div>
					</li>
					<li>
						<span className="depth_01">{t("main_product_technology")}</span>
						<div>
							<Link to={"/main/BusinessArea"}>{t("main_business_area")}</Link>
							<Link to={"/main/Product"}>{t("main_product")}</Link>
							<Link to={"/main/Tec"}>{t("main_technology")}</Link>
							{currentLanguage === "ko" && <Link to={"/main/EdgeBox"}>AI Edge Box</Link>}
							{currentLanguage === "en" && (
								<>
									{/* <a href="https://intellivix.wixsite.com/ai-care" target="_blank" rel="noopener noreferrer">
										AI Care
									</a>
									<a href="https://intellivix.wixsite.com/ai-guard" target="_blank" rel="noopener noreferrer">
										AI Guard
									</a>
									<a href="https://intellivix.wixsite.com/vixallcam" target="_blank" rel="noopener noreferrer">
										VIXallcam
									</a> */}
								</>
							)}
						</div>
					</li>
					<li>
						<Link to={"/main/NewsRoom"} className="depth_01">
							{t("newsRoom")}
						</Link>
					</li>
					{currentLanguage === "ko" && (
						<>
							<li>
								<Link to="/main/Procurement" className="depth_01">
									조달우수
								</Link>
							</li>
							<li>
								<a onClick={() => history.push("/main/Introduction")} className="depth_01">
									인재채용
								</a>
							</li>
						</>
					)}
					<li>
						{currentLanguage === "ko" ? (
							<span className="depth_01">{t("main_04_h2")}</span>
						) : (
							<Link to={"/main/Support"} className="depth_01">
								{t("main_04_h2")}
							</Link>
						)}

						{currentLanguage === "ko" && (
							<div>
								<Link to={"/main/Support"}>{t("main_04_h2")}</Link>
								<Link to={"/main/Notice"}>공지사항</Link>
								<Link to={"/main/install"}>설치파일 무결성 확인</Link>
							</div>
						)}
					</li>

					{/*230410 HJ 뉴스레터 한글만 링크 추가 */}
					{currentLanguage === "ko" && (
						<li>
							<a
								href="https://page.stibee.com/subscriptions/242975"
								className="depth_01"
								target="_blank"
								rel="noopener noreferrer"
							>
								뉴스레터
							</a>
						</li>
					)}
					{/*230410 HJ 뉴스레터 한글만 링크 추가 */}
				</ul>

				{/* 영문 네비게이션 */}
				{/* 조달우수, 인재채용 인텔리빅스>공지사항 비활성화 */}
				{/* <ul>
							<li>
									<span className="depth_01">IntelliVIX</span>
									<div>
											<a href="인텔리빅스소개.html">Introduction</a>
									</div>
							</li>
							<li>
									<span className="depth_01">Product & Technology</span>
									<div>
											<a href="사업분야.html">Business Area</a>
											<a href="제품.html">Product</a>
											<a href="기술.html">Technology</a>
									</div>
							</li>
							<li><a href="뉴스룸.html" className="depth_01">Newsroom</a></li>
							<li><a href="고객문의.html" className="depth_01">Support</a></li>
					</ul> */}
			</div>
			<div className="right">
				{!useKr && (
					<div className="language_box">
						<button className="language_btn" />
						<div className="language_list">
							<button onClick={() => changeLanguageToKo()}>KOR</button>
							<button onClick={() => changeLanguageToEn()}>ENG</button>
						</div>
					</div>
				)}
				<button className="m_menuBtn" />
			</div>
		</header>
	);
}

export default NavBar
