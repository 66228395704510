import React, { useState } from 'react'
import Information from './Information'
import { useTranslation } from 'react-i18next'
import CEOMessage from './CEOMessage'
import History from './Histroy'
import Award from './Award'

const CompanyInfo = () => {

	const [selectedMenu, setSelectedMenu] = useState('info')
	const { t, i18n } = useTranslation()

	return (
		<main className={`sub_page ${i18n.language === 'ko' ? 'kor' : 'eng'}`}>
			<div className="sub_con">

				{i18n.language === 'ko' ?
					<ul className="sub_nav">
					<li>인텔리빅스</li>
					<li>기업정보</li>
					<li>{{ 'info':'인텔리빅스',
									'ceo': 'CEO Message',
									'his': '연혁',
									'award': '인증·수상·특허'}[selectedMenu]}</li>
				</ul>
					:
					<ul className="sub_nav">
					<li>IntelliVIX</li>
					<li>company information</li>
					<li>{{ 'info': 'Introduction',
								'ceo': 'CEO Message',
								'his': 'History'}[selectedMenu]}</li>
				</ul>}

				<div className="clear">
					<ul className="tab_btn right">
						<li className={selectedMenu === 'info' ? 'active' : ''}>
							<button onClick={() => setSelectedMenu('info')}>{t('company_info')}</button>
						</li>
						{/* <li className={selectedMenu === 'ceo' ? 'active' : ''}>
							<button onClick={() => setSelectedMenu('ceo')}>{t('company_ceo')}</button>
						</li> */}
						<li className={selectedMenu === 'his' ? 'active' : ''}>
							<button onClick={() => setSelectedMenu('his')}>{t('company_his')}</button>
						</li>
						{i18n.language === 'ko' && <li className={selectedMenu === 'award' ? 'active' : ''}>
							<button onClick={() => setSelectedMenu('award')}>인증·수상·특허</button>
						</li>}
					</ul>
					<h2 className="sub_title left">{i18n.language === 'ko' ? {
						'info': '인텔리빅스 소개',
						'ceo': 'CEO Message',
						'his': '연혁',
						'award': '인증·수상·특허'
					}[selectedMenu] : {
						'info': 'Introduction',
						'ceo': 'CEO Message',
						'his': 'History'
					}[selectedMenu]}</h2>
				</div>

				{i18n.language === 'en' && selectedMenu === 'info' &&
					<>
					<p className="title_txt">Specialized in Vision AI Technology</p>
					<p className="sub_txt martop_30">IntelliVIX is a vision AI company that has key know-how in video analytics
						through technology development and system construction.<br/>Through the development/commercialization of
						Vision AI core technologies,
						we continue to provide high-precision intelligent video analytics technologies and services.</p>
					</>
					}
				{i18n.language === 'ko' && selectedMenu === 'award' && <div className="M_disnone">
					<p className="title_txt">공신력 있는 국내/외 주요 인증을 통해 최고의 기술 기업으로 인정받고 있습니다.</p>
				</div>}
			</div>


			{{ 'info': <Information/>,
					'ceo': <CEOMessage />,
					'his': <History />,
					'award': <Award />}[selectedMenu]}


		</main>
	)
}

export default CompanyInfo
