import React, { useEffect, useState } from 'react'
import Logo from 'MainAssets/assets/images/logo.png'
import {useTranslation} from 'react-i18next'
import mainBanner from 'MainAssets/assets/images/main_banner.png'
import iconYoutube from 'MainAssets/assets/images/icon/icon_youtube.png'
import iconBlog from 'MainAssets/assets/images/icon/icon_blog.png'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import NewsApi from '../../../Api/NewsApi'
import CommonAxios from '../../../Axios/CommonAxios'

const Main = () => {

	const { t, i18n} = useTranslation()

	const changeLanguageToKo = () => i18n.changeLanguage('ko')
	const changeLanguageToEn = () => i18n.changeLanguage('en')

	const [data, setData] = useState([])
	const [page, setPage] = useState(0)
	const [totalPage, setTotalPage] = useState(0)

	useEffect(()=> {
		let params = {
			page
		}
		NewsApi.getNews(params)
			.then(res => {
				setData(data.concat(res.data.data.news))
				setTotalPage(res.data.data.allPage)
			})
	},[page])


	useEffect(()=> {
		window.jQuery(function(){
			// 풀페이지 이벤트
			if(window.jQuery('#fullpage').length){
				window.jQuery('#fullpage').fullpage({
					navigation: true,
					anchors: ['slide_01', 'slide_02', 'slide_03', 'slide_04', 'slide_05'],
				});
			}

			// 뉴스룸 슬라이드

			// 서버 무제한 방식
			// 메인 영상 pc, mobile 교체
			// var video_src = {
			//     pc: "../assets/images/img_main_01.mp4",
			//     mobile: "../assets/images/mobile/Mimg_main_01.mp4"
			// }
			// window.jQuery(window).resize(function() {
			//     var nowPlaySrc = window.jQuery(".main_video").find("source").attr("src");
			//     var width=window.innerWidth;
			//     var src = "";
			//     if (width < 1025){
			//         src = video_src.mobile;
			//     } else {
			//         src = video_src.pc;
			//     }
			//
			//     if(nowPlaySrc != src){
			//         window.jQuery(".main_video").find('source').attr("src", src);
			//         window.jQuery(".main_video")[0].load();
			//     }
			// }).resize();


			// vimeo 방식
			var video_src = {
				pc: "https://player.vimeo.com/video/674351054?h=d57f3fe32e?autoplay=1&mute=1&background=1",
				mobile: "https://player.vimeo.com/video/674352447?h=6c00fb5895?autoplay=1&mute=1&background=1"
			}
			window.jQuery(window).resize(function() {
				var nowPlaySrc = window.jQuery(".vimeo-wrapper").find("iframe").attr("src");
				var width=window.innerWidth;
				var src = "";
				if (width < 1025){
					src = video_src.mobile;
				} else {
					src = video_src.pc;
				}

				if(nowPlaySrc !== src){
					window.jQuery(".vimeo-wrapper").find('iframe').attr("src", src);
				}
			}).resize();
		})
		return () => {
			if (typeof window.jQuery.fn.fullpage.destroy === 'function') {
				window.jQuery.fn.fullpage.destroy('all');
			}
		}
	},[])

	useEffect(() => {
		if(window.jQuery('#news').length){
			var news = new window.Swiper("#news", {
				slidesPerView: 1.5,
				spaceBetween: 15,
				breakpoints: {
					1010: {
						slidesPerView: 4,
						spaceBetween: 30,
					},
					840: {
						slidesPerView: 3,
					},
					540: {
						slidesPerView: 2,
					},
				},
				navigation: {
					nextEl: '.news-next',
					prevEl: '.news-prev',
				},
			});
		}
	}, [data])


	return (
		<>
			<ul className="page_bar" id="fp-nav" dangerouslySetInnerHTML={{ __html: t("main_slide") }}></ul>

			{/* 영문 */}
			{/* <ul className="page_bar" id="fp-nav">
					<li><a href="#slide_01"><span>Main</span></a></li>
					<li><a href="#slide_02"><span>Product & Technology</span></a></li>
					<li><a href="#slide_03"><span>Newsroom</span></a></li>
					<li><a href="#slide_04"><span>Support</span></a></li>
			</ul> */}

			<style media="screen"></style>
			{/* 국문 */}
			<main id="fullpage" className="kor">
				<section className="section main_01">
					{/* 서버 무제한 방식 */}
					{/* <video muted autoplay loop className="main_video">
							<source src="../assets/images/img_main_01.mp4" type="video/mp4">
					</video> */}

					{/* vimeo 방식 */}
					<div className="vimeo-wrapper">
						<iframe
							src="https://player.vimeo.com/video/674351054?h=d57f3fe32e?autoplay=1&mute=1&background=1"
							frameBorder="0"
							allow="autoplay; fullscreen; picture-in-picture"
							allowFullScreen
						/>
					</div>

					<div className="main_middle mid_top">
						{/* <h2>{t("main_01_h2")}</h2>
						<p dangerouslySetInnerHTML={{ __html: t("main_01_p") }} /> */}
					</div>
					<span className="main_arrow" />
				</section>

				<section className="section main_02">
					<div className="main_middle">
						<h2>{t("main_02_h2")}</h2>
						<p dangerouslySetInnerHTML={{ __html: t("main_02_p") }} />
						{/* <Link to={"/main/BusinessArea"} className="main_link martop_108 pl_56">
							{t("main_02_read_more")}
						</Link> */}
						{/* 20240221 클래스 ('pl_56')추가 */}
						{/* 20240307 AMS 링크 주소 변경 */}
						{i18n.language === "ko" && (
							<a href={"https://blog.naver.com/intellivix_illisis/223376231175"} className="main_link martop_108 pl_56">
								{t("main_02_read_more")}
							</a>
						)}
					</div>
				</section>

				<section className="section main_03">
					<div className="main_middle">
						<h2>{t("newsRoom")}</h2>
						{data.length !== 0 ? (
							<div className="swiper-container" id="news">
								<ul className="swiper-wrapper">
									{data.length !== 0 &&
										data.map((item) => (
											<li className="swiper-slide" key={item.no}>
												<div className="img_box">
													<img src={CommonAxios.defaults.baseURL + `/api/file/${item.no}`} alt="" />
												</div>
												<div className="list_txt">
													<em>{item.reportDate}</em>
													<p>{item.title}</p>
													<a href={item.url} target={"_blank"}>
														View Detail
													</a>
												</div>
											</li>
										))}
								</ul>
							</div>
						) : (
							<div className="news_empty" dangerouslySetInnerHTML={{ __html: "등록된 소식이 없습니다" }} />
						)}
						<div className="news_btn">
							<div className="news-next swiper-button-next" />
							<div className="news-prev swiper-button-prev" />
							<Link to={"/main/newsRoom"} className="news_plus" />
						</div>
					</div>
				</section>

				<section className="section main_04">
					<div className="main_middle">
						<h2>{t("main_04_h2")}</h2>
						<p dangerouslySetInnerHTML={{ __html: t("main_04_p") }} />
						<Link to={"/main/Support"} className="main_link martop_94">
							{t("main_04_a")}
						</Link>
					</div>
				</section>
				<Footer />
			</main>

			{/* 영문 */}
			{/* <main id="fullpage" className="eng">
					<section className="section main_01">
							<video muted autoplay loop className="main_video">
									<source src="../assets/images/img_main_01.mp4" type="video/mp4">
							</video>
							<div className="main_middle">
									<h2>Vision AI company IntelliVIX</h2>
									<p>Specialized in Vision AI Technology</p>
							</div>
							<span className="main_arrow"></span>
					</section>

					<section className="section main_02">
							<div className="main_middle">
									<h2>Product & Technology</h2>
									<p>IntelliVIX provides the best solution based on professional development personnel specialized in Vision  <span className="dis_block">AI and accumulated know-how over the decades. </span>Get various solutions from IntelliVIX that are growing through continuous innovation.</p>
									<a href="사업분야.html" className="main_link martop_108">Read More</a>
							</div>
					</section>

					<section className="section main_03">
							<div className="main_middle">
									<h2>Newsroom</h2>
									<div className="swiper-container" id="news">
											<ul className="swiper-wrapper">
													<li className="swiper-slide">
															<div className="img_box"><img src={mainBanner} alt=""></div>
															<div className="list_txt">
																	<em>2021-10-15</em>
																	<p>인텔리빅스, AI 기반 글로벌 영상분석 업체로 도약하는 한 해 될 것</p>
																	<a href="">View Detail</a>
															</div>
													</li>
													<li className="swiper-slide">
															<div className="img_box"><img src={mainBanner} alt=""></div>
															<div className="list_txt">
																	<em>2021-10-15</em>
																	<p>인텔리빅스</p>
																	<a href="">View Detail</a>
															</div>
													</li>
													<li className="swiper-slide">
															<div className="img_box"><img src={mainBanner} alt=""></div>
															<div className="list_txt">
																	<em>2021-10-15</em>
																	<p>인텔리빅스, AI 기반 글로벌 영상분석 업체로 도약하는 한 해 될 것한 해 될 것한 해 될 것한 해 될 것한 해 될 것한 해 될 것한 해 될 것한 해 될 것</p>
																	<a href="">View Detail</a>
															</div>
													</li>
													<li className="swiper-slide">
															<div className="img_box"><img src={mainBanner} alt=""></div>
															<div className="list_txt">
																	<em>2021-10-15</em>
																	<p>인텔리빅스, AI 기반 글로벌 영상분석 업체로 도약하는 한 해 될 것</p>
																	<a href="">View Detail</a>
															</div>
													</li>
													<li className="swiper-slide">
															<div className="img_box"><img src={mainBanner} alt=""></div>
															<div className="list_txt">
																	<em>2021-10-15</em>
																	<p>인텔리빅스, AI 기반 글로벌 영상분석 업체로 도약하는 한 해 될 것마지막</p>
																	<a href="">View Detail</a>
															</div>
													</li>
											</ul>
									</div>
									<div className="news_btn">
											<div className="news-next swiper-button-next"></div>
											<div className="news-prev swiper-button-prev"></div>
											<a href="뉴스룸.html" className="news_plus"></a>
									</div>
							</div>
					</section>

					<section className="section main_04">
							<div className="main_middle">
									<h2>Support</h2>
									<a href="고객문의.html" className="main_link martop_94">Support</a>
							</div>
					</section>

					<footer className="clear section">
							<div className="left ft_logo"><img src="../assets/images/logo.png" alt=""></div>
							<div className="left ft_txt">
									<p>6F, Prince Hyoryung Building, 4, Hyoryungro 34-gil, Seocho-gu, Seoul 06704, Republic of Korea</p>
									<ul>
											<li>TEL: 02-581-3883</li>
											<li>FAX: 02-581-3886</li>
											<li>E-Mail: intellivix@intellivix.ai</li>
									</ul>
									<p>Copyright 2021 Intellivix Co., Ltd. All rights reserved.</p>
							</div>
							<div className="right ft_link">
									<a href="https://www.youtube.com/watch?v=6f54Xx8ioRs"><img src="../assets/images/icon/icon_youtube.png" alt=""></a>
									<a href="https://blog.naver.com/intellivix_illisis"><img src="../assets/images/icon/icon_blog.png" alt=""></a>
							</div>
					</footer> */}
		</>
	);
}

export default Main
