import CommonAxios from 'Axios/CommonAxios'

export default {
	searchList (params) {
		return CommonAxios(({
			url: '/api/install/search/list',
			method: 'GET',
			params
		}))
	},

	deleteInstall (data) {
		return CommonAxios(({
			url: 'api/install/delete',
			method: 'DELETE',
			data
		}))
	},

	getListInstall () {
		return CommonAxios(({
			url: 'api/install/list',
			method: 'GET',
		}))
	},

	insertInstall (data) {
		return CommonAxios(({
			url: 'api/install/insert',
			method: 'POST',
			data
		}))
	},
	detailInstall (params) {
		return CommonAxios(({
			url: 'api/install/detail',
			method: 'GET',
			params
		}))
	},

	getList () {
		return CommonAxios(({
			url: 'api/install/list',
			method: 'GET'
		}))
	}
}
