import React from 'react'
import Logo from '../../../MainAssets/assets/images/logo.png'
import iconYoutube from '../../../MainAssets/assets/images/icon/icon_youtube.png'
import iconBlog from '../../../MainAssets/assets/images/icon/icon_blog.png'
import iconInstagram from '../../../MainAssets/assets/images/icon/icon_instagram.png'
import iconLinkedin from '../../../MainAssets/assets/images/icon/icon_linkedin.png'
import iconFaceBook from '../../../MainAssets/assets/images/icon/icon_facebook.png'

import { useTranslation } from 'react-i18next'

const Footer = () => {

	const { t, i18n} = useTranslation()

	return (
		<footer className="clear section">
			<div className="left ft_logo"><img src={Logo} alt=""/></div>
			<div className="left ft_txt">
				<p>{t('footer_p')}</p>
				<ul>
					<li>TEL: 02-581-3883</li>
					<li>FAX: 02-581-3886</li>
					<li>E-Mail: intellivix@intellivix.ai</li>
				</ul>
				<p>Copyright 2022 Intellivix Co., Ltd. All rights reserved.</p>
			</div>
			<div className="right ft_link">				
				{/*20240221 링크 추가 및 url 수정*/}
                {/* <a href="https://www.youtube.com/watch?v=6f54Xx8ioRs"><img src="../assets/images/icon/icon_youtube.png" alt=""></a>*/}
                <a href="https://www.youtube.com/@intellivix"><img src={iconYoutube} alt=""/></a>
                <a href="https://blog.naver.com/intellivix_illisis"><img src={iconBlog} alt=""/></a>
                <a href="https://www.instagram.com/intellivix/"><img src={iconInstagram}alt=""/></a>
                <a href="https://www.linkedin.com/company/intellivix/"><img src={iconLinkedin} alt=""/></a>
                <a href="https://www.facebook.com/profile.php?id=61555845850369"><img src={iconFaceBook} alt=""/></a>
                {/* 20240221 링크 추가 및 url 수정 */}
			</div>
		</footer>
	)
}

export default Footer
