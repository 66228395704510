import React, {useEffect, useState} from 'react'

const useProList = (List, selectNo, writeMode, count, page, allChk) => {

	const [checkList, setCheckList] = useState([])


	useEffect(() => {
		if (window.jQuery("[id^='inpChk']").length > 0) {
			window.jQuery("[id^='inpChk']").map(item => {
				window.jQuery("[id^='inpChk']")[item].checked = allChk
			})
			allChk ? setCheckList(checkList.concat(List.map(item => (item.no)))) : setCheckList([])
		}
	}, [allChk])

	const division = (d) => {
		switch (d) {
			case 'PRO' : return '우수제품'
			case 'MAS' : return 'MAS'
			case 'SW': return 'SW'
		}
	}

	const subject = (d,s) => {
		switch (s) {
			case 'main' : {
				if (d === 'PRO' || d === 'MAS') {
					return '주품목'
				} return '응용과학용 소프트웨어'
			}
			case 'sub' : {
				if (d === 'PRO' || d === 'MAS') {
					return '추가선택품목'
				} return '통신 소프트웨어'
			}
		}
	}




	const checkHandler = (no, e) => {
		e.target.checked ? setCheckList(checkList.concat(no)) : setCheckList(checkList.filter(item => item !== no))
	}

	return (
		[List.map((item, index) => (
			<tr key={item.no} >
				<td>
					<input type="checkbox" name="inpChk" className="ch_check hide" id={`inpChk${item.no}`} onChange={(e)=>checkHandler(item.no, e)}/>
					<label htmlFor={`inpChk${item.no}`} className="label single" />
				</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{count - (page * 10 + index)}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{item.createDate}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{division(item.division)}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{subject(item.division, item.subject)}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{item.ino}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{item.product_name}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{item.model_name}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{item.product_standard}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{item.equipment}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{item.price}</td>
				<td onClick={(e)=> {
					selectNo(item.no)
					writeMode(2)}
				}>{item.createAdmin}</td>
			</tr>
			)
		), checkList]

	)
}

export default useProList
