export const onlyKR = "USE_ONLY_KR"
export const notOnlyKR = "NOT_USE_ONLY_KR"

export const onlyUseKR = bool => ({type: onlyKR, useKr: bool})

export const notOnlyUseKR = bool => ({type: notOnlyKR, useKr: bool})

const initialState  = {
	useKr: false
}

const useOnlyKR = (state = initialState, action) => {
	switch (action.type) {
		case onlyKR:
			return {
				...state,
				useKr: action.useKr
			}
		case notOnlyKR:
			return {
				...state,
				useKr: action.useKr
			}
		default: return state
	}
}

export default useOnlyKR
