import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import PostNotice from './PostNotice'
import NoticeApi from '../../../../Api/NoticeApi'
import useList from 'Hooks/useList'
import DetailNotice from './DetailNotice'
import useSearch from '../../../../Hooks/useSearch'
import usePage from 'Hooks/usePage'
import Alert from '../../../../Modal/Component/Alert'
import instance from '../../../../Axios/CommonAxios'

const Notice = () => {
	const [writeMode, setWriteMode] = useState(0)

	const [data, setData] = useState([])

	const [pageCount, setPageCount] = useState(1)
	const [listCount, setListCount] = useState(0)
	const [allChecked, setAllChecked] = useState(false)
	const [searchComponent, startDate, endDate, title] = useSearch(writeMode)
	const [updateNumber, setUpdateNumber] = useState(-1)

	const [selectNo, setSelectNo] = useState()
	const [pageComponent, selectedPage] = usePage(pageCount)

	const [list, checkList] = useList(data, setSelectNo, setWriteMode, listCount, selectedPage, allChecked)

	const [emptyText, setEmptyText] = useState('')

	useEffect(() => {
		getData(title, startDate, endDate, selectedPage)
	}, [startDate, endDate, selectedPage, title])

	useEffect(() => {
		setPageCount(1)
	}, [startDate, endDate])

	useEffect(() => {
		getData(title, startDate, endDate, selectedPage)
	}, [writeMode])

	const getData = (title, startDate, endDate, page) => {
		let notice = { title, startDate, endDate, page }
		NoticeApi.getNotice(notice)
			.then(res => {
				if (res.data.data.notice.length > 0 || listCount > 0) {
					setData(res.data.data.notice)
				} else {
					setEmptyText('공지사항이 존재하지 않습니다. <br /> 공지사항 등록버튼을 통해 등록해주세요.')
				}
				setPageCount(res.data.data.allPage)
				setListCount(res.data.data.count)
			})
	}

	const deleteList = () => {
		if (checkList.length === 0) {
			window.popupManager.open(Alert, {
				contents: '삭제할 게시물을 선택해주세요'
			})
		} else {
			NoticeApi.deleteNotice(checkList)
				.then(res => {
					getData('', '', '', selectedPage)
					setPageCount(1)
				})
		}
	}

	return (
		writeMode === 0 ? <>
			<div className="title_box clear">
				<h2 className="title left">공지사항 관리</h2>

				<ul className="right">
					<li>HOME</li>
					<li>공지사항 관리</li>
				</ul>
			</div>

			{!emptyText && searchComponent}

			<div className="table_wrap martop_20">

				{!emptyText ? <table className="type1 txt_left4 notice_list">
					<colgroup>
						<col width="80px"/>
						<col width="80px"/>
						<col width="210px"/>
						<col width="*"/>
						<col width="250px"/>
					</colgroup>
					<thead>
					<tr>
						<th>
							<input type="checkbox" name="inpChk" className="ch_check hide allcheck" id={'allChk'}
							       onChange={() => setAllChecked(!allChecked)}/>
							<label htmlFor="allChk" className="label single"/>
						</th>
						<th>번호</th>
						<th>등록일</th>
						<th>제목</th>
						<th>등록자</th>
					</tr>
					</thead>
					<tbody>
					{list}
					</tbody>
				</table> : <li dangerouslySetInnerHTML={{ __html: emptyText }}/>}

				{!emptyText  && pageComponent}

				<div className="btn_bottom btn_center">
					{!emptyText && <button type="button" className="white_btn w_70 alert_btn" onClick={() => deleteList()}>삭제</button>}
					<button type="button" className="blue_btn w_120" onClick={() => {
						setWriteMode(1)
					}}>공지사항 등록
					</button>
				</div>
			</div>
		</> : writeMode === 1 || writeMode === 3 ? <PostNotice writeMode={writeMode} setWriteMode={setWriteMode} updateNumber={updateNumber} /> :
			<DetailNotice detail={selectNo}
			              setWriteMode={setWriteMode} setUpdateNumber={setUpdateNumber}/>
	)
}

export default Notice
