window.jQuery(function () {
    // close modal on click dimLayer

    // 모달 닫기(공통)
    window.jQuery(document).on("click", ".close_modal",function () {
        closeModal();
    });

    /* 공통 alert, confirm 관련 */
    // 공통 alert, confirm 모달 닫기(X버튼, 취소버튼)
    window.jQuery(".modal_wrap").on("click", ".delete_modal", function () {
        action_popup.close(this);

		window.jQuery(".subDimLayer").remove();
		window.jQuery("body").removeClass("fix");
    });

    // datepicker


    // pagination
    window.jQuery(document).on("click", ".paging_num",function () {
        window.jQuery(this).parents("li").siblings().children().removeClass("on");
        window.jQuery(this).addClass("on");
    });
});

// addnDimLayer
function addnDimLayer() {
    var createDiv = "<div id='mainDimLayer'></div>";
    window.jQuery("body").append(createDiv).addClass("fix");
}

// addnSubDimLayer
function addnSubDimLayer() {
    var createDiv = "<div class='subDimLayer'></div>";
    window.jQuery("body").append(createDiv).addClass("fix");
}

// closeModal
function closeModal() {
	window.jQuery(".modal_wrap").removeClass("active");
    window.jQuery("#mainDimLayer").remove();
    window.jQuery("body").removeClass("fix");
}

/**
 *  공통 alert, confirm 관련
 *  alert, confirm 대용 팝업 메소드 정의 <br/>
 *  timer : 애니메이션 동작 속도 <br/>
 *  alert : 경고창 <br/>
 *  confirm : 확인창 <br/>
 *  open : 팝업 열기 <br/>
 *  close : 팝업 닫기 <br/>
 **/
// alert and confirm common
var action_popup = {
    timer: 300,
    // confirm 모달
    confirm: function (txt, callback) {
        // 텍스트 없을경우(유효성 검증)
        if (txt == null || txt.trim() == "") {
            console.warn("confirm message is empty.");
            return;
            // 콜백 정의 검증
        } else if (callback == null || typeof callback != "function") {
            console.warn("callback is null or not function.");
            return;
            // 정상인 경우
        } else {
            window.jQuery(".confirm .btn_ok").on("click", function () {
                window.jQuery(this).unbind("click"); // 기존 모달 위에 띄울 경우 중복 확인 동작 방지
                callback(true);
                action_popup.close(this);
            });
            this.open("confirm", txt);
        }
    },

    // alert 모달
    alert: function (txt) {
        if (txt == null || txt.trim() == "") {
            console.warn("confirm message is empty.");
            return;
        } else {
            this.open("alert", txt);
        }
    },

    // alert or confirm 구분해서 열기
    open: function (type, txt) {
        var popup = window.jQuery("." + type);
        popup.find(".modal_text").empty().append(txt);
        window.jQuery("body").append("<div class='subDimLayer'></div>");
        window.jQuery(".subDimLayer").css("height", window.jQuery(document).height()).attr("target", type);
        popup.fadeIn(this.timer);
    },

    // alert or confirm 구분해서 닫기
    close: function (target) {
        var modal = window.jQuery(target).closest(".modal_wrap");
        var dimLayer;
        if (modal.hasClass("confirm")) {
            dimLayer = window.jQuery(".subDimLayer[target=confirm]");
        } else if (modal.hasClass("alert")) {
            dimLayer = window.jQuery(".subDimLayer[target=alert]");
        } else {
            console.warn("close unknown target.");
            return;
        }
        modal.fadeOut(this.timer);
        window.jQuery(".confirm .btn_ok").unbind("click");
        setTimeout(function () {
					if (dimLayer) dimLayer.remove()
        }, this.timer);
    }
};
