import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'

import VideoSurveliance from './VideoSurveliance'
import AIFarm from './AIFarm'
import IndustrialSafety from './IndustrialSafety'
import ITS from './ITS'
import BI from './BI'
import VIX from './VIX'

const BusinessArea = () => {

	const location = useLocation()
	const history = useHistory()
	const [selectedMenu, setSelectedMenu] = useState('VS')
	const [hash, setHash] = useState('')
	const { t, i18n } = useTranslation()

	useEffect(() => {
		if (location.state) {
			setSelectedMenu(location.state.menu)
			setHash(location.hash)

			return ()=> {
				window.scrollTo({top: 0})
			}
		}
	}, [])

	useEffect(() => {
		//location.state.menu !== selectedMenu && setHash('')
	}, [selectedMenu])

	const lang = i18n.language === 'ko'

	return (
		<main className={`sub_page ${lang ? 'kor' : 'eng'}`}>
			<div className="sub_con">
				{lang ? <ul className="sub_nav">
						<li>제품과 기술</li>
						<li>사업분야</li>
					</ul> :
					<ul className="sub_nav">
						<li>Product & Technology</li>
						<li>Business Area</li>
					</ul>
				}
				<div className="order_change">
					<h2 className="sub_title">{lang ? '사업분야' : 'Business Area'}</h2>
					{!lang &&
						<p className="title_txt">Based on Vision AI technology, IntelliVIX provides various solutions that can be
							used in various industries such as video security, industrial safety, transportation, retail stores, and
							farms.</p>}
					<ul className={`tab_btn ${lang ? 'per33 kor_ver' : 'eng_ver'}`}>
						<li className={selectedMenu === 'VS' ? 'active' : ''}>
							<button onClick={() => {
								setSelectedMenu('VS')
								setHash('')
							}}>{lang ? '영상보안' : 'Video Surveillance'}</button>
						</li>
						<li className={selectedMenu === 'IS' ? 'active' : ''}>
							<button onClick={() => {
								setSelectedMenu('IS')
								setHash('')
							}}>{lang ? '산업안전' : 'Industrial Safety'}</button>
						</li>
						<li className={selectedMenu === 'ITS' ? 'active' : ''}>
							<button onClick={() => {
								setSelectedMenu('ITS')
								setHash('')
							}}>{lang ? '교통' : 'ITS'}</button>
						</li>
						<li className={selectedMenu === 'BI' ? 'active' : ''}>
							<button onClick={() => {
								setSelectedMenu('BI')
								setHash('')
							}}>BI
							</button>
						</li>
						<li className={selectedMenu === 'AIF' ? 'active' : ''}>
							<button onClick={() => setSelectedMenu('AIF')}>AI Farm</button>
						</li>

						{/*20240221 출입통제 추가*/}

						{i18n.language === 'ko' && <li className={selectedMenu === 'VIX' ? 'active' : ''}>
							<button onClick={() => setSelectedMenu('VIX')}>출입통제</button>
						</li>}

						{/*230130 HJ 네트워크 장비 삭제*/}
						{/*<li><a href="https://hyperlab.co.kr/"*/}
						{/*       target={'_blank'}>{lang ? '네트워크 장비' : 'network equipment'}</a>*/}
						{/*</li>*/}
					</ul>
				</div>
			</div>

			{{
				'VS': <VideoSurveliance hash={hash}/>,
				'IS': <IndustrialSafety hash={hash}/>,
				'ITS': <ITS hash={hash}/>,
				'BI': <BI hash={hash}/>,
				'AIF': <AIFarm hash={hash}/>,
				'VIX': <VIX hash={hash}/>,
			}[selectedMenu]}

		</main>
	)
}

export default BusinessArea
