import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Product = () => {
	const { t, i18n } = useTranslation()
	const lang = i18n.language === 'ko'

	useEffect(() => {
		window.jQuery('.more_btn').on('click', function () {
			window.jQuery(this).toggleClass('active').next('.more_txt').slideToggle(300)
		})

	}, [])

	useEffect(() => {
		!lang && window.jQuery('.tab_btn.eng_ver button').on('click', function () {
			window.jQuery(this).parents('li').addClass('active').siblings().removeClass('active')
			var _target = window.jQuery(this).data('target')
			window.jQuery('.Eproduct_panel').addClass('dis_none')
			window.jQuery('#' + _target).removeClass('dis_none')
		})
	}, [lang])

	const findImage = (path) => {
		let imgArray = path.split('images/')
		let img = imgArray[imgArray.length - 1]

		console.log(`MainAssets/assets/images/${img}`)

		return require(`MainAssets/assets/images/${img}`)
	}

	return lang ? (
		<main className="sub_page kor">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>제품과 기술</li>
					<li>제품</li>
				</ul>
				<h2 className="sub_title">제품소개</h2>
				<p className="title_txt small_tec">인텔리빅스만의 제품을 소개합니다. 끊임없는 연구 개발을 통해 더 편리한 사회 만들기에 변함없이 기여하겠습니다.</p>
			</div>

			<div className="sub_con">
				<div className="technology_con">
					<p className="M_disnone grey_p">*모델명에 마우스를 대시면 설명을 확인할 수 있습니다</p>

					<div className="technology_right">
						<div><span>VIX-Monitoring<br/>+ Client</span></div>
						<div><span>SmartEye Client<br/>+Manager</span></div>
						<div><span>VIX-Search<br/>Forensic</span></div>
						<div><span>Masker Client<br/>+Server</span></div>
					</div>


					<div className="technology_chart">
						<div className="technology_tit">
							<img src={findImage('../assets/images/technology/technology_tit.png')} alt=""/>
						</div>

						<div className="blue_back">

							<div className="blue_leftBox">
								<div className="tech_left01">
									<img src={findImage('../assets/images/technology/img_tech01.png')} alt=""/>
									<div className="tech_hover tech_leftPosion">
										<Link to={{
											pathname: '/main/BusinessArea',
											hash: '#220s',
											state: { menu: 'IS' }
										}}>
											<div className="technology_txt">
												<strong>VIX-220S</strong>
												<p>220B 기능을 포함하고 있으며, <span className="underline">건설현장에서 효율적인 안전 관리를 위한 영상 분석 이벤트 분석</span> 수행
												</p>
											</div>
										</Link>
									</div>
									<div className="tech_hover tech_rightPosion">
										<Link to={{
											pathname: '/main/BusinessArea',
											hash: '#220r',
											state: { menu: 'ITS' }
										}}>
											<div className="technology_txt">
												<strong>VIX-220R</strong>
												<p>220B 기능을 포함하고 있으며, 도로 유고 이벤트를 감지하며 <span className="underline">딥러닝 기반 도로 교통 정보 분석</span>을
													수행</p>
											</div>
										</Link>
									</div>
								</div>

								<div className="Mdis_block"/>

								<div className="tech_left02">
									<img src={findImage('../assets/images/technology/img_tech02.png')} alt=""/>
									<div className="tech_hover tech_leftPosion">
										<Link to={{
											pathname: '/main/BusinessArea',
											hash: '#220f',
											state: { menu: 'BI' }
										}}>
											<div className="technology_txt">
												<strong>VIX-220F</strong>
												<p>220B 기능을 포함하고 있으며, 얼굴을 감지하고 추적한 후 <span
													className="underline">나이 예측, 성별 판별,표정 인식, 얼굴 인식</span>을 수행</p>
											</div>
										</Link>
									</div>
									<div className="tech_hover tech_rightPosion">
										<Link to={{
											pathname: '/main/BusinessArea',
											hash: '#220p',
											state: { menu: 'ITS' }
										}}>
											<div className="technology_txt">
												<strong>VIX-220P</strong>
												<p>220B 기능을 포함하고 있으며, 자동 PTZ 추적과 연계해 일반 PTZ 카메라를 이용해 추적되는 관심 차량에 대한 줌인 영상을 확보 후 <span
													className="underline">실시간으로 차량 번호판 인식 수행</span></p>
											</div>
										</Link>
									</div>
								</div>
							</div>

							<div className="blue_right tech_hover">
								<Link to={{
									pathname: '/main/BusinessArea',
									hash: '#220b',
									state: { menu: 'VS' }
								}}>
									<img src={findImage('../assets/images/technology/img_tech03.png')} alt=""/>
									<div className="technology_txt right_position">
										<strong>VIX- 220B (Basic)</strong>
										<p>VIX-120B 기능을 포함하며, 마스터 카메라(고정형 카메라)와 슬레이브 카메라(PTZ 카메라)의 조합을 통해 마스터 카메라에서 객체를 감지한 후 연동된 슬레이브
											카메라를 통해 감지된 객체를 추적하는 <span className="underline">자동 PTZ 추적</span> 기능을 제공</p>
									</div>
								</Link>
							</div>

							<div className="sky_blue_back">
								<div className="tech_hover sky_blue_left">
									<img src={findImage('../assets/images/technology/img_tech04.png')} alt=""/>
									<div className="technology_txt">
										<strong>VIX- 200B (Basic)</strong>
										<p>VIX-100B 기능을 포함하며, 마스터 카메라(고정형 카메라)와 슬레이브 카메라(PTZ 카메라)의 조합을 통해 마스터 카메라에서 객체를 감지한 후 연동된 슬레이브
											카메라를 통해 감지된 객체를 추적하는 <span className="underline">자동 PTZ 추적</span> 기능을 제공</p>
									</div>
								</div>
								<div className="sky_blue_right">
									<img src={findImage('../assets/images/technology/img_tech07.png')} alt=""/>
								</div>
							</div>

							<div className="green_back">
								<div className="tech_hover">
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#120b',
										state: { menu: 'VS' }
									}}>
										<img src={findImage('../assets/images/technology/img_tech05.png')} alt=""/>
										<div className="technology_txt right_position">
											<strong>VIX- 120B (Basic)</strong>
											<p>VIX-100B 기능을 포함하며, 영상 내 이동하는 객체(사람, 차량 등)을 자동으로 감지하고 추적한 후 정해진 이벤트 감지를 수행할 수 있는 지능형 영상 감시
												S/W로, 모션 기반 및 딥러닝 기반 객체 검출, 감지 및 추적을 동시에 제공할 뿐만 아니라 <span className="underline">인식된 보행자의 속성, 차량의 종류를 실시간으로 분석</span>하여
												제공</p>
										</div>
									</Link>
								</div>

								<div className="Ygreen_back">
									<div className="tech_hover">
										<img src={findImage('../assets/images/technology/img_tech06.png')} alt=""/>
										<div className="technology_txt">
											<strong>VIX- 100B (Basic)</strong>
											<p>영상 내 이동하는 객체(사람, 차량 등)을 자동으로 감지하고 추적한 후 정해진 이벤트 감지를 수행할 수 있는 지능형 영상 감시 S/W로, 모션 기반 및 딥러닝 기반
												객체 검출, 감지 및 추적을 동시에 제공하며, <span className="underline">배회, 불꽃, 경계선 통과 등 다양한 이벤트를 제공</span></p>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>


					<div className="M_technology">
						<div className="M_dark"><strong className="Mtech_tit">VIX-420M<br/>(*군용 특수 솔루션)</strong></div>
						<div className="Mblue">
							<strong className="Mtech_tit"><Link to={{
								pathname: '/main/BusinessArea',
								hash: '#220b',
								state: { menu: 'VS' }
							}}>VIX- 220B (Basic)</Link></strong>
							<p className="Mtxt_list">
								+ 지능형 이벤트 감지<br/>
								+ 딥러닝 기반 객체 속성 정보 분석<br/>
								+ 자동 추적<br/>
							</p>
							<div className="Micon_box">
								<img src={findImage('../assets/images/technology/ico_tech02.png')} alt=""/>
								<img src={findImage('../assets/images/technology/ico_tech03.png')} alt=""/>
								<img src={findImage('../assets/images/technology/ico_tech01.png')} alt=""/>
							</div>
							<button className="more_btn">더보기</button>
							<p className="more_txt">VIX-120B 기능을 포함하며, 마스터 카메라(고정형 카메라)와 슬레이브 카메라(PTZ 카메라)의 조합을 통해 마스터 카메라에서 객체를 감지한 후
								연동된 슬레이브 카메라를 통해 감지된 객체를 추적하는 <span className="underline">자동 PTZ 추적</span> 기능을 제공</p>
							<ul className="Mtech_dot underline clear">
								<li>
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#220s',
										state: { menu: 'IS' }
									}}>
										<img src={findImage('../assets/images/technology/ico_tech07.png')} alt=""/>
										<div className="Mdot_right">
											<strong>VIX-220S</strong>
											<span>+ AI 산업안전</span>
										</div>
									</Link>
								</li>
								<li>
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#220r',
										state: { menu: 'ITS' }
									}}>
										<img src={findImage('../assets/images/technology/ico_tech04.png')} alt=""/>
										<div className="Mdot_right">
											<strong>VIX-220R</strong>
											<span>+ AI 교통 분석</span>
										</div>
									</Link>
								</li>
								<li>
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#220f',
										state: { menu: 'BI' }
									}}>
										<img src={findImage('../assets/images/technology/ico_tech06.png')} alt=""/>
										<div className="Mdot_right">
											<strong>VIX-220F</strong>
											<span>+ 얼굴 인식</span>
										</div>
									</Link>
								</li>
								<li>
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#220p',
										state: { menu: 'ITS' }
									}}>
										<img src={findImage('../assets/images/technology/ico_tech05.png')} alt=""/>
										<div className="Mdot_right">
											<strong>VIX-220P</strong>
											<span>+ 차량 번호판 인식</span>
										</div>
									</Link>
								</li>
							</ul>

							<button className="more_btn">더보기</button>
							<div className="Mtechnology_txt_js more_txt">
								<div className="Mtechnology_txt">
									<strong>VIX-220S</strong>
									<p>220B 기능을 포함하고 있으며, <span className="underline">건설현장에서 효율적인 안전 관리를 위한 영상 분석</span> 이벤트 분석 수행</p>
									<strong>VIX-220R</strong>
									<p>220B 기능을 포함하고 있으며, 도로 유고 이벤트를 감지하며 <span className="underline">딥러닝 기반 도로 교통 정보 분석</span>을 수행</p>
									<strong>VIX-220F</strong>
									<p>220B 기능을 포함하고 있으며,얼굴을 감지하고 추적한 후 <span className="underline">나이 예측, 성별 판별,표정 인식, 얼굴 인식</span>을 수행
									</p>
									<strong>VIX-220P</strong>
									<p>220B 기능을 포함하고 있으며, 자동 PTZ 추적과 연계해 일반 PTZ 카메라를 이용해 추적되는 관심 차량에 대한 줌인 영상을 확보 후 <span
										className="underline">실시간으로 차량 번호판 인식 수행</span></p>
								</div>
							</div>

						</div>

						<div className="Mskyblue">
							<strong className="Mtech_tit">VIX- 200B (Basic)</strong>
							<p className="Mtxt_list">
								+ 지능형 이벤트 감지<br/>
								+ 자동 PTZ 추적<br/>
							</p>
							<div className="Micon_box">
								<img src={findImage('../assets/images/technology/ico_tech02.png')} alt=""/>
								<img src={findImage('../assets/images/technology/ico_tech03.png')} alt=""/>
							</div>
							<button className="more_btn">더보기</button>
							<p className="more_txt">VIX-100B 기능을 포함하며, 마스터 카메라(고정형 카메라)와 슬레이브 카메라(PTZ 카메라)의 조합을 통해 마스터 카메라에서 객체를 감지한 후
								연동된 슬레이브 카메라를 통해 감지된 객체를 추적하는 <span className="underline">자동 PTZ 추적</span> 기능을 제공</p>
							<ul className="Mtech_dot clear">
								<li>
									<img src={findImage('../assets/images/technology/ico_tech06.png')} alt=""/>
									<div className="Mdot_right">
										<strong>VIX-200F</strong>
										<span>+ 얼굴 인식</span>
									</div>
								</li>
								<li>
									<img src={findImage('../assets/images/technology/ico_tech05.png')} alt=""/>
									<div className="Mdot_right">
										<strong>VIX-200P</strong>
										<span>+ 차량 번호판 인식</span>
									</div>
								</li>
							</ul>
						</div>

						<div className="Mgreen">
							<strong className="Mtech_tit"><Link to={{
								pathname: '/main/BusinessArea',
								hash: '#120b',
								state: { menu: 'VS' }
							}}>VIX- 120B (Basic)</Link></strong>
							<p className="Mtxt_list">
								+ 지능형 이벤트 감지<br/>
								+ 딥러닝 기반 객체 속성 정보 분석<br/>
							</p>
							<div className="Micon_box">
								<img src={findImage('../assets/images/technology/ico_tech02.png')} alt=""/>
								<img src={findImage('../assets/images/technology/ico_tech01.png')} alt=""/>
							</div>
							<button className="more_btn">더보기</button>
							<p className="more_txt">VIX-100B 기능을 포함하며, 영상 내 이동하는 객체(사람, 차량 등)을 자동으로 감지하고 추적한 후 정해진 이벤트 감지를 수행할 수 있는
								지능형 영상 감시 S/W로, 모션 기반 및 딥러닝 기반 객체 검출, 감지 및 추적을 동시에 제공할 뿐만 아니라 <span className="underline">인식된 보행자의 속성, 차량의 종류를 실시간으로 분석</span>하여
								제공</p>
						</div>

						<div className="MYgreen">
							<strong className="Mtech_tit">VIX- 100B (Basic)</strong>
							<p className="Mtxt_list">+ 지능형 이벤트 감지</p>
							<div className="Micon_box">
								<img src={findImage('../assets/images/technology/ico_tech02.png')} alt=""/>
							</div>
							<button className="more_btn">더보기</button>
							<p className="more_txt">영상 내 이동하는 객체(사람, 차량 등)을 자동으로 감지하고 추적한 후 정해진 이벤트 감지를 수행할 수 있는 지능형 영상 감시 S/W로, 모션 기반
								및 딥러닝 기반 객체 검출, 감지 및 추적을 동시에 제공하며, <span className="underline">배회, 불꽃, 경계선 통과 등 다양한 이벤트를 제공</span></p>
						</div>

					</div>

				</div>
				<div className="technology_btm clear">
					<div>
						<div>
							<img src={findImage('../assets/images/technology/ico_tech02.png')} alt=""/>
						</div>
						<strong>지능형 이벤트 감지</strong>
					</div>
					<div>
						<div>
							<img src={findImage('../assets/images/technology/ico_tech01.png')} alt=""/>
						</div>
						<strong>AI 영상분석(객체 분류 외)</strong>
					</div>
					<div>
						<div>
							<img src={findImage('../assets/images/technology/ico_tech03.png')} alt=""/>
						</div>
						<strong>PTZ 자동 추적</strong>
					</div>
					<div>
						<div>
							<img src={findImage('../assets/images/technology/ico_tech04.png')} alt=""/>
						</div>
						<strong>교통 분석 이벤트</strong>
					</div>
					<div>
						<div>
							<img src={findImage('../assets/images/technology/ico_tech05.png')} alt=""/>
						</div>
						<strong>차량번호판 인식</strong>
					</div>
					<div>
						<div>
							<img src={findImage('../assets/images/technology/ico_tech06.png')} alt=""/>
						</div>
						<strong>얼굴 인식</strong>
					</div>
				</div>
			</div>
		</main>
	) : (
		<main className="sub_page eng">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>Product & Technology</li>
					<li>Product</li>
				</ul>
				<h2 className="sub_title">Product</h2>
				<p className="title_txt small_tec">This is our product line-up for creating a more convenient society.</p>
			</div>

			<div className="sub_con">
				<div className="technology_con">
					<p className="M_disnone grey_p">* Point to the model's name for detail</p>

					<div className="technology_right">
						<div><span>VIX-Monitoring<br/>+ Client</span></div>
						<div><span>SmartEye Client<br/>+Manager</span></div>
						<div><span>VIX-Search<br/>Forensic</span></div>
						<div><span>Masker Client<br/>+Server</span></div>
					</div>

					{/*pc 제품*/}
					<div className="technology_chart">
						<div className="technology_tit">
							<img src={require('MainAssets/assets/images/technology/Etechnology_tit.png')} alt=""/>
						</div>

						<div className="blue_back">

							<div className="blue_leftBox">
								<div className="tech_left01">
									<img src={require('MainAssets/assets/images/technology/Eimg_tech01.png')} alt=""/>
									<div className="tech_hover tech_leftPosion">
										<Link to={{
											pathname: '/main/BusinessArea',
											hash: '#220s',
											state: { menu: 'IS' }
										}}>
											<div className="technology_txt">
												<strong>VIX-220S</strong>
												<p>Including VIX-220B function + <span className="underline">Video analytics for efficient safety management at construction sites</span>
												</p>
											</div>
										</Link>
									</div>
									<div className="tech_hover tech_rightPosion">
										<Link to={{
											pathname: '/main/BusinessArea',
											hash: '#220r',
											state: { menu: 'ITS' }
										}}>
											<div className="technology_txt">
												<strong>VIX-220R</strong>
												<p>Including VIX-220B function + <span className="underline">Road event detection and deep learning-based road traffic information analytics</span>
												</p>
											</div>
										</Link>
									</div>
								</div>

								<div className="Mdis_block" />

								<div className="tech_left02">
									<img src={require('MainAssets/assets/images/technology/Eimg_tech02.png')} alt=""/>
									<div className="tech_hover tech_leftPosion">
										<Link to={{
											pathname: '/main/BusinessArea',
											hash: '#220f',
											state: { menu: 'BI' }
										}}>
											<div className="technology_txt">
												<strong>VIX-220F</strong>
												<p>Including VIX-220B function + <span className="underline">Age estimation, Gender classification, face recognition after detecting the face</span>
												</p>
											</div>
										</Link>
									</div>
									<div className="tech_hover tech_rightPosion">
										<Link to={{
											pathname: '/main/BusinessArea',
											hash: '#220p',
											state: { menu: 'ITS' }
										}} />
									</div>
								</div>
							</div>

							<div className="blue_right tech_hover">
								<Link to={{
									pathname: '/main/BusinessArea',
									hash: '#220b',
									state: { menu: 'VS' }
								}}>
									<img src={require('MainAssets/assets/images/technology/Eimg_tech03.png')} alt=""/>
									<div className="technology_txt right_position">
										<strong>VIX- 220B (Basic)</strong>
										<p>Including VIX-120B function + <span className="underline">Automatic PTZ tracking </span>function
											that detects objects through a combination of a <span className="underline">Master camera</span>(Static
											camera) and a <span className="underline">Slave camera</span>(PTZ camera)</p>
									</div>
								</Link>
							</div>

							<div className="sky_blue_back">
								<div className="tech_hover sky_blue_left">
									<img src={require('MainAssets/assets/images/technology/Eimg_tech04.png')} alt=""/>
									<div className="technology_txt">
										<strong>VIX- 200B (Basic)</strong>
										<p>Including VIX-100B function + <span className="underline">Automatic PTZ tracking </span>function
											that detects objects through a combination of a <span className="underline">Master camera</span>(Static
											camera) and a <span className="underline">Slave camera</span>(PTZ camera)</p>
									</div>
								</div>
								<div className="sky_blue_right">
									<img src={require('MainAssets/assets/images/technology/Eimg_tech07.png')} alt=""/>
								</div>
							</div>

							<div className="green_back">
								<div className="tech_hover">
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#120b',
										state: { menu: 'VS' }
									}}>
										<img src={require('MainAssets/assets/images/technology/Eimg_tech05.png')} alt=""/>
										<div className="technology_txt right_position">
											<strong>VIX- 120B (Basic)</strong>
											<p>Including VIX-100B function + <span className="underline">Human & Vehicle attribute analytics in real time</span>
											</p>
										</div>
									</Link>
								</div>

								<div className="Ygreen_back">
									<div className="tech_hover">
										<img src={require('MainAssets/assets/images/technology/Eimg_tech06.png')} alt=""/>
										<div className="technology_txt">
											<strong>VIX- 100B (Basic)</strong>
											<p>Intelligent video monitoring S/W that can automatically detect and track moving objects
												(people, vehicles, etc.) in the video and perform set event detection, provides motion-based
												and deep learning-based object detection, and <span className="underline">provides various events such as loitering, fire, and line crossing.</span>
											</p>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>

					{/*mobile 제품*/}
					<div className="M_technology">
						<div className="M_dark"><strong className="Mtech_tit">VIX-420M</strong></div>
						<div className="Mblue">
							<strong className="Mtech_tit"><Link to={{
								pathname: '/main/BusinessArea',
								hash: '#220b',
								state: { menu: 'VS' }
							}}>VIX- 220B (Basic)</Link></strong>
							<p className="Mtxt_list">
								+ Video Event Detection<br/>
								+ Object Attribute Recognition<br/>
								+ Auto Tracking<br/>
							</p>
							<div className="Micon_box">
								<img src={require('MainAssets/assets/images/technology/ico_tech02.png')} alt=""/>
								<img src={require('MainAssets/assets/images/technology/ico_tech03.png')} alt=""/>
								<img src={require('MainAssets/assets/images/technology/ico_tech01.png')} alt=""/>
							</div>
							<button className="more_btn">more</button>
							<p className="more_txt">Including VIX-120B function + <span
								className="underline">Automatic PTZ tracking </span>function that detects objects through a combination
								of a <span className="underline">Master camera</span>(Static camera) and a <span className="underline">Slave camera</span>(PTZ
								camera)</p>
							<ul className="Mtech_dot underline clear">
								<li>
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#220s',
										state: { menu: 'IS' }
									}}>
										<img src={require('MainAssets/assets/images/technology/ico_tech07.png')} alt=""/>
										<div className="Mdot_right">
											<strong>VIX-220S</strong>
											<span>+ Industrial Safety</span>
										</div>
									</Link>
								</li>
								<li>
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#220r',
										state: { menu: 'ITS' }
									}}>
										<img src={require('MainAssets/assets/images/technology/ico_tech04.png')} alt=""/>
										<div className="Mdot_right">
											<strong>VIX-220R</strong>
											<span>+ Traffic Analytics</span>
										</div>
									</Link>
								</li>
								<li>
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#220f',
										state: { menu: 'BI' }
									}}>
										<img src={require('MainAssets/assets/images/technology/ico_tech06.png')} alt=""/>
										<div className="Mdot_right">
											<strong>VIX-220F</strong>
											<span>+ Face Recognition</span>
										</div>
									</Link>
								</li>
								<li>
									<Link to={{
										pathname: '/main/BusinessArea',
										hash: '#220p',
										state: { menu: 'ITS' }
									}}>
										<img src={require('MainAssets/assets/images/technology/ico_tech05.png')} alt=""/>
										<div className="Mdot_right">
											<strong>VIX-220P</strong>
											<span>+ License Plate Recognition</span>
										</div>
									</Link>
								</li>
							</ul>

							<button className="more_btn">more</button>
							<div className="Mtechnology_txt_js more_txt">
								<div className="Mtechnology_txt">
									<strong>VIX-220S</strong>
									<p>Including VIX-220B function + <span className="underline">Video analytics for efficient safety management at construction sites</span>
									</p>
									<strong>VIX-220R</strong>
									<p>Including VIX-220B function + <span className="underline">Road event detection and deep learning-based road traffic information analytics</span>
									</p>
									<strong>VIX-220F</strong>
									<p>Including VIX-220B function + <span className="underline">Age estimation, Gender classification, face recognition after detecting the face</span>
									</p>
									<strong>VIX-220P</strong>
								</div>
							</div>

						</div>

						<div className="Mskyblue">
							<strong className="Mtech_tit">VIX- 200B (Basic)</strong>
							<p className="Mtxt_list">
								+ Video Event Detection<br/>
								+ Auto PTZ Tracking<br/>
							</p>
							<div className="Micon_box">
								<img src={require('MainAssets/assets/images/technology/ico_tech02.png')} alt=""/>
								<img src={require('MainAssets/assets/images/technology/ico_tech03.png')} alt=""/>
							</div>
							<button className="more_btn">more</button>
							<p className="more_txt">Including VIX-100B function + <span
								className="underline">Automatic PTZ tracking </span>function that detects objects through a combination
								of a <span className="underline">Master camera</span>(Static camera) and a <span className="underline">Slave camera</span>(PTZ
								camera)</p>
							<ul className="Mtech_dot clear">
								<li>
									<img src={require('MainAssets/assets/images/technology/ico_tech06.png')} alt=""/>
									<div className="Mdot_right">
										<strong>VIX-200F</strong>
										<span>+ Face Recognition</span>
									</div>
								</li>
								<li>
									<img src={require('MainAssets/assets/images/technology/ico_tech05.png')} alt=""/>
									<div className="Mdot_right">
										<strong>VIX-200P</strong>
										<span>+ License Plate Recognition</span>
									</div>
								</li>
							</ul>
						</div>

						<div className="Mgreen">
							<strong className="Mtech_tit"><Link to={{
								pathname: '/main/BusinessArea',
								hash: '#120b',
								state: { menu: 'VS' }
							}}>VIX- 120B (Basic)</Link></strong>
							<p className="Mtxt_list">
								+ Video Event Detection<br/>
								+ Object Attribute Recognition<br/>
							</p>
							<div className="Micon_box">
								<img src={require('MainAssets/assets/images/technology/ico_tech02.png')} alt=""/>
								<img src={require('MainAssets/assets/images/technology/ico_tech01.png')} alt=""/>
							</div>
							<button className="more_btn">more</button>
							<p className="more_txt">Including VIX-100B function + <span className="underline">Human & Vehicle attribute analytics in real time</span>
							</p>
						</div>

						<div className="MYgreen">
							<strong className="Mtech_tit">VIX- 100B (Basic)</strong>
							<p className="Mtxt_list">+ Video Event Detection</p>
							<div className="Micon_box">
								<img src={require('MainAssets/assets/images/technology/ico_tech02.png')} alt=""/>
							</div>
							<button className="more_btn">more</button>
							<p className="more_txt">Intelligent video monitoring S/W that can automatically detect and track moving
								objects (people, vehicles, etc.) in the video and perform set event detection, provides motion-based and
								deep learning-based object detection, and <span className="underline">provides various events such as loitering, fire, and line crossing.</span>
							</p>
						</div>

					</div>

				</div>
				<div className="technology_btm clear">
					<div>
						<div>
							<img src={require('MainAssets/assets/images/technology/ico_tech02.png')} alt=""/>
						</div>
						<strong>Video Event<br/>Detection</strong>
					</div>
					<div>
						<div>
							<img src={require('MainAssets/assets/images/technology/ico_tech01.png')} alt=""/>
						</div>
						<strong>AI Video<br/>Analytics</strong>
					</div>
					<div>
						<div>
							<img src={require('MainAssets/assets/images/technology/ico_tech03.png')} alt=""/>
						</div>
						<strong>Auto PTZ Tracking</strong>
					</div>
					<div>
						<div>
							<img src={require('MainAssets/assets/images/technology/ico_tech04.png')} alt=""/>
						</div>
						<strong>Traffic Analytics</strong>
					</div>
					<div>
						<div>
							<img src={require('MainAssets/assets/images/technology/ico_tech05.png')} alt=""/>
						</div>
						<strong>License Plate<br/>Recognition</strong>
					</div>
					<div>
						<div>
							<img src={require('MainAssets/assets/images/technology/ico_tech06.png')} alt=""/>
						</div>
						<strong>Face Recognition</strong>
					</div>
				</div>
			</div>
		</main>
	)
}

export default Product
