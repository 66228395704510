import React, { useEffect, useState } from 'react'
import AdminApi from '../../../../Api/AdminApi'
import usePage from '../../../../Hooks/usePage'
import useList from '../../../../Hooks/useList'
import useAdminList from '../../../../Hooks/useAdminList'
import AdminModal from '../../../../Modal/Component/AdminModal'
import Confirm from '../../../../Modal/Component/Confirm'
import Alert from '../../../../Modal/Component/Alert'

const Admin = () => {

	const [pageCount, setPageCount] = useState(1)
	const [pageComponent, selectedPage] = usePage(pageCount)
	const [searchValue, setSearchValue] = useState('')
	const [data, setData] = useState([])
	const [listCount, setListCount] = useState(0)
	const [allChecked, setAllChecked] = useState(false)

	const [selectNo, setSelectNo] = useState(-1)

	const [detailAdmin, setDetailAdmin] = useState(false)

	const [adminInfo, setAdminInfo] = useState({})

	const [myInfo, setMyInfo] = useState(JSON.parse(sessionStorage.getItem('userInfo')))



	const [list, checkList] = useAdminList(data, listCount, selectedPage, allChecked, setSelectNo, setDetailAdmin)

	const showAdminPopup = () => {
		window.popupManager.open(AdminModal, {
			myInfo: true,
			name: myInfo.name,
			grade: myInfo.grade === 1 ? '마스터' : '일반',
			phone: myInfo.phone,
			id: myInfo.id,
			no: myInfo.no,
			onClose: () => {setDetailAdmin(false)
				getData(searchValue, selectedPage)
			}
		})
	}






	useEffect(()=> {
		getData(searchValue, selectedPage)
	},[])

	useEffect(() => {
		!detailAdmin ? setSelectNo(-1) : selectNo === myInfo.no ? showAdminPopup() : window.popupManager.open(AdminModal, {
			myInfo: false,
			detailAdmin: detailAdmin,
			adminNo: selectNo,
			onClose: () => {setDetailAdmin(false)}
		})
	}, [detailAdmin])



	const getData = (searchValue, page) => {
		let params = {
			searchValue,
			page
		}
		AdminApi.getList(params)
			.then(res => {
				setData(res.data.data.admin)
				setPageCount(res.data.data.allPage)
				setListCount(res.data.data.count)
			})
	}

	const regAdmin = () => window.popupManager.open(AdminModal, {
		onClose: () => getData(searchValue, selectedPage)
	})

	const deleteAdmin = () => {

		if (checkList.length === 0) {
			window.popupManager.open(Alert, {
				contents: '삭제할 관리자를 선택해주세요'
			})
		} else {
			if (checkList.find(item => item === myInfo.no)) {
				window.popupManager.open(Alert, {
					contents: '내 계정은 삭제 할 수 없습니다!'
				})
			} else {
				window.popupManager.open(Confirm, {
					contents: '삭제하시겠습니까?<br />삭제 시 계정을 다시 등록해야합니다.',
					onConfirm: () => {
						AdminApi.deleteAdmin(checkList)
							.then(res => {
								getData('', 0)
								setPageCount(1)
								window.popupManager.open(Alert, {
									contents: '삭제가 완료되었습니다.'
								})
							})
					}

				})
			}
		}


	}


	return (
		<>
			<div className="title_box clear">
				<h2 className="title left">관리자 계정 관리</h2>

				<ul className="right">
					<li>HOME</li>
					<li>관리자 계정 관리</li>
				</ul>
			</div>

			<div className="inp_wrap">
				<label>검색어</label>
				<input type="text" className="w_100minus171 marleft_16" placeholder="관리자명, 휴대폰 번호"/>
				<button className="black_btn w_100 marleft_1">검색</button>
			</div>

			<div className="table_wrap martop_20">
				<table className="type1 account_list">
					<colgroup>
						<col width="80px"/>
						<col width="80px"/>
						<col width="*"/>
						<col width="*"/>
						<col width="*"/>
						<col width="*"/>
						<col width="*"/>
					</colgroup>
					<thead>
					<tr>
						<th>
							<input type="checkbox" name="inpChk" className="ch_check hide allcheck" id={'allChk'}  onChange={() => setAllChecked(!allChecked)}/>
							<label htmlFor="allChk" className="label single"/>
						</th>
						<th>번호</th>
						<th>등록일</th>
						<th>이름</th>
						<th>등급</th>
						<th>휴대폰 번호</th>
						<th>E-Mail</th>
					</tr>
					</thead>
					<tbody>
					{myInfo.grade ===1 ? list : (
						<tr>
						<td>
						<input type="checkbox" name="inpChk" className="ch_check hide" id={`inpChk${myInfo.no}`}/>
						<label htmlFor={`inpChk${myInfo.no}`} className="label single"/>
						</td>
						<td>1</td>
						<td>{myInfo.create_date}</td>
						<td onClick={()=>{showAdminPopup()
					}

					}>{myInfo.name}</td>
						<td>{myInfo.grade === 1 ? '마스터' : '일반'}</td>
						<td>{myInfo.phone}</td>
						<td>{myInfo.email}</td>
						</tr>
					)}
					</tbody>
				</table>

				{pageComponent}

				{myInfo.grade === 1 && <div className="btn_bottom btn_center">
					<button type="button" className="white_btn w_70 confirm_btn" onClick={() => deleteAdmin()}>삭제</button>
					<button type="button" className="blue_btn w_120 modal_btn" onClick={() => regAdmin()}>관리자 등록</button>
				</div>}
			</div>
		</>
	)
}

export default Admin
