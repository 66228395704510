import React,{useState, useEffect, useRef} from 'react'
import { use } from 'i18next'
import Confirm from '../../../../Modal/Component/Confirm'
import NumberUtils from '../../../../Utils/NumberUtils'
import ProApi from '../../../../Api/ProApi'
import Alert from '../../../../Modal/Component/Alert'
import InstallApi from '../../../../Api/InstallApi'

const PostInstall = (props) => {


	const [model_name, setModelName] = useState('')
	const modelNameRef = useRef()

	const [type, setType] = useState('')
	const typeRef = useRef()

	const [version, setVersion] = useState('')
	const versionRef = useRef()

	const [hash, setHash] = useState('')
	const hashRef = useRef()

	const [btn_disabled, setBtnDisabled] = useState(true)

	useEffect(()=> {
		(model_name && type && version && hash) ? setBtnDisabled(false) : setBtnDisabled(true)
	},[model_name, type, version, hash])

	const onChangeHandler = (ref, setState) => {
		setState(ref.current.value)
	}

	const onSubmit = () => {
		window.popupManager.open(Confirm, {
			contents: (props.writeMode === 3 && props.updateNumber !== -1)  ? '수정하시겠습니까?' :'등록하시겠습니까?',
			onConfirm: () => {
				let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
				let data = (props.writeMode === 3 && props.updateNumber !== -1)  ? {
					no: props.updateNumber
					,model_name, type, version, hash,
					create_admin: `${userInfo.name}(${userInfo.id})`
				} : {
					model_name, type, version, hash,
					create_admin: `${userInfo.name}(${userInfo.id})`
				}
				InstallApi.insertInstall(data)
					.then(res => {
						window.popupManager.open(Alert, {
							contents: (props.writeMode === 3 && props.updateNumber !== -1)? '수정이 완료되었습니다.' :'등록이 완료되었습니다.',
							onClose: () => {props.setWriteMode(0)}
						})
					})
			}
		})
	}

	useEffect(() => {
		if (props.writeMode === 3 && props.updateNumber !== -1) {
			InstallApi.detailInstall({ no: props.updateNumber })
				.then((res) => {

					modelNameRef.current.value = res.data.data.model_name
					setModelName(res.data.data.model_name)

					typeRef.current.value = res.data.data.type
					setType(res.data.data.type)

					versionRef.current.value = res.data.data.version
					setVersion(res.data.data.version)

					hashRef.current.value = res.data.data.hash
					setHash(res.data.data.hash)
				})
		}
	}, [props.writeMode])


	return (
		<>
			<div className="title_box clear">
				<h2 className="title left">설치파일 무결성 등록</h2>

				<ul className="right">
					<li>HOME</li>
					<li>설치파일 무결성 관리</li>
					<li>설치파일 무결성 등록</li>
				</ul>
			</div>

			<div className="table_wrap">
				<table className="type2 left2_table txt_content">
					<colgroup>
						<col width="259px"/>
						<col width="*"/>
						<col width="259px"/>
						<col width="*"/>
					</colgroup>
					<tbody>
					<tr>
						<th className="required">모델명</th>
						<td>
							<input type="text" className="w_full" ref={modelNameRef} onChange={()=> onChangeHandler(modelNameRef, setModelName)}/>
						</td>
						<th className="required">방식</th>
						<td>
							<input type="text" className="w_full" ref={typeRef} onChange={()=> onChangeHandler(typeRef, setType)}/>
						</td>
					</tr>
					<tr>
						<th className="required">버전</th>
						<td colSpan="3">
							<input type="text" className="w_full" ref={versionRef} onChange={()=> onChangeHandler(versionRef, setVersion)}/>
						</td>
					</tr>
					<tr>
						<th className="required">해쉬값</th>
						<td colSpan="3">
							<input type="text" className="w_full" ref={hashRef} onChange={()=> onChangeHandler(hashRef, setHash)}/>
						</td>
					</tr>
					</tbody>
				</table>

				<div className="btn_center martop_20">
					<button type="button" className={`blue_btn w_100 confirm_btn ${btn_disabled ? 'disabled' : ''}`} disabled={btn_disabled} onClick={()=>onSubmit()}>등록</button>
					<button type="button" className="white_btn w_100" onClick={()=>props.setWriteMode(0)}>취소</button>
				</div>
			</div>
		</>
	)
}

export default PostInstall
