import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

const useMainList = (list, count, page) => {





	return (
				list.map((item, index) => {
					return (
						<div className="tbody_tr" key={item.no}>
							<span className="w_100">{count - (page * 10 + index)}</span>
							<span className="w_minus450"><Link to={`NoticeDetail/${item.no}`}>{item.title}</Link></span>
							<span className="w_200">{item.createDate}</span>
							<span className="w_150">{item.views}</span>
						</div>)
				})
	)
}

export default useMainList
