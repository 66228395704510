import React, {useEffect, useState} from 'react'
import Logo from 'AdminAssets/assets/images/logo.png'
import Notice from './Notice'
import News from './News'
import Admin from './Admin'
import AdminModal from '../../../Modal/Component/AdminModal'
import { Redirect, useHistory } from 'react-router-dom'
import Procurement from './Procurement'
import Install from './Install'

const Main = () => {

	const [menu, setMenu] = useState('notice')
	const [userInfo, setUserInfo] = useState({})
	const history = useHistory()

	useEffect(() => {
		setUserInfo(sessionStorage.getItem('userInfo') ? (JSON.parse(sessionStorage.getItem('userInfo'))) : '')
	},[])

	useEffect(() => {
		if (!sessionStorage.getItem('userInfo')) {
		  history.push('/admin/login')
		}
	}, [menu])

	const logout = () => {
		sessionStorage.removeItem('userInfo')
		sessionStorage.removeItem('auth')
		history.push('/admin/login')
	}


	const changeMenu = (menu) => {
		setMenu(menu)
	}

	const showAdminPopup = () => {
		window.popupManager.open(AdminModal, {
			myInfo: true,
			name: userInfo.name,
			grade: userInfo.grade === 1 ? '마스터' : '일반',
			phone: userInfo.phone,
			id: userInfo.id,
			no: userInfo.no
		})
	}

	return (
		<>
			<div>
				<header>
					<h1>
						<a href="#none" className="main_logo">
							<img src={Logo} alt="IntelliVIX logo" />
						</a>
					</h1>


					<nav className="gnb">
						<ul>
							<li className={menu === 'notice' ? 'active' : ''}><a onClick={()=> {changeMenu('notice')}}>공지사항 관리</a></li>
							<li className={menu === 'news' ? 'active' : ''}><a onClick={()=> {changeMenu('news')}}>뉴스룸 관리</a></li>
							<li className={menu === 'procurement' ? 'active' : ''}><a onClick={()=> {changeMenu('procurement')}}>조달우수 관리</a></li>
							<li className={menu === 'install' ? 'active' : ''}><a onClick={()=> {changeMenu('install')}}>설치파일 무결성 관리</a></li>
							<li className={menu === 'admin' ? 'active' : ''}><a onClick={()=> {changeMenu('admin')}}>관리자 계정 관리</a></li>
						</ul>
					</nav>

				</header>

				<main>
					<nav className="my_nav">
						<ul className="clear">
							<li className="user_name">{userInfo.name}({userInfo.id})님</li>
							<li>
								<button type="button" className="my_menu" onClick={()=> showAdminPopup()}>내정보</button>
							</li>
							<li>
								<button type="button" className="logout" onClick={()=>logout()}>로그아웃</button>
							</li>
						</ul>
					</nav>
					<div className="con_wrap">
						{{'notice': <Notice/>,
							'news': <News />,
							'admin': <Admin />,
							'procurement': <Procurement />,
							'install': <Install />

						}[menu]}

					</div>
				</main>
			</div>

			<footer>
				<p>Copyright(C) 2021 Intellivix.Co.,Ltd.. All rights reserved.</p>
			</footer>
		</>
	)
}

export default Main
