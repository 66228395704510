import StackModal from '../Stack/StackModal'

const Confirm = (props) => {

	const  onConfirm = async () => {
		await props.onConfirm()
		await	props.onClose()
	}

	return (
		<StackModal {...props}>
			<div className="modal_wrap confirm active">
				<div className="modal_section">
					<button className="hide_btn delete_modal" onClick={()=>props.onClose()} />
					<p className="modal_text" dangerouslySetInnerHTML={{__html: props.contents}} />
					<div className="btn_wrap">
						<button type="button" className="blue_btn w_100 btn_ok" onClick={()=>onConfirm()}>확인</button>
						<button type="button" className="white_btn w_100 delete_modal" onClick={()=>props.onClose()}>취소</button>
					</div>
				</div>
			</div>
		</StackModal>
	)
}

export default Confirm
