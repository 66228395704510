import React, { useState, useEffect, useRef } from 'react'
import logo from 'MainAssets/assets/images/logo.png'
import vision from 'MainAssets/assets/images/img_vision.png'
import chart from 'MainAssets/assets/images/img_chart.png'
import complete from 'MainAssets/assets/images/img_compete.png'
import map from 'MainAssets/assets/images/img_map.png'
import map2 from 'MainAssets/assets/images/img_map2.png'
import car from 'MainAssets/assets/images/icon/car.png'
import subway from 'MainAssets/assets/images/icon/subway.png'

import business01 from 'MainAssets/assets/images/icon/business_01.png'
import business02 from 'MainAssets/assets/images/icon/business_02.png'
import business03 from 'MainAssets/assets/images/icon/business_03.png'
import business04 from 'MainAssets/assets/images/icon/business_04.png'
import business05 from 'MainAssets/assets/images/icon/business_05.png'
import { useTranslation } from 'react-i18next'
import { Map, MapMarker } from 'react-kakao-maps-sdk'
import useKakaoMap from '../../../../Hooks/useKakaoMap'
//chart complete map car subway

const Information = () => {
	const { t, i18n } = useTranslation()

	const mapComponent = useKakaoMap()


	return i18n.language === "ko" ? (
		<>
			<div className="sub_imgBox img4">
				<div className="title_imgTxt">
					<p className="title_txt">앞선 경쟁력, 최고의 가치를 전달하는 Vision AI 선도기업 인텔리빅스</p>
					<p className="sub_txt martop_30">
						인텔리빅스는 기술 개발 및 시스템 구축을 통해 영상 분석 핵심 노하우를 보유하고 있는 Vision AI 전문 기업
						입니다.{" "}
						<span className="dis_block">
							Vision AI 핵심 기술 개발 / 상품화를 통해 지속적으로 높은 정밀도의 지능형 영상분석 기술 및 서비스를
							제공하고 있습니다.
						</span>
					</p>
				</div>
			</div>

			<div className="sub_con">
				<div className="outline_cpn clear">
					<h3 className="left">회사개요</h3>
					<ul className="cpn_list cpn_right left">
						<li>
							<strong>회사명</strong>
							<span>주식회사 인텔리빅스</span>
						</li>
						<li>
							<strong>대표이사</strong>
							<span>최은수, 장정훈</span>
						</li>
						<li>
							<strong>설립일</strong>
							<span>2000년 6월</span>
						</li>
						<li>
							<strong>주소</strong>
							<span>서울특별시 서초구 효령로 34길 4 프린스 효령빌딩 6층</span>
						</li>
						<li>
							<strong>공장 주소</strong>
							<span>경기도 의왕시 성고개로 53 908호, 909호 (포일동, 에이스 청계타워)</span>
						</li>
						<li>
							<strong>연락처</strong>
							<span>(T) 02-581-3883 / (F)02 – 581 – 3886 / intellivix@intellivix.ai</span>
						</li>
					</ul>
				</div>
			</div>

			<div className="ci_conbox">
				<div className="outline_cpn clear sub_con">
					<h3 className="left">
						CI <span className="small_h3">(Corporate Identity)</span>
					</h3>
					<div className="cpn_right right">
						<div className="ci_img">
							<div className="ci_logo">
								<img src={logo} alt="" />
							</div>
						</div>
						<strong className="ci_strong">
							인텔리빅스(IntelliVIX)는
							<br />
							‘Intelligent(Intelli) + Video(VI) + Extension(X)를 담은 의미로, 지능화된 비전 AI 기술을 통해 행복하고 더
							나은 미래로 성장해 간다는 의미를 담고 있습니다.
						</strong>
						<div className="clear ci_txt">
							<p>
								인텔리빅스의 CI는 인간의 눈을 닮은 인공지능 카메라 초점을 상징합니다. Vision AI 기술 전문 기업의
								이미지를 원과 원이 융합되고 끊임없이 회전되는 형태로 형상화 하였고 세계로 뻗어나가는 원동력을
								상징합니다. 지속적인 혁신을 통해 새로운 Vision AI기술들을 보여줌으로써 세계로 성장해 나갈 것입니다.
							</p>
							<p>
								인텔리빅스 블루는 정직을 표현합니다. 20년 이상 Vision AI 기술에 집중하며 쌓아온 기술력을 통해 고객이
								믿고 신뢰할 수 있는 정직한 기술을 약속합니다.{" "}
							</p>
							<p>
								인텔리빅스 스카이블루는 도전을 표현합니다. 글로벌 기업을 향해 도전하는 미래지향적인 Vision AI 기술을
								보여줍니다.
							</p>
							<p>
								인텔리빅스 그린은 열정을 표현합니다. 밝은 열정과 에너지 가지고 지속적인 혁신을 통해 성장하겠다는 의지를
								담고 있습니다.
							</p>
						</div>
						<div className="ci_color">
							<span>Gradation Color</span>
							<div className="blue">인텔리빅스 블루</div>
							<div className="sky_blue">인텔리빅스 스카이 블루</div>
							<div className="green">인텔리빅스 그린</div>
						</div>
					</div>
				</div>
			</div>

			<div className="sub_con">
				<div className="outline_cpn border_grey clear">
					<h3 className="left">
						비전 및 <span className="dis_block">경영철학</span>
					</h3>
					<div className="cpn_right right">
						<strong className="vision_tit">핵심 비전</strong>
						<span className="vision_key">“행복한 사람들, 더 나은 미래”</span>
						<p className="vision_txt">
							인텔리빅스는 우리 생활과 밀접한 관계를 갖고 있는 영상보안, 산업안전, 리테일, 스마트팜 등 다양한 분야에
							첨단 AI 기술로 사람들을 행복하게 하고 좀 더 나은 미래를 열어갈 수 있다는 믿음과 비전이 있습니다.
						</p>
						<strong className="vision_tit">경영철학</strong>
						<div className="vision_img">
							<img src={vision} alt="" />
						</div>
					</div>
				</div>

				{/*230130 HJ 조직도 삭제*/}
				{/*<div className="outline_cpn border_grey clear">*/}
				{/*	<h3 className="left">조직도</h3>*/}
				{/*	<div className="cpn_right right">*/}
				{/*		<div className="chart_img">*/}
				{/*			<img src={chart} alt="" />*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}

				<div className="outline_cpn clear">
					<h3 className="left">기업경쟁력</h3>
					<div className="cpn_right right">
						<div className="compete_img">
							<img src={complete} alt="" />
						</div>
					</div>
				</div>
			</div>

			<div className="map_con">
				<div className="sub_con clear">
					<h3>찾아오시는 길</h3>
					<div className="map_conbox">
						{mapComponent}
						<div className="map_white clear">
							<strong className="tit_01">본사</strong>
							<div>
								<img src={car} alt="" />
								<strong>자가용 이용시</strong>
								<div>
									<p>도로명주소 : 서울특별시 서초구 효령로34길 4, 6층</p>
									<p>지번주소 : 서울 서초구 방배동 984-3 프린스효령빌딩 6층</p>
								</div>
							</div>
							<div>
								<img src={subway} alt="" />
								<strong>대중교통 이용시</strong>
								<div>
									<p>지하철 2호선 방배역 1번 출구 강남역 방향 100m</p>
									<p>버스 4319, 6016, 마을버스 서초07, 서초13, 서초17, 방배역 1번출구 정류장 하차</p>
									<p>버스 148, 350, 461, 641, 마을버스 서초16 방배역, 백석예술대학교 정류장 하차 후 강남역 방향 200m</p>
								</div>
							</div>
						</div>
					</div>
					<div className="w_minus330 right">
						<div className="map_white">
							<strong className="tit_01 w_115">공장</strong>
							<p className="dis_inblock">지번주소 : 경기도 의왕시 포일동 657-2 에이스 청계타워 제9층 A908호</p>
						</div>
						<div className="map_white">
							<strong className="tit_01 w_115">연락처</strong>
							<p className="dis_inblock">
								전화 : 02-581-3883 / 팩스 : 02-581-3886 / 이메일 : intellivix@intellivix.ai
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	) : (
		<>
			<div className="sub_imgBox img4" />

			<div className="sub_con">
				<div className="outline_cpn clear">
					<h3 className="left w_230">
						Company <span className="dis_block">Overview</span>
					</h3>
					<ul className="cpn_list cpn_right left">
						<li>
							<strong>Company Name</strong>
							<span>IntelliVIX</span>
						</li>
						<li>
							<strong>CEO</strong>
							<span>Eun Soo Choi, Jeong Hun Jang</span>
						</li>
						<li>
							<strong>Foundation</strong>
							<span>2000. 06</span>
						</li>
					</ul>
				</div>
			</div>

			<div className="map_con">
				<div className="sub_con">
					<h3>Location</h3>
					{/*<div className="map_img">*/}
					{/*	<img src="../assets/images/img_map2.png" alt="" className="img_map" />*/}
					{/*</div>*/}
					{mapComponent}
					<div className="map_white clear martop_40">
						<strong className="tit_01">Headquarter</strong>
						<p>6F, Prince Hyoryung Building, 4, Hyoryungro 34-gil, Seocho-gu, Seoul 06704, Republic of Korea</p>
					</div>
					<div className="map_white">
						<p>T : +82-581-3883 / F : +82-581-3886 / E : intellivix@intellivix.ai</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default Information
