import CommonAxios from 'Axios/CommonAxios'

export default {
	postLogin (id, password) {
		return CommonAxios({
			url: '/api/admin/login',
			method: 'POST',
			data: {
				id,
				password,
				isLogin: true
			}
		})
	},

	getList (params) {
		return CommonAxios(({
			url: 'api/admin/list',
			method: 'GET',
			params
		}))
	},

	signAdmin (data) {
		return CommonAxios(({
			url: '/api/admin/signup',
			method: 'POST',
			data
		}))
	},

	checkDup (params) {
		return CommonAxios(({
			url:'/api/admin/dupChk',
			method: 'GET',
			params
		}))
	},

	detailAdmin (params) {
		return CommonAxios(({
			url: '/api/admin/adminInfo',
			method: 'GET',
			params
		}))
	},
	modifyAdmin(data) {
		return CommonAxios(({
			url: '/api/admin/modify',
			method: 'PUT',
			data
		}))
	},

	deleteAdmin (data) {
		return CommonAxios(({
			url: `api/admin/delete`,
			method: 'DELETE',
			data
		}))
	}
}
