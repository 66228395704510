import React from 'react'
import { Route, Redirect } from 'react-router-dom'


const PrivateRoute = ({ component: Component, ...rest }) => {

	const isLogin = () => {
		return !!sessionStorage.getItem('userInfo')
	}

	return (
		<Route {...rest} render={props => (isLogin() ? <Component {...props} /> : <Redirect to='/admin/login'/> ) }/>)
}
export default PrivateRoute

