import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import korImg from 'MainAssets/assets/images/img_technology2.png'
import endImg from 'MainAssets/assets/images/img_technology_eng.png'

const Tec = () => {
	const [selectedMenu, setSelectedMenu] = useState('VS')
	const { t, i18n } = useTranslation()

	const lang = i18n.language === 'ko'

	return (
		<main className={`sub_page ${lang ? 'kor' : 'eng'}`}>
			<div className="sub_con">
				{lang ? <ul className="sub_nav">
						<li>제품과 기술</li>
						<li>기술</li>
					</ul> :
					<ul className="sub_nav">
						<li>Product & Technology</li>
						<li>Technology</li>
					</ul>
				}
				<h2 className="sub_title">{lang ? '기술 소개' : 'Technology'}</h2>
				{lang ? <p className="title_txt small_tec">인텔리빅스는 영상분석 기술에 끊임없는 연구개발 및 투자로 Vision AI 기술 확보에 성공하였으며 국내 Vision
					AI 기술 수준의 표준을 제시하고 있습니다.</p> : <p className="title_txt">Intellivix succeeds in securing Vision AI technology
					through constant development and investment in video analytics
					technology. <br/>It presents standards at the level of domestic vision AI technology.</p>
				}

				<div className={`img_technology`}>
					<img src={lang ? korImg : endImg} alt=""/>
				</div>

			</div>
		</main>
	)
}

export default Tec
