import React, {useState, useEffect} from 'react';

const usePage = (pageCount) => {


	const [pageBundle, setPageBundle] = useState(1)
	const [selectedPage, setSelectedPage] = useState(0)
	const [currentPageBundle, setCurrentPageBundle] = useState(1)
	const [pageNumber, setPageNumber] = useState([])

	useEffect(()=>{
		setPageBundle(Math.ceil(pageCount / 10))
		setCurrentPageBundle(1)
		setSelectedPage(0)
		calcPageNumber(pageCount)
	},[pageCount])

	useEffect(() => {
		setCurrentPageBundle(Math.ceil((selectedPage + 2) / 10))
	}, [selectedPage])

	useEffect(() => {
		setCurrentPageBundle(currentPageBundle === 0 ? 1 : currentPageBundle)
	}, [currentPageBundle])

	const calcPageNumber = (number) => {
		setPageNumber([])
		for (let i = 1; i <= number; i++) {
			setPageNumber(pageNumber => pageNumber.concat(i))
		}
	}

	const pageComponent = (bundle) => pageNumber.map((item) => {
		if(item < bundle * 10 && item >= (bundle - 1) * 10) {
			return (
				<li key={item}>
					<button type="button" className={selectedPage === item - 1 ? 'paging_num on' : 'paging_num'} onClick={() => {setSelectedPage(item - 1)}}>{item}</button>
				</li>
			)
		}
		}
	)


	return (
		[<div className="page_wrap martop_20">
			<div className="pagination">
				<ul>
					<li>
						<button type="button" className="paging_first" onClick={()=> setSelectedPage(0)} />
					</li>
					<li>
						<button type="button" className="paging_left" onClick={() => selectedPage !== 0 ? setSelectedPage(selectedPage - 1) : null} />
					</li>
					{pageComponent(currentPageBundle)}
					<li>
						<button type="button" className="paging_right" onClick={() => selectedPage + 1 < (pageNumber.length) ? setSelectedPage(selectedPage + 1) : null} />
					</li>
					<li>
						<button type="button" className="paging_last" onClick={()=> setSelectedPage(pageCount - 1 < 0 ? 0 : pageCount - 1)} />
					</li>
				</ul>
			</div>
		</div>, selectedPage]
	)

}

export default usePage
