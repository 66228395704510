import React, {useState, useRef, useEffect, useCallback} from 'react';

const useDataSearch = (state1, state2, menu) => {

	const ref1 = useRef()
	const ref2 = useRef()

	const searchRef1 = useRef()
	const searchRef2 = useRef()

	useEffect(()=> {
		searchRef1.current.value = searchRef1.current &&  ''
		searchRef2.current.value = searchRef2.current &&  ''
	},[menu])


	const search = useCallback((data, state, select , search)=> {
		let filter = data.filter(item => {
			return item[select.current.value].includes(search.current.value)
		})
		state(filter)

	},[state1.data, state2.data])

	return [
		(<div className="product_inp right">
			<select className="w_150" ref={ref1}>
				{state1.select.map(item => (
					<option key={item.value} value={item.value}>{item.label}</option>
				))}
			</select>
			<input type="text" className="w_280" placeholder="검색어를 입력해주세요" ref={searchRef1}/>
			<button className="skyblue_btn w_100 marleft_10" onClick={(e) => search(state1.data, state1.setState, ref1, searchRef1)}>검색</button>
		</div>), 	(<div className="product_inp right">
			<select className="w_150" ref={ref2}>
				{state2.select.map(item => (
					<option key={item.value} value={item.value}>{item.label}</option>
				))}
			</select>
			<input type="text" className="w_280" placeholder="검색어를 입력해주세요" ref={searchRef2}/>
			<button className="skyblue_btn w_100 marleft_10" onClick={() => search(state2.data, state2.setState, ref2, searchRef2)}>검색</button>
		</div>)]
}

export default useDataSearch


