import react,{useEffect} from 'react'
import { BrowserRouter, Route, Switch, Redirect, useHistory, Link } from 'react-router-dom'
//import 'MainAssets/assets/css/common.css'
import 'MainAssets/assets/js/common'

import Main from '../Views/Main/Main'
import Logo from '../MainAssets/assets/images/logo.png'
import React from 'react'
import Award from '../Views/Main/CompanyInfo/Award'
import History from '../Views/Main/CompanyInfo/Histroy'
import Introduction from '../Views/Main/Introduction'
import Information from '../Views/Main/CompanyInfo/Information'
import NewsRoom from '../Views/Main/NewsRoom'
import Support from '../Views/Main/Support'
import Procurement from '../Views/Main/Procurement'

import { useTranslation } from 'react-i18next'
import NavBar from '../Views/Main/Main/NavBar'
import Footer from '../Views/Main/Main/Footer'
import Notice from '../Views/Main/Notice'
import Detail from '../Views/Main/Notice/Detail'
import useMediaQuery from '../Hooks/useMediaQuery'
import CompanyInfo from '../Views/Main/CompanyInfo'
import BusinessArea from '../Views/Main/ProductTec/BusinessArea'
import Product from '../Views/Main/ProductTec/Product'
import Tec from '../Views/Main/ProductTec/Tec'
import Install from '../Views/Main/Install'
import AIEdgeBox from '../Views/Main/ProductTec/AIEdgeBox/index,js'

// if (!window.matchMedia('(min-width: 768px)').matches) {
// import('MainAssets/assets/css/mobile.css')
// }
//window.matchMedia('(min-width: 768px)').matches ? import('MainAssets/assets/css/mobile.css') : import('MainAssets/assets/css/common.css')


const MainRouter = () => {

	return (
		<>

			<BrowserRouter basename={process.env.PUBLIC_URL}>
				{!window.location.pathname.startsWith('/admin') && <NavBar/>}
				<Switch>
					<Route exact path={'/main'} component={Main}/>
					<Route exact path={'/'} component={() => { return (<Redirect to='/main'/>) }} />

					<Route exact path={'/main/Award'} component={Award}/>
					<Route exact path={'/main/Information'} component={Information}/>
					<Route exact path={'/main/Introduction'} component={Introduction}/>
					<Route exact path={'/main/History'} component={History}/>
					<Route exact path={'/main/NewsRoom'} component={NewsRoom}/>
					<Route exact path={'/main/Support'} component={Support}/>
					<Route exact path={'/main/Notice'} component={Notice}/>
					<Route exact path={'/main/Procurement'} component={Procurement} />
					<Route exact path={'/main/CompanyInfo'} component={CompanyInfo} />
					<Route exact path={'/main/BusinessArea'} component={BusinessArea} />
					<Route exact path={'/main/Product'} component={Product} />
					<Route exact path={'/main/Tec'} component={Tec} />
					<Route exact path={'/main/install'} component={Install} />
					<Route exact path={'/main/EdgeBox'} component={AIEdgeBox} />


					<Route path={'/main/NoticeDetail/:no'} component={Detail} />
				</Switch>
				{window.location.pathname.startsWith('/main') && <Footer />}
			</BrowserRouter>
		</>
	)
}

export default MainRouter
