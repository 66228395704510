import React,{useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import { onlyUseKR, notOnlyUseKR } from '../../../../Reducer/useOnlyKR'

const Award = () => {

	const dispatch = useDispatch()

	useEffect(()=> {
		dispatch(onlyUseKR(true))

		return () => {
			dispatch(notOnlyUseKR(false))
		}
	},[])

	const findImage = (path) => {
		let imgArray = path.split('/')
		let img = imgArray[imgArray.length - 1]
		// console.log(img);

		return require(`MainAssets/assets/images/award/${img}`)
	}


	const [selectedMenu, setSelectedMenu] = useState('i')


	return (
		<>
			<div className="sub_imgBox img6" />
			<div className="sub_con">
				<div className="history_tab">
					<button className={ selectedMenu === 'i' ? 'active' : ''} onClick={()=> setSelectedMenu('i')}>인증</button>
					<button className={ selectedMenu === 's' ? 'active' : ''} onClick={()=> setSelectedMenu('s')}>수상</button>
					<button className={ selectedMenu === 't' ? 'active' : ''} onClick={()=> setSelectedMenu('t')}>특허</button>
				</div>

				<ul className={`award_panel clear ${selectedMenu === 'i' ? '' : 'dis_none'}`}>
					{/*20240221 HJ 인증 컨텐츠 추가 (23년도)*/}
					<li>
                    <div className="award_img">
                        <img src={findImage("../assets/images/award/award_2023_01.png")} alt=""/>
                    </div>
                    <div>
                        <em>2023</em>
                        <p>우수기업연구소 인증</p>
                    </div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2023_02.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>NEP 신제품 인증</p>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2023_03.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>산업융합 선도기업</p>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2023_04.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>직무발명 우수기업</p>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2023_05.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>KISA 얼굴인식 성능인증</p>
						</div>
					</li>

					{/*230130 HJ 인증 추가 2022*/}
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_01.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>해외조달시장 진출유망기업</p>
							<span>딥러닝 기반 엣지형 영상분석 장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_02.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>녹색기술 인증</p>
							<span>딥러닝 기반 엣지형 영상분석 장치 제조 기술</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_03.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>재난안전제품 인증</p>
							<span>작업장 안전감시를 위한 딥러닝 기반의 엣지형CCTV영상분석 장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_04.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>EPC 성능인증</p>
							<span>딥러닝 기반의 엣지형 영상분석장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_05.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>상생협력제품 인증</p>
							<span>국산AI카메라블록을 이용한 엣지형 영상분석 장치 CCTV 시스템</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_06.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>혁신제품 지정 인증</p>
							<span>딥러닝 기반의 엣지형 영상분석 장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_07.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>시범구매제품 선정</p>
							<span>딥러닝 기반의 엣지형 영상분석 장치 CCTV 시스템</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_08.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>ISO45001</p>
							<span>안전보건경영시스템 인증</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_09.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>ISO14001</p>
							<span>환경경영시스템 인증</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_02.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>2022 Emerging AI Top100</p>
							<span>Image/Video 분야 선정 </span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_02.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>ITS 2022 K-혁신기업</p>
							<span>딥러닝 기반 엣지형CCTV 영상분석 장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_new01.png")} alt="" />
						</div>
						<div>
							<em>2022</em>
							<p>TTA 인증</p>
							<span>공공기관용 IP카메라 보안 성능 품질 Ver.1</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_01.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>한국건설기술연구원<br/>돌발 상황 검지 성능평가</p>
							<span>전 분야 최상급 획득 (정검지율(TDR) 100%, 다른유형검지율(DTDR) 0%, 오경보(FA) 0%)</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_02.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>국토교통부 & 금융위원회<br/>「혁신기업 국가대표 1000」</p>
							<span>국토 교통 분야</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_02.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>과기정통부 & 한국지능<br/>정보사회진흥원</p>
							<span>2021년도 DNA 혁신 기업 선정<br/>인공지능(AI) 분야</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_03.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>산업통상자원부장관</p>
							<span>딥러닝(DCNN) 기반의 엣지형 영상분석 장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_new02.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>우수발명품 우선구매선정서</p>
							<span>딥러닝(DCNN) 기반의 엣지형 영상분석 장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_new03.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>조달청 우수 제품 지정</p>
							<span>딥러닝(DCNN) 기반의 엣지형 영상분석장치 CCTV 시스템</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_new04.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>K마크</p>
							<span>딥러닝(DCNN) 기반의 엣지형 영상분석 장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_05.png")} alt="" />
						</div>
						<div>
							<em>2020</em>
							<p>중소벤처기업<br/>기술혁신형중소기업</p>
							<span>INNOBIZ</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_09.png")} alt="" />
						</div>
						<div>
							<em>2018</em>
							<p>한국인터넷진흥원(KISA)<br/>얼굴 인식 알고리즘 성능 인증</p>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_10.png")} alt="" />
						</div>
						<div>
							<em>2017</em>
							<p>한국인터넷진흥원(KISA)<br/>지능형 CCTV 성능 인증</p>
							<span>국내 1호 인증<br/>민간/방위 사업 인증 보유</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_11.png")} alt="" />
						</div>
						<div>
							<em>2017</em>
							<p>영국 CPNI 인증</p>
							<span>Video Analytics Certification</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_12.png")} alt="" />
						</div>
						<div>
							<em>2015</em>
							<p>ISO 9001</p>
							<span>품질 경영 시스템 인증서</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_13.png")} alt="" />
						</div>
						<div>
							<em>2008</em>
							<p>GS 인증 Good Software</p>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_14.png")} alt="" />
						</div>
						<div>
							<em>2002</em>
							<p>과학기술정보통신부<br/>기업부설연구소 인정서</p>
							<span>인텔리빅스 영상정보처리연구소</span>
						</div>
					</li>
				</ul>

				<ul className={`award_panel clear ${selectedMenu === 's' ? '' : 'dis_none'}`}>

					{/*230130 HJ 수상 추가 2023 */}
					<li>
                    <div className="award_img">
                        <img src={findImage("../assets/images/award/award_2023_06.png")} alt=""/>
                    </div>
                    <div>
                        <em>2023</em>
                        <p>행정안전부 장관상</p>
                        <span>대한민국안전기술대상</span>
                    </div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2023_07.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>중소벤처기업부 장관상</p>
							<span>기술혁신 중소기업 발전</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2023_08.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>과학기술정보통신부 장관상</p>
							<span>과학기술 진흥 국가발전 기여</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2023_09.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>중소벤처기업부 장관상</p>
							<span>디지털 경영대상</span>
						</div>
					</li>

					{/*230130 HJ 수상 추가 2022 */}
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_10.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>전자신문 사장상</p>
							<span>SW제품 품질대상 우수</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_11.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>과학기술정보통신부 장관상</p>
							<span>정보보호 산업발전 기여</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_20.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>대통령상</p>
							<span>SW 발전 공로</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_21.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>공공부문발주자협의회<br/>정부정보화협의회</p>
							<span>상용 SW 부문 지능형 영상분석 솔루션</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_22.png")} alt="" />
						</div>
						<div>
							<em>2020</em>
							<p>중소벤처기업부 장관상</p>
							<span>벤처기업 경영 및 생산성 향상을 통한 국가 산업 발전 공로</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_23.png")} alt="" />
						</div>
						<div>
							<em>2020</em>
							<p>산업통상자원부 장관상</p>
							<span>생산성 향상 활동을 통한 국가 경제 발전 기여</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_24.png")} alt="" />
						</div>
						<div>
							<em>2019</em>
							<p>과학기술정보통신부 장관상</p>
							<span>ICT 융합을 통한 국가산업 발전 기여</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_25.png")} alt="" />
						</div>
						<div>
							<em>2017</em>
							<p>중소벤처기업부 장관상</p>
							<span>중소기업 육성을 통한 국가경제 발전 기여</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_26.png")} alt="" />
						</div>
						<div>
							<em>2016</em>
							<p>미래창조과학부 장관상</p>
							<span>정보통신 중소벤처기업 발전에 기여</span>
						</div>
					</li>
				</ul>

				<ul className={`award_panel clear ${selectedMenu === 't' ? '' : 'dis_none'}`}>

					{/*20240221 특허 소멸 */}					
					{/* <li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_31.png")} alt="" />
						</div>
						<div>
							<em>2005</em>
							<p>특허 제10-2003-0005850</p>
							<span>글로벌 영상 모자이크 방법</span>
						</div>
					</li> */}
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_32.png")} alt="" />
						</div>
						<div>
							<em>2006</em>
							<p>특허 제10-2004-0028814</p>
							<span>실시간 파노라마 비디오 영상을<br/>이용한 감시 시스템 및 그 시스템의 제어방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_33.png")} alt="" />
						</div>
						<div>
							<em>2006</em>
							<p>특허 제10-2004-0095309</p>
							<span>자동화된 360˚ 파노라마 이미지 생성 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_34.png")} alt="" />
						</div>
						<div>
							<em>2009</em>
							<p>특허 제10-2008-0065180</p>
							<span>PTZ 카메라를 이용한 자동화된 광역 감시 시스템 및 그 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_35.png")} alt="" />
						</div>
						<div>
							<em>2009</em>
							<p>특허 제10-2008-0074461</p>
							<span>오버헤드 카메라 영상에서 사람을 감지하고 추적하는 시스템 및 그 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_36.png")} alt="" />
						</div>
						<div>
							<em>2009</em>
							<p>특허 제10-2008-0077656</p> {/*20240221 특허번호 수정*/}
							<span>비디오 기반 가상 경로 통과 물체 감지 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_37.png")} alt="" />
						</div>
						<div>
							<em>2009</em>
							<p>특허 제10-2008-0079618</p>
							<span>고해상도 카메라를 이용한 지능형 영상 감시 시스템 및 그 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_38.png")} alt="" />
						</div>
						<div>
							<em>2009</em>
							<p>특허 제10-2008-0085651</p>
							<span>지능형 영상 감시 시스템에서 두 카메라 간의 연동 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_39.png")} alt="" />
						</div>
						<div>
							<em>2009</em>
							<p>특허 제10-2009-0021467</p>
							<span>주야간 영상 합성에 기반한 야간 영상 감시 시스템 및 그 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_40.png")} alt="" />
						</div>
						<div>
							<em>2009</em>
							<p>특허 제10-2010-0113891호</p>
							<span>영상 촬영 장치의 객체 추적 장치 및 방법과 보안 관리 시스템의 객체 추적 장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_41.png")} alt="" />
						</div>
						<div>
							<em>2018</em>
							<p>특허 제10-2011-0145979</p>
							<span>HD CCTV 영상 감시 시스템</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_42.png")} alt="" />
						</div>
						<div>
							<em>2014</em>
							<p>특허 제 10-2013-0029035</p>
							<span>사람 객체 추적을 통한 자동 카메라 보정 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_43.png")} alt="" />
						</div>
						<div>
							<em>2015</em>
							<p>특허 제10-2014-0018220</p>
							<span>보안데이터 수집장치 및 그 수집방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_44.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>특허 제10-2014-0057653</p>
							<span>주차 시 차량용 블랙박스의 이벤트 감지 시스템 및 그 이벤트 감지방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_45.png")} alt="" />
						</div>
						<div>
							<em>2017</em>
							<p>특허 제10-2015-0015893</p>
							<span>이벤트 감지 방법 및 이를 실행하는 장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_46.png")} alt="" />
						</div>
						<div>
							<em>2016</em>
							<p>특허 제10-2015-7014026</p>
							<span>객체 이동 시간 기반 관심 영역  이벤트 발생 감지 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_47.png")} alt="" />
						</div>
						<div>
							<em>2018</em>
							<p>특허 제10-2016-0179630</p>
							<span>카메라 제어장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_48.png")} alt="" />
						</div>
						<div>
							<em>2019</em>
							<p>특허 제10-2017-0008942</p>
							<span>카메라와 연계해서 증강현실 프리미티브와 이벤트를 연동 시키는 시스템 및 그 시스템의 구동방법</span>
						</div>
					</li>

					{/* 20240221 삭제 */}
					
					{/* <li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_49.png")} alt="" />
						</div>
						<div>
							<em>2019</em>
							<p>특허 제10-2018-0065298</p>
							<span>궤적서비스제공장치 및 그 장치의 데이터 구축방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_50.png")} alt="" />
						</div>
						<div>
							<em>2019</em>
							<p>특허 제10-2018-0065401</p>
							<span>궤적서비스제공장치 및 그 장치의 구동방법</span>
						</div>
					</li> */}

					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_51.png")} alt="" />
						</div>
						<div>
							<em>2019</em>
							<p>특허 제10-2018-0072476</p>
							<span>안전관제시스템 및 그 시스템의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_52.png")} alt="" />
						</div>
						<div>
							<em>2019</em>
							<p>특허 제10-2018-0092893</p>
							<span>이벤트 감지장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_53.png")} alt="" />
						</div>
						<div>
							<em>2019</em>
							<p>특허 제10-2018-0125707</p>
							<span>스마트 영상처리장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_54.png")} alt="" />
						</div>
						<div>
							<em>2019</em>
							<p>특허 제 10-2018-0139043</p>
							<span>객체 이미지 인식 DCNN 기반  CCTV 영상분석장치</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_55.png")} alt="" />
						</div>
						<div>
							<em>2020</em>
							<p>특허 제10-2018-0159267</p>
							<span>복수의 고해상도 카메라들을 이용한 파노라마 영상 감시 시스템 및 그 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_56.png")} alt="" />
						</div>
						<div>
							<em>2020</em>
							<p>특허 제10-2018-01599933</p>
							<span>수위 감지 장치 및 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_57.png")} alt="" />
						</div>
						<div>
							<em>2020</em>
							<p>특허 제10-2019-0151235</p>
							<span>사회약자 인식장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					{/*20240221 특허추가*/}
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_new05.png")} alt=""/>
						</div>
						<div>
							<em>2020</em>
							<p>특허 제10-2019-0152026</p>
							<span>스마트관제서비스장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_58.png")} alt="" />
						</div>
						<div>
							<em>2020</em>
							<p>특허 제10-2019-0153878</p>
							<span>효율적 관제를 위한 스마트관제 서비스장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_59.png")} alt="" />
						</div>
						<div>
							<em>2020</em>
							<p>특허 제10-2019-0160358</p>
							<span>다중 ROI 및 객체 검출 DCNN 기반의 CCTV 영상분석장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_60.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>특허 제10-2020-0070786</p>
							<span>실시간 CCTV 영상분석장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_61.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>특허 제10-2020-0152171</p>
							<span>영상분석장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_62.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>특허 제10-2020-0155167</p>
							<span>객체의 GPS 좌표정보를 이용한 검색 서비스를 제공하는 영상분석장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_63.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>특허 제10-2020-0167428</p>
							<span>횡단보도 환경을 고려한 영상분석장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_64.png")} alt="" />
						</div>
						<div>
							<em>2021</em>
							<p>특허 제10-2020-0182052</p>
							<span>영상기반 추적 객체의 GPS 좌표추출장치 및 그 장치의 구동방법</span>
						</div>
					</li>

					{/*230130 HJ 2022 특허 컨텐츠 추가 */}
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_12.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>특허 제10-2021-0090709</p>
							<span>객체추적장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_13.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>특허 제10-2021-0188368</p>
							<span>무빙ROI방식이 적용된 딥러닝 기반 객체 검출장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_14.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>특허 제10-2021-0178703</p>
							<span>복수의 카메라에 기반한 맵 기반 객체 추적 및 이벤트 검출장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_2022_15.png")} alt=""/>
						</div>
						<div>
							<em>2022</em>
							<p>특허 제10-2020-0017666</p>
							<span>영상처리장치 및 그 장치의 구동방법</span>
						</div>
					</li>

					{/*20240221 HJ 2023 특허 컨텐츠 추가 */}
					<li>
                    <div className="award_img">
                        <img src={findImage("../assets/images/award/award_65.png")} alt=""/>
                    </div>
                    <div>
                        <em>2023</em>
                        <p>특허 제10-2022-0150443</p>
                        <span>TOF 카메라와 디지털 트윈기술을 이용한 독거노인 행동관찰 및 이상행위 모니터링 시스템 및 방법</span>
                    </div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_66.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>특허 제10-2022-0153843</p>
							<span>영상분석 기반의 차종정보 자동추출장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_67.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>특허 제10-2022-0155446</p>
							<span>객체 검출 추적에 기반한 비디오 인코딩 및 전송방법, 그리고 그 시스템</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_68.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>특허 제10-2022-0173416</p>
							<span>과소 및 과밀 환경을 지원하는 군중위험관리시스템 및 방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_69.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>특허 제10-2023-0065190</p>
							<span>영상감시장치 및 그 장치의 구동방법, 영상감시시스템 및 컴퓨터 판독가능 기록매체</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_70.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>특허 제10-2023-0067284</p>
							<span>모돈의 행동인식 기반의 분만 판단을 위한 장치 및 그 장치의 구동방법</span>
						</div>
					</li>
					<li>
						<div className="award_img">
							<img src={findImage("../assets/images/award/award_71.png")} alt=""/>
						</div>
						<div>
							<em>2023</em>
							<p>특허 제10-2023-0101165</p>
							<span>ATM 이상행동감지 장치 및 그 장치의 구동방법</span>
						</div>
					</li>

				</ul>

			</div>
		</>
	)
}

export default Award
