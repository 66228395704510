import React, {useState, useEffect, useRef} from 'react';
import { Link, useLocation } from 'react-router-dom'
import NoticeApi from '../../../Api/NoticeApi'
import { useDispatch } from 'react-redux'
import { notOnlyUseKR, onlyUseKR } from '../../../Reducer/useOnlyKR'

const Detail = ({match}) => {

	const [data, setData] = useState({})

	const dispatch = useDispatch()

	useEffect(()=> {
		dispatch(onlyUseKR(true))

		return () => {
			dispatch(notOnlyUseKR(false))
		}

	},[])

	useEffect(() => {
		NoticeApi.mainDetailNotice(match.params.no)
			.then(res => {
				setData(res.data.data)
			})
	},[])


	const iframeRef = useRef(null);

	const handleOnLoad = () => {
	  if (iframeRef.current) {
		const iframeDocument = iframeRef.current.contentDocument;
		const iframeContentHeight = iframeDocument.documentElement.scrollHeight + 10;
		iframeRef.current.style.height = `${iframeContentHeight}px`;
	  }
	};

	return (
		<main className="sub_page kor">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>인텔리빅스</li>
					<li>고객문의</li>
					<li>공지사항</li>
					<li>공지사항 상세</li>
				</ul>
				<h2 className="sub_title">공지사항</h2>
				<p className="title_txt">{data.title}</p>
				<span className="sub_date">{data.createDate}</span>

				<div className="detail_notice" style={{overflow:"hidden"}}>  
					<iframe title ="공지사항 상세" ref = {iframeRef} onLoad={handleOnLoad} srcDoc={data.content} style={{ width:'100%',border:'none'}}></iframe>
				</div>
				<div className="btn_center martop_40">
					<Link to={'/main/Notice'} className="skyblue_btn w_130">목록으로</Link>
				</div>
			</div>

			<div className="sub_imgBox img1">
				<div className="main_middle">
					<em className="img_tit">Vision AI 전문 기업 인텔리빅스</em>
					<p className="img_txt">앞선 경쟁력, 최고의 가치를 전달하는 Vision AI 선도기업 인텔리빅스</p>
				</div>
			</div>

		</main>
	)
}

export default Detail
