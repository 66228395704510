import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import MailApi from '../../../Api/MailApi'
import Alert from '../../../Modal/Component/Alert'

const Support = () => {

	const { t, i18n } = useTranslation()

	const contentsRef = useRef()
	const nameRef = useRef()
	const managerRef = useRef()
	const eMailRef = useRef()
	const telRef = useRef()

	const checkRef = useRef()

	const emailRegExp = new RegExp(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i)

	const postMail = () => {

		if (validCheck()) {
		// 	let data = {}
		//
		// 	data = {
		// 		eMail: eMailRef.current.value,
		// 		name: nameRef.current.value,
		// 		manager: managerRef.current.value ? managerRef.current.value : '',
		// 		tel: telRef.current.value,
		// 		contents: contentsRef.current.value
		// 	}
		//
			let form = new FormData()

			form.append('eMail', eMailRef.current.value)
			form.append('name', nameRef.current.value)
			form.append('manager', nameRef.current.value ? managerRef.current.value : '')
			form.append('tel', telRef.current.value)
			form.append('contents', contentsRef.current.value)



			MailApi.postMail(form)
				.then(res => {
					alert('문의사항을 전송했습니다.')
					window.location.reload()
				})
				.catch(err => {
					alert('문의사항 전송이 실패했습니다. 다시시도해주세요.')
				})

		}
	}

	const validCheck = () => {
		if (checkRef.current.checked) {
			if (eMailRef.current.value && nameRef.current.value && telRef.current.value && contentsRef.current.value) {
				if(emailRegExp.test(eMailRef.current.value)) {
					return true
				} else {
					alert('이메일 형식을 확인해주세요.')
				}
			} else {
				alert('필수 입력 사항을 확인해주세요.')
			}
		} else {
			alert('개인정보 수집 및 이용 동의를 체크해주세요.')
		}
		return false
	}

	return i18n.languages[0] === 'ko' ? (
		<main className="sub_page kor">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>고객문의</li>
				</ul>
				<h2 className="sub_title">고객문의</h2>
				<p className="title_txt">인텔리빅스는 제품 구매, 기업 제휴, 투자 문의 등 고객님이 주시는 어떤 문의든지 환영합니다.</p>

				<div className="img_question"/>

				<div className="question_box">
					<strong className="question_tit">문의사항 등록</strong>
					<div className="table_type2">
						<div className="tbody_tr">
							<span className="w_200 red_dot">문의 내용</span>
							<span className="w_minus200">
								<textarea rows="12" className="w_full" placeholder="사용 목적, 사용기간, 사용자 수 등을 함께 작성해주시면 빠른 회신에 도움이 됩니다."
								          ref={contentsRef}/>
							</span>
						</div>
					</div>
				</div>

				<div className="question_box">
					<strong className="question_tit">문의 고객 정보</strong>
					<div className="table_type2">
						<div className="tbody_tr">
							<span className="w_200 red_dot">이름(기업명)</span>
							<span className="w_minus200">
								<input type="text" className="w_full" placeholder="고객님의 성함 혹은 기업명을 입력해주세요." ref={nameRef}/>
							</span>
						</div>
						<div className="tbody_tr">
							<span className="w_200">담당자명</span>
							<span className="w_minus200">
								<input type="text" className="w_full" placeholder="기업 고객인 경우 담당자명을 입력해주세요." ref={managerRef}/>
							</span>
						</div>
						<div className="tbody_tr">
							<span className="w_200 red_dot">E-Mail</span>
							<span className="w_minus200">
								<input type="email" className="w_full" placeholder="답변을 회신 받을 메일 주소를 정확히 입력해주세요." ref={eMailRef}/>
							</span>
						</div>
						<div className="tbody_tr">
							<span className="w_200 red_dot">전화번호</span>
							<span className="w_minus200">
								<input type="tel" className="w_full" placeholder="전화번호를 입력해주세요." ref={telRef}/>
							</span>
						</div>
					</div>
				</div>

				<div className="tbody_tr marbtm_26">
					<strong className="question_tit">[필수] 개인정보 수집 및 이용 동의</strong>
					<div className="table_pre">
						<pre>{`이용자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 아래 목적 이외의 용도로는 사용되지 않습니다.
1. 목적: 문의 등록에 따른 연락처 정보 확인
2. 항목: 이름(기업명), 담당자명, E-Mail, 전화번호
3. 보유기간: 문의 답변 서비스를 위해 검토 및 답변 완료 후 해당 정보를 지체없이 파기합니다.

위 정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부 시에는 문의 접수가 제한될 수 있습니다.
더 자세한 내용에 대해서는 인텔리빅스 개인정보처리방침을 참고하시기 바랍니다.`}</pre>
					</div>
				</div>
				<input type="checkbox" className="ch_ra hide" id="chk_01" ref={checkRef}/>
				<label htmlFor="chk_01" className="label check" >개인정보 수집 및 이용에 동의합니다.</label>

				<div className="btn_center martop_16">
					<button className="skyblue_btn w_130 m_wfull" onClick={() => postMail()}>제출하기</button>
				</div>
			</div>

			<div className="sub_imgBox img3">
				<div className="main_middle">
					<em className="img_tit">Vision AI 전문 기업 인텔리빅스</em>
					<p className="img_txt">앞선 경쟁력, 최고의 가치를 전달하는 Vision AI 선도기업 인텔리빅스</p>
				</div>
			</div>

		</main>) : (
		<main className="sub_page eng">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>Inquiry</li>
				</ul>
				<h2 className="sub_title marbtm_26">Inquiry</h2>

				<div className="question_box">
					<strong className="question_tit">Inquiry</strong>
					<div className="table_type2">
						<div className="tbody_tr">
							<span className="w_200 red_dot">Description</span>
							<span className="w_minus200">
								<textarea rows="12" className="w_full" ref={contentsRef}/>
							</span>
						</div>
					</div>
				</div>

				<div className="question_box">
					<strong className="question_tit">Information</strong>
					<div className="table_type2">
						<div className="tbody_tr">
							<span className="w_200 red_dot">Company</span>
							<span className="w_minus200">
								<input type="text" className="w_full" ref={nameRef}/>
							</span>
						</div>
						<div className="tbody_tr">
							<span className="w_200">Name</span>
							<span className="w_minus200">
								<input type="text" className="w_full" ref={managerRef}/>
							</span>
						</div>
						<div className="tbody_tr">
							<span className="w_200 red_dot">E-Mail</span>
							<span className="w_minus200">
								<input type="email" className="w_full" ref={eMailRef}/>
							</span>
						</div>
						<div className="tbody_tr">
							<span className="w_200 red_dot">Phone</span>
							<span className="w_minus200">
								<input type="tel" className="w_full" ref={telRef}/>
							</span>
						</div>
					</div>
				</div>

				<div className="tbody_tr marbtm_26">
					<strong className="question_tit">Privacy Policy</strong>
					<div className="table_pre">
						<pre>{`이용자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 아래 목적 이외의 용도로는 사용되지 않습니다.
1. 목적: 문의 등록에 따른 연락처 정보 확인
2. 항목: 이름(기업명), 담당자명, E-Mail, 전화번호
3. 보유기간: 문의 답변 서비스를 위해 검토 및 답변 완료 후 해당 정보를 지체없이 파기합니다.

위 정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부 시에는 문의 접수가 제한될 수 있습니다.
더 자세한 내용에 대해서는 인텔리빅스 개인정보처리방침을 참고하시기 바랍니다.`}</pre>
					</div>
				</div>
				<input type="checkbox" className="ch_ra hide" id="chk_01" ref={checkRef}/>
				<label htmlFor="chk_01" className="label check">By proceeding, I agree to intellviix terms and privacy
					policy</label>

				<div className="btn_center martop_16">
					<button className="skyblue_btn w_130 m_wfull"  onClick={() => postMail()}>Submit</button>
				</div>
			</div>

			<div className="sub_imgBox img3">
				<div className="main_middle">
					<em className="img_tit">Vision AI company IntelliVIX</em>
					<p className="img_txt">Specialized in Vision AI Technology</p>
				</div>
			</div>

		</main>
	)
}

export default Support
