import React, { useEffect, useState } from 'react'
import useDataSearch from '../../../../Hooks/useDataSearch'

const MAS = (props) => {

	const [selectedState, setSelectedState] = useState('main')

	const SELECT1 = [
		{ label: '물품식별번호', value: 'ino' },
		{ label: '모델명', value: 'model_name' },
		{ label: '기자재', value: 'equipment' },
		{ label: '단가', value: 'price' }
	]

	const SELECT2 = [
		{ label: '물품식별번호', value: 'ino' },
		{ label: '품명', value: 'product_name' },
		{ label: '단가', value: 'price' }
	]

	// 	<option>물품식별번호</option>
	// <option>모델명</option>
	// <option>기자재</option>
	// <option>단가</option>


	const [main, setMain] = useState([])
	const [sub, setSub] = useState([])

	const data1 = {
		select : SELECT1,
		data: props.data1 && props.data1,
		setState: setMain
	}

	const data2 = {
		select : SELECT2,
		data: props.data2 && props.data2,
		setState: setSub
	}

	const [component1, component2] = useDataSearch(data1,data2, selectedState)


	useEffect(() => {
		props.data1 && setMain(props.data1)
	}, [props.data1, selectedState])

	useEffect(() => {
		props.data2 && setSub(props.data2)
	}, [props.data2, selectedState])

	return (
		<div id="business_panel2" className="business_panel">
			<div className="position_nav">
				<ul className="sub_nav">
					<li>조달우수</li>
					<li>MAS</li>
				</ul>
				<h2 className="sub_title">다수공급자계약(MAS)</h2>
			</div>

			<div className="small_tab_btn martop_70">
				<button data-target="article_panel1" className={selectedState === 'main' ? 'active' : ''}
				        onClick={() => setSelectedState('main')}>주품목
				</button>
				<button data-target="article_panel2" className={selectedState === 'sub' ? 'active' : ''}
				        onClick={() => setSelectedState('sub')}>추가선택품목
				</button>
			</div>

			{/* MAS - 주품목 */}
			<div id="article_panel3" className={selectedState !== 'main' ? 'article_panel dis_none' : ''}>
				<div className="clear">
					{component1}
				</div>

				<div className="table_type1 article martop_40">
					<div className="thead">
						<span className="w_200 M25per">물품식별번호</span>
						<span className="w_220 M25per">모델명</span>
						<span className="w_minus820 M_disnone">규격</span>
						<span className="w_240 M25per">기자재</span>
						<span className="w_160 M25per">단가</span>
					</div>
					<div className="tbody">
						{main.length !== 0 && main.map(item => (
							<div className="tbody_tr" key={item.no}>
							<span className="w_200 M25per">{item.ino}</span>
							<span className="w_220 M25per">{item.model_name}</span>
							<span className="w_minus820 M_disnone">{item.product_standard}</span>
							<span className="w_240 M25per">{item.equipment}</span>
							<span className="w_160 M25per txt_right">{item.price}</span>
						</div>))}
					</div>
				</div>
			</div>

			{/* MAS - 추가선택품목 */}
			<div id="article_panel4" className={selectedState !== 'sub' ? 'article_panel dis_none' : ''}>
				<div className="clear">
					{component2}
				</div>

				<div className="table_type1 article martop_40">
					<div className="thead">
						<span className="w_200 M30per">물품식별번호</span>
						<span className="w_220 M40per">품명</span>
						<span className="w_minus620 M_disnone">상세규격</span>
						<span className="w_200 M30per">단가</span>
					</div>

					<div className="tbody">
						{sub.length !== 0 && sub.map(item => (
							<div className="tbody_tr" key={item.no}>
								<span className="w_200 M30per">{item.ino}</span>
								<span className="w_220 M40per">{item.product_name}</span>
								<span className="w_minus620 txt_left M_disnone">{item.product_standard}</span>
								<span className="w_200 M30per txt_right">{item.price}</span>
							</div>
						))}
					</div>
				</div>
			</div>

		</div>
	)
}

export default MAS
