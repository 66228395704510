import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
 import 'AdminAssets/assets/naver_editor/js/service/HuskyEZCreator'
import NoticeApi from '../../../../Api/NoticeApi'
import Notice from './index'
import async from 'async'
//import sSkinUrl from 'assets/naver_editor'

let oEditors = []

const PostNotice = (props) => {
	const history = useHistory()
	const titleRef = useRef()
	const [content, setContent] = useState('')
	const [title, setTitle] = useState('')
	const [disabled, setDisabled] = useState(true)

	useEffect(() => {
		//const sSkinURI = require('assets/naver_editor/SmartEditor2Skin.html')
		initEditor()
	}, [props.writeMode])

	const initEditor = useCallback(async () => {
		window.nhn.husky.EZCreator.createInIFrame({
			oAppRef: oEditors,
			elPlaceHolder: 'txtContent',
			sSkinURI: '../../../naver_editor/SmartEditor2Skin.html',
			fCreator: 'createSEditor2',
			htParams: { // 툴바 사용 여부 (true/false)
				bUseToolbar: true, // 입력창 크기 조절바 사용 여부 (true/false)
				bUseVerticalResizer: false, // 모드 탭(Editor|HTML|TEXT) 사용 여부 (true/false)
				bUseModeChanger: true, // 전송버튼 클릭이벤트
				bSkipXssFilter : true
			}
		})
	}, [])

	let contentChk = setTimeout(function () {
		var ctntarea = document.querySelector('iframe').contentWindow.document.querySelector('iframe').contentWindow.document.querySelector('.se2_inputarea')
		ctntarea.addEventListener('keyup', function (e) {
			var text = this.innerHTML
			setContent(text)
		})
		},1000)

	useEffect(() => {

		(content && title) ? setDisabled(false) : setDisabled(true)

	}, [content, title])




			useEffect(() => {
				if (props.writeMode === 3 && props.updateNumber !== -1) {
					NoticeApi.detailNotice(props.updateNumber)
						.then((res) => {
							titleRef.current.value = res.data.data.title
							document.getElementById('txtContent').value = res.data.data.content
						})
				}
			}, [props.writeMode])

			const write = () => {
				oEditors.length && oEditors.getById['txtContent'].exec('UPDATE_CONTENTS_FIELD', [])
				let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
				let data = {
					title: titleRef.current.value,
					content: document.getElementById('txtContent').value,
					createAdmin: `${userInfo.name}(${userInfo.id})`
				}
				NoticeApi.postNotice(data)
					.then(res => {
						changeMode()
					})
			}

			const update = () => {
				oEditors.length && oEditors.getById['txtContent'].exec('UPDATE_CONTENTS_FIELD', [])
				let data = {
					no: props.updateNumber,
					title: titleRef.current.value,
					content: document.getElementById('txtContent').value,
				}
				NoticeApi.modifyNotice(data)
					.then(res => {
						changeMode()
					})
			}

			 const changeMode = async () => {
				 await clearTimeout(contentChk)
				 await props.setWriteMode(0)
			 }

			return (
				<>
					<div className="con_wrap">
						<div className="title_box clear">
							<h2 className="title left">공지사항 등록</h2>

							<ul className="right">
								<li>HOME</li>
								<li>공지사항 관리</li>
								<li>공지사항 등록</li>
							</ul>
						</div>

						<div className="table_wrap">
							<table className="type2 txt_left1 txt_content">
								<colgroup>
									<col width="259px"/>
									<col width="*"/>
								</colgroup>
								<tbody>
								<tr>
									<th>제목</th>
									<td>
										<input type="text" className="w_full" placeholder="제목을 입력해주세요." ref={titleRef} onChange={()=>setTitle(titleRef.current.value)}/>
									</td>
								</tr>
								<tr>
									<th>내용</th>
									<td>
										<textarea name="txtContent" id="txtContent" style={{ width: '100%', height: '672px' }}/>
									</td>
								</tr>
								</tbody>
							</table>

							<div className="btn_center martop_20">
								<button type="button" className={`blue_btn w_100 ${disabled ? 'disabled' : ''}`}
								        onClick={() => props.writeMode === 1 ? write() : update()} disabled={disabled}>등록
								</button>
								<button type="button" className="white_btn w_100" onClick={() => changeMode()}>취소</button>
							</div>
						</div>
					</div>
				</>
			)
		}

		export default PostNotice
