import React, {useState, useEffect} from 'react'
import ProcurementTab from './tab/Procurement'
import MAS from './tab/MAS'
import SW from './tab/SW'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import Footer from '../Main/Footer'
import { notOnlyUseKR, onlyUseKR } from '../../../Reducer/useOnlyKR'
import ProApi from '../../../Api/ProApi'

const Procurement = () => {

	const [selectedState, setSelectedState] = useState('Procurement')
	const [data, setData] = useState([])
	const dispatch = useDispatch()

	useEffect(()=> {
		dispatch(onlyUseKR(true))
		ProApi.getList()
			.then(res => {
				setData(res.data.data)
			})

		return () => {
			dispatch(notOnlyUseKR(false))
		}
	},[])

	return (
		<main className="sub_page kor">
			<div className="sub_con">
				<div className="clear position_tab">
					<ul className="tab_btn per33 right">
						<li className={selectedState === 'Procurement' ? 'active' : ''}>
							<button data-target="business_panel1" onClick={()=> setSelectedState('Procurement')}>우수제품</button>
						</li>
						<li className={selectedState === 'MAS' ? 'active' : ''}>
							<button data-target="business_panel2" onClick={()=> setSelectedState('MAS')}>MAS</button>
						</li>
						<li className={selectedState === 'SW' ? 'active' : ''}>
							<button data-target="business_panel3" onClick={()=> setSelectedState('SW')}>SW</button>
						</li>
					</ul>
				</div>
				{{
					'Procurement': <ProcurementTab data1={data.procurement_main} data2={data.procurement_sub} />,
					'MAS': <MAS data1={data.MAS_main} data2={data.MAS_sub} />,
					'SW': <SW data1={data.SW_main} data2={data.SW_sub} />
				}[selectedState]}
				{/* MAS */}
				{/* SW */}
			</div>
		</main>
	)
}

export default Procurement
