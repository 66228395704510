import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const ITS = (props) => {
	const { t, i18n } = useTranslation()

	const lang = i18n.language === 'ko'

	const findImage = (path) => {
		let imgArray = path.split('images/')
		let img = imgArray[imgArray.length - 1]

		return require(`MainAssets/assets/images/${img}`)
	}

	const ref220r = useRef()
	const ref220p = useRef()

	useEffect(() => {
		if (props.hash && ref220r.current && ref220p.current) {
			let location = props.hash === '#220r' ? ref220r.current.offsetTop : ref220p.current.offsetTop
			window.scrollTo({ top: location })
		}
	}, [])

	return lang ? (
		<div id="business_panel3" className="business_panel">
			<div className="business_img img3">
				<div className="business_txt">
					<strong>교통</strong>
					<p>
						협력형 자율주행 인프라 구축을 위해 Vision AI 기술을 도로에 접목해 실시간 교통 정보를 수집하고 차량과
						보행자를 보호할 수 있는 다양한 솔루션을 제공합니다.
					</p>
				</div>
			</div>
			<div className="sub_con">
				<strong className="business_tit">왜 필요할까요?</strong>
				<p className="business_use">
					미래형 교통 시스템 구축을 위해서는 도로 상황에 대한 다양한 데이터가 필요합니다. 또한 도로 교통을 통제하고 우회
					도로 안내 등 사용자를 위한 다양한 정보를 제공하기 위해서는 도로 상황에 대한 실시간 파악이 필요합니다. 이를
					위해 <span className="highlighter">도로에 설치된 CCTV인프라를 Vision AI로 분석하여 다양한 정보를 제공</span>
					합니다.
				</p>
				<div className="bsn_martop_80">
					<strong className="business_tit">이렇게 활용할 수 있습니다.</strong>
					<ul className="business_dot">
						<li>신규 시설물 구축 지양, 기존 시설물(CCTV)의 활용 방안 모색</li>
						<li>교통 모니터링 CCTV의 능동적 상황관리 방안 모색</li>
						<li>실시간 정보분석에 근거한 능동적 상황관리로 시민안전 및 편의증진</li>
						<li>스마트 감응 시스템, 스마트 교차로 등 교통 인프라 고도화</li>
					</ul>
				</div>
				<div className="bsn_martop_80">
					<strong className="business_tit bsn_martop_80">구축 사이트</strong>
					<div className="clear">
						{/*20240221 텍스트 내용 추가 및 순서 변경 */}
						<ul class="business_dot type2 mar_0">
							<li>인천 청라지하차도 자동사고감지(유고감지) 시스템 구축(2023년)</li>
							<li>인천 영종지하차도 자동사고감지(유고감지) 시스템 구축(2023년)</li>
							<li>울산시 ITS 보강 및 확장사업(2022년)</li>
							<li>김포시 스마트교차로 영상분석 시스템 구축(2022년)</li>
							<li>평택시 ITS 지능형교통체계 구축사업(2022년)</li>
							<li>대구도시철도 1호선 안심하양 복선전철 화상전송설비 구매설치 사업(2022년)</li>
							<li>창원시 지능형 교통체계(ITS) 구축사업(1차)-센터시스템(2022년)</li>
							<li>대전시 ITS 터널 사고감지시스템 구축(2022년)</li>
							<li>순천시 스마트교차로 구축사업(2022년)</li>
							<li>시흥시 ITS 스마트교차로 구축 사업(2022년)</li>
							<li>전주시 지능형교통체계(ITS)구축사업 관급자재(소프트웨어)구매(2022년)</li>
							<li>울산시 C-ITS 3차 영상분석 서버 공급(2022년)</li>
							<li>울산시 산업로(신답교~경주시계) 확장 ITS시설공사 광급자재(지능형영상분석외1)(2022년)</li>
							<li>부산시 횡단보도 보행안전 시스템 구매 건(2022년)</li>
							<li>인천ITS 영상식 돌발감시 시스템 구축(2022년)</li>
							<li>울산송정지구 영상식 VDS 물품 납품(2021년) </li>
						</ul>
						<ul class="business_dot type2 mar_0">
							<li>서울시 집회돌발 감시시스템 확대 구축(2021년)</li>
							<li>부산시 BRT구간 보행안전 시스템 확대 구축(2021년) </li>
							<li>서울시설공단 홍지문터널 유고감지 시스템 구축(2021년)</li>
							<li>한국도로공사 영상보안 마스킹 솔루션 구축(2021년)</li>
							<li>부천시, 시흥시 지능형교통체계(ITS) 구축(2021년)</li>
							<li>세종시 ATMS 시스템 구축(2020년)</li>
							<li>부산시 BRT구간 보행안전 시스템 구축(2020년)</li>
							<li>구미시, 울산시 지능형교통체계(ITS) 구축(2020년)</li>
							<li>울산시 스마트교차로 시스템 구축(2020년)</li>
							<li>서울시설공단, 울산대교 지능형 돌발상황 관리시스템 구축(2020년)</li>
							<li>인천스마트시티 고속영상마스킹 솔루션 고도화 사업(2020년)</li>
							<li>돌발터널 유고 영상분석 납품(2019~21년)</li>
							<li>안산시 지능형교통체계(ITS) 구축 (2019년)</li>
							<li>서울시 C-ITS 사업 돌발 / 터널유고 영상분석 납품(2019)</li>
							<li>한국도로공사 200여개(3,500채널) 터널 유고 유지보수(2017년~)</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="product_panel blue_back" ref={ref220r}>
				<div className="sub_con">
					<div className="product_box clear">
						<strong className="business_tit">주요 제품</strong>
						<div className="product_txt w_half">
							<strong>220R (AI 교통분석)</strong>
							<p>
								도로 및 터널 내 안전사고와 돌발 상황에 대한 신속한 대처를 할 수 있도록 지원합니다. 도로 유고 이벤트를
								감지하며 딥러닝 기반 차량 및 보행자 검출 및 분류를 동시에 지원해 분석된 영상 데이터를 활용하여 도로의
								교통정보를 파악할 수 있습니다.
							</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage("../assets/images/product_img04.png")} alt="" />
						</div>
					</div>

					<strong className="business_tit">주요 특징</strong>
					<ul className="business_dot">
						<li>도로/터널 내 돌발 상황 감지: 차량 역주행/정지, 배회자, 낙하물, 화재 등</li>
						<li>도로 내 차량의 각종 통계 정보 획득: 차선 별 교통량, 차량 대기열 길이/밀도, 차량 이동 속도 등</li>
						<li>차량/보행자의 GPS 위치 정보 획득</li>
						<li>
							횡단보도 내 보행자 인식: 신호등과 연계한 무단횡단자 검출, 사회적 약자 검출, 느린 보행자 검출, 보행자 계수
							등
						</li>
						<li>다중 카메라와 맵에 기반한 차량/보행자 객체 연속 추적: 맵 상에서 각종 통계 정보 획득</li>
					</ul>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit martop_40">대표 이벤트</strong>
						<div className="clear event_220R">
							<div>
								<strong>차량검지(VDS)</strong>
								<img src={findImage("../assets/images/img_event01.png")} alt="" />
								<p>통행량, 속도, 점유율(대기열)</p>
							</div>
							<div>
								<strong>도로유고</strong>
								<img src={findImage("../assets/images/img_event02.png")} alt="" />
								<p>차량정차, 역주행차량, 보행자탐지, 낙하물, 화재</p>
							</div>
							<div>
								<strong>차종 분류</strong>
								<img src={findImage("../assets/images/img_event03.png")} alt="" />
								<p>세단, SUV, 트럭, 버스, 이륜차, 벤</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="product_panel blue_back" ref={ref220p}>
				<div className="sub_con">
					<div className="product_box clear">
						<div className="product_txt w_half">
							<strong>220P (AI 차량번호판인식)</strong>
							<p>
								소프트웨어 기반으로 모션 및 딥러닝 기반 지능형 영상 분석 기술과 차량 번호 인식 기술을 융합 하여 별도의
								차량 번호판 인식 루프 센서를 설치 하지 않고, 자동 PTZ 추적과 연계해 일반 PTZ 카메라를 이용해 추적되는
								관심 차량에 대한 줌인 영상을 확보 후 실시간으로 차량 번호판 인식을 수행 하는 지능형 차량 번호 인식
								소프트웨어 입니다.
							</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage("../assets/images/product_img05.png")} alt="" />
						</div>
					</div>

					<strong className="business_tit">주요 특징</strong>
					<div className="clear">
						<ul className="business_dot type2 li_marbtm20">
							<li>국/내외 차량 번호판 인식</li>
							<li>
								<span>심 차량 등록 및 자동 감지, 화면 내 리스트 표출</span>
								<p>- 썸네일 이미지 / 차량번호 이미지 / 감시 영상 내 오버레이</p>
							</li>
							<li>타 시스템으로 차량 번호 정보 전송</li>
						</ul>
						<ul className="business_dot type2 li_marbtm20">
							<li>M/S 기반 자동 PTZ 추적 연계</li>
							<li>관심 등급에 따른 실시간 팝업</li>
							<li>특수차량 번호인식 기술 확보</li>
						</ul>
					</div>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit">대표 이벤트</strong>
						<ul className="event_list">
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_15.png")} alt="" />
									<span>차량번호판인식</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div id="Ebusiness_panel3" className="Ebusiness_panel">
			<div className="business_img img3">
				<div className="business_txt">
					<strong>ITS</strong>
					<p>
						The introduction of Vision-AI-based solutions is increasing to collect vehicle and human movement
						information through CCTV in real time. It is expected to play a key role in autonomous collaboration driving
						and identifying the traffic volume of vehicles in the future.
						<br />
						IntelliVIX's Vision-AI technology is being introduced to protect pedestrians along with collecting real-time
						traffic information.
						<br />
						In addition, we are building an ITS that can drastically reduce the risk of accidents by providing accident
						risk information such as construction sections, sudden stops, falling objects, and jaywalking in real time.
					</p>
				</div>
			</div>
			<div className="sub_con">
				<strong className="business_tit">Background</strong>
				<ul className="business_dot">
					<li>
						Demand for collaborative autonomous driving and smart road construction increases based on information on
						vehicles on the road and people around the road
					</li>
					<li>Increasing the establishment of C-ITS infrastructure to introduce autonomous driving systems</li>
				</ul>
				<strong className="business_tit">Necessity</strong>
				<ul className="business_dot">
					<li>Need to understand road conditions in real time -> Traffic congestion/accident</li>
					<li>
						Necessity of understanding road conditions when constructing collaborative autonomous driving and smart
						roads
					</li>
					<li>
						Necessity of providing accurate data on places requiring statistical information such as new roads and
						traffic lights
					</li>
					<li>
						Various information can be provided, such as smooth road traffic control and bypass road guidance in case of
						an accident
					</li>
				</ul>
				<strong className="business_tit">Solution Competitiveness</strong>
				<ul className="business_dot marbtm_100">
					<li>Optimization of detection of people/vehicle over medium distance</li>
					<li>
						Secure detection performance optimized for road CCTV through detection by ROI area and application of
						multiple detectors
					</li>
					<li>Multiple cameras and map-based object tracking technology</li>
				</ul>
				<strong className="business_tit bsn_martop_80">Reference</strong>
				<div className="cpn_box">
					<img src={findImage("../assets/images/icon/business_01.png")} alt="" />
					<div className="business_box">
						<strong className="tit_01">ITS</strong>
						{/*20240221 컨텐츠 수정 */}
						<ul className="small_list">
							<li>- Cheongna Underground Road IncheonAutomatic Accident Detection System (2023)</li>
							<li>- Incheon Yeongjong Underground Road Automatic Accident Detection System (2023)</li>
							<li>- Ulsan-si ITS Reinforcement and Expansion Project (2022)</li>
							<li>- Gimpo, suncheon, siheung, Smart Intersection (traffic Analytics) (2022)</li>
							<li>- Pyeongtaek,Changwon,jeonju,incheon ITS traffic analytics (2022)</li>
							<li>- Daegu Transportation Corporation line 1 image transmission equipment installation project(2022)</li>
							<li>- Daejeon Tunnel accident video analytics system (2022)</li>
							<li>- Ulsan C-ITS Video analytics server supply (2022)</li>
							<li>- Busan Pedestrian safety system (2022)</li>
							<li>- Seoul, Ansan, Bucheon,Ulsan, Busan, Sejong traffic analytics (2020~2021)</li>
							<li>- Seoul C-ITS tunnel accident video analytics (2019~2021)</li>
							<li>
								- Korea Expressway Corporation : 200 tunnels (3,500CH) accidents monitoring and maintenance (2017~ )
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="Eproduct_panel blue_back" ref={ref220r}>
				<div className="sub_con">
					<div className="product_box clear">
						<strong className="business_tit">Product</strong>
						<div className="product_txt w_half">
							<strong>220R (ITS)</strong>
							<p>
								It supports rapid response to safety accidents and unexpected situations on roads and tunnels. It
								detects road accident events and supports the detection and classification of deep learning-based
								vehicles and pedestrians at the same time to understand traffic information on the road using analyzed
								video data.
							</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage("../assets/images/product_img04.png")} alt="" />
						</div>
					</div>
					<strong className="business_tit">Features</strong>
					<ul className="business_dot">
						<li>
							unexpected situation detection on roads and tunnels: reverse/stopping, pedestrian, falling object, fire
						</li>
						<li>Statistic information of the vehicle in the road: traffic volume of each lane,</li>
						<li>Obtaining GPS location information of a vehicle/pedestrian</li>
						<li>Pedestrian detection in a crosswalk: jaywalker detection</li>
						<li>
							Vehicle/pedestrian object continuous tracking based on multi-camera and map: obataining statistic
							information on the map
						</li>
					</ul>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit martop_40">Main Event</strong>
						<div className="clear event_220R">
							<div>
								<strong>Vehicle Detection system</strong>
								<img src={findImage("../assets/images/img_event01.png")} alt="" />
								<p>Traffic volume, speed</p>
							</div>
							<div>
								<strong>Road accident</strong>
								<img src={findImage("../assets/images/img_event02.png")} alt="" />
								<p>Car stopping, reverse, pedestrian detection, falling object, fire</p>
							</div>
							<div>
								<strong>Vehicle classification</strong>
								<img src={findImage("../assets/images/img_event03.png")} alt="" />
								<p>Sedan, SUV, Van, Truck, bus, two wheels</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="Eproduct_panel blue_back" ref={ref220p}>
				<div className="sub_con">
					<div className="product_box clear">
						<div className="product_txt w_half">
							<strong>220P(License Plate Recognition)</strong>
							<p>
								It is a intelligent plate number recognition software that combines software-based motion and deep
								learning-based smart video analytics technology with plate number recognition technology without
								installing a separate vehicle license plate recognition loop sensor.
							</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage("../assets/images/product_img05.png")} alt="" />
						</div>
					</div>

					<strong className="business_tit">Features</strong>
					<div className="clear">
						<ul className="business_dot type2 li_marbtm20">
							<li>Plate number recognition</li>
							<li>
								<span>Vehicle registration and auto detection, display the list</span>
								<p>- Thumbnail image / plate number image / overlay within surveillance video</p>
							</li>
							<li>Transmitting the plate number information to other system</li>
						</ul>
						<ul className="business_dot type2 li_marbtm20">
							<li>Auto PTZ tracking based M/S</li>
							<li>Real time pop-up by the interest level</li>
							<li>Special vehicle number recognition technology</li>
						</ul>
					</div>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit">Main Event</strong>
						<ul className="event_list">
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_15.png")} alt="" />
									<span>Plate number recognition</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ITS
