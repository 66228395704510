import React,{useState, useEffect, useRef} from 'react'
import NumberUtils from '../../../../Utils/NumberUtils'
import ProApi from '../../../../Api/ProApi'
import Confirm from '../../../../Modal/Component/Confirm'
import Alert from '../../../../Modal/Component/Alert'
import NoticeApi from '../../../../Api/NoticeApi'

const PostProcurement = (props) => {

	const [division, setDivision] = useState('PRO')
	const divisionRef = useRef()
	const [subject, setSubject] = useState('main')
	const subjectRef = useRef()

	const [ino, setIno] = useState('')
	const inoRef = useRef()
	const [product_name, setProductName] = useState('')
	const productNameRef = useRef()
	const [equipment, setEquipment] = useState('')
	const equipmentRef = useRef()
	const [price, setPrice] = useState('')
	const priceRef = useRef()
	const [product_standard, setProductStandard] = useState('')
	const productStandardRef = useRef()
	const [model_name, setModelName] = useState('')
	const modelNameRef = useRef()

	const [btn_disabled, setBtnDisabled] = useState(true)


	const [subjectArr, setSubjectArr] = useState([{value:'main', name:'주품목'},
		{value:'sub', name:'추가선택품목'}])

	const PRO_OPTION = [
	{value:'PRO', name:'우수제품'},
		{value:'MAS', name:'MAS'},
		{value:'SW', name:'SW'},
	]

	useEffect(()=> {
		division === 'SW' ? setSubjectArr([{value:'main', name: '응용과학용 소프트웨어'},
			{value:'sub', name: '통신 소프트웨어'}
		]) : setSubjectArr([{value:'main', name:'주품목'},
			{value:'sub', name:'추가선택품목'}])
	},[division])

	const onChangeHandler = (ref, setState) => {
		setState(ref.current.value)
	}

	useEffect(()=> {


		if(productNameRef.current.disabled) {
			productNameRef.current.value = ''
			setProductName('')
		}
		if(equipmentRef.current.disabled) {
			equipmentRef.current.value = ''
			setEquipment('')
		}
		if(modelNameRef.current.disabled) {
			modelNameRef.current.value = ''
			setModelName('')
		}

	},[division, subject])

	useEffect(()=> {
		(inoRef.current.value &&
			priceRef.current.value &&
			productStandardRef.current.value) ?	setBtnDisabled(!((productNameRef.current.disabled ? true : !!productNameRef.current.value) &&
			(equipmentRef.current.disabled ? true :!!equipmentRef.current.value) &&
			(modelNameRef.current.disabled ? true : !!modelNameRef.current.value)))	 : setBtnDisabled(true)

		// (productNameRef.current.disabled ? true : productNameRef.current.value ? true : false) &&
		// (equipmentRef.current.disabled ? true :equipmentRef.current.value ? true : false) &&
		// (modelNameRef.current.disabled ? true : modelNameRef.current.value ? true : false)

	},[ino,division,subject,product_name,product_standard,model_name,equipment,price])

	const onSubmit = () => {
		window.popupManager.open(Confirm, {
			contents: (props.writeMode === 3 && props.updateNumber !== -1)  ? '수정하시겠습니까?' :'등록하시겠습니까?',
			onConfirm: () => {
				let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
				let data = (props.writeMode === 3 && props.updateNumber !== -1)  ? {
					no: props.updateNumber
					,ino,division,subject,product_name,product_standard,model_name,equipment,
					price: NumberUtils.priceToString(price),
					createAdmin: `${userInfo.name}(${userInfo.id})`
				} : {
					ino,division,subject,product_name,product_standard,model_name,equipment,
					price: NumberUtils.priceToString(price),
					createAdmin: `${userInfo.name}(${userInfo.id})`
				}
				ProApi.insertPro(data)
					.then(res => {
						window.popupManager.open(Alert, {
							contents: (props.writeMode === 3 && props.updateNumber !== -1)? '수정이 완료되었습니다.' :'등록이 완료되었습니다.',
							onClose: () => {props.setWriteMode(0)}
						})
					})
			}
		})
	}

	useEffect(() => {
		if (props.writeMode === 3 && props.updateNumber !== -1) {
			ProApi.detailPro({ no: props.updateNumber })
				.then((res) => {

					divisionRef.current.value = res.data.data.division
					setDivision(res.data.data.division)

					subjectRef.current.value = res.data.data.subject
					setSubject(res.data.data.subject)

					inoRef.current.value = res.data.data.ino
					setIno(res.data.data.ino)

					productNameRef.current.value = res.data.data.product_name
					setProductName(res.data.data.product_name)

					productStandardRef.current.value = res.data.data.product_standard
					setProductStandard(res.data.data.product_standard)

					equipmentRef.current.value = res.data.data.equipment
					setEquipment(res.data.data.equipment)
					priceRef.current.value = removeComma(res.data.data.price)
					setPrice(removeComma(res.data.data.price))

					modelNameRef.current.value = res.data.data.model_name
					setModelName(res.data.data.model_name)
				})
		}
	}, [props.writeMode])

	function removeComma(str)
	{

		let n = (str.replace(/,/g,""));

		return n;

	}



	return (
		<>
			<div className="title_box clear">
				<h2 className="title left">조달우수 등록</h2>

				<ul className="right">
					<li>HOME</li>
					<li>조달우수 관리</li>
					<li>조달우수 등록</li>
				</ul>
			</div>

			<div className="table_wrap">
				<table className="type2 left2_table txt_content">
					<colgroup>
						<col width="*"/>
					</colgroup>
					<tbody>
					<tr>
						<th className="required">구분/품목</th>
						<td>
							<select className="select w_60perminus15" ref={divisionRef} onChange={()=>onChangeHandler(divisionRef, setDivision)}>
								{PRO_OPTION.map(item => (
									<option value={item.value}
									        defaultValue={'PRO'}
									        key={item.value}
									>{item.name}</option>
									)
								)}
							</select>
							<select className="select w_60per marleft_10" ref={subjectRef} onChange={()=>onChangeHandler(subjectRef, setSubject)}>
								{subjectArr.map(item => (
										<option value={item.value}
										        defaultValue={'main'}
										        key={item.value}
										>{item.name}</option>
									)
								)}
							</select>
						</td>
						<th className="required">물품식별번호</th>
						<td>
							{/*비활성화의 경우입니다  disabled */}
							<input type="text" className="w_full" ref={inoRef} onChange={()=>onChangeHandler(inoRef, setIno)}/>
						</td>
					</tr>
					<tr>
						<th className="required">품명</th>
						<td>
							<input type="text" className="w_full" ref={productNameRef} onChange={()=>onChangeHandler(productNameRef, setProductName)} disabled={!(division === 'PRO' && subject === 'sub') || (division === 'MAS' && subject === 'sub')}/>
						</td>
						<th className="required">모델명</th>
						<td>
							<input type="text" className="w_full" ref={modelNameRef} onChange={()=>onChangeHandler(modelNameRef, setModelName)} disabled={division === 'MAS' && subject === 'sub'}/>
						</td>
					</tr>
					<tr>
						<th className="required">기자재</th>
						<td>
							<input type="text" className={`w_full`} ref={equipmentRef} onChange={()=>onChangeHandler(equipmentRef, setEquipment)} disabled={!(division ==='MAS' && subject ==='main')}  />
						</td>
						<th className="required">단가(원)</th>
						<td>
							<input type="text" className="w_full" ref={priceRef} onChange={()=>onChangeHandler(priceRef, setPrice)}/>
						</td>
					</tr>
					<tr>
						<th className="required">제품(상세/구성)규격</th>
						<td colSpan="3">
							<input type="text" className="w_full" ref={productStandardRef} onChange={()=>onChangeHandler(productStandardRef, setProductStandard)}/>
						</td>
					</tr>
					</tbody>
				</table>

				<div className="btn_center martop_20">
					  {/*필수입력 미 입력 시 disabled 클래스 추가됩니다 */}
					 {/*<button type="button" class="blue_btn w_100 confirm_btn disabled">등록</button>*/}
					<button type="button" className={`blue_btn w_100 confirm_btn ${btn_disabled ? 'disabled' : ''}`} onClick={()=>onSubmit()} disabled={btn_disabled}>등록</button>
					<button type="button" className="white_btn w_100" onClick={()=>props.setWriteMode(0)}>취소</button>
				</div>
			</div>
		</>
	)
}

export default PostProcurement
