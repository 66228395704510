import React, {useState, useEffect} from 'react'
import NoticeApi from '../../../Api/NoticeApi'
import useMainList from '../../../Hooks/useMainList'
import useMainPage from '../../../Hooks/useMainPage'
import { useDispatch } from 'react-redux'
import { notOnlyUseKR, onlyUseKR } from '../../../Reducer/useOnlyKR'


const Notice = () => {

	const [page, setPage] = useState(0)
	const [list ,setList] = useState([])
	const [pageCount, setPageCount] = useState(1)
	const [listCount, setListCount] = useState(0)
	const [emptyText, setEmptyText] = useState('')

	const [pageComponent, selectedPage] = useMainPage(pageCount)


	const listView = useMainList(list, listCount, selectedPage)

	const dispatch = useDispatch()

	useEffect(()=> {
		dispatch(onlyUseKR(true))

		return () => {
			dispatch(notOnlyUseKR(false))
		}

	},[])

	useEffect(() => {
		let notice = { page: selectedPage}
		NoticeApi.getNotice(notice)
			.then(res => {
				if (res.data.data.notice.length > 0 || res.data.data.count > 0) {
					setList(res.data.data.notice)
				} else {
					setEmptyText('<span>등록된 공지사항이 없습니다.</span>')
				}


				setPageCount(res.data.data.allPage)
				setListCount(res.data.data.count)
			})
	},[selectedPage])

	return (
		<main className="sub_page kor">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>인텔리빅스</li>
					<li>고객문의</li>
					<li>공지사항</li>
				</ul>
				<h2 className="sub_title">공지사항</h2>
				<p className="title_txt">인텔리빅스의 소식을 전달합니다.</p>
				<div className="table_type1 notice martop_40">
					<div className="thead">
						<span className="w_100">번호</span>
						<span className="w_minus450">제목</span>
						<span className="w_200">등록일</span>
						<span className="w_150">조회수</span>
					</div>
					<div className="tbody">
				{!emptyText ? listView : <div className={'tbody_tr'} dangerouslySetInnerHTML={{__html: emptyText}} />}
					</div>
				</div>

				{/*<ul className="paging">*/}
				{/*	<li className="paging_first"><a href=""></a></li>*/}
				{/*	<li className="paging_prev"><a href=""></a></li>*/}
				{/*	<li className="paging_num active"><a href="">1</a></li>*/}
				{/*	<li className="paging_num"><a href="">2</a></li>*/}
				{/*	<li className="paging_num"><a href="">3</a></li>*/}
				{/*	<li className="paging_num"><a href="">4</a></li>*/}
				{/*	<li className="paging_num"><a href="">5</a></li>*/}
				{/*	<li className="paging_next"><a href=""></a></li>*/}
				{/*	<li className="paging_last"><a href=""></a></li>*/}
				{/*</ul>*/}
				{!emptyText && pageComponent}
			</div>

			<div className="sub_imgBox img1">
				<div className="main_middle">
					<em className="img_tit">Vision AI 전문 기업 인텔리빅스</em>
					<p className="img_txt">앞선 경쟁력, 최고의 가치를 전달하는 Vision AI 선도기업 인텔리빅스</p>
				</div>
			</div>

		</main>
	)
}

export default Notice
