import React, {useState, useEffect, useRef} from 'react'
import 'AdminAssets/assets/js/datepicker'
import 'AdminAssets/assets/js/datepicker.ko'
import 'AdminAssets/assets/js/common'
import dayjs from 'dayjs'

const useSearch = (writeMode) => {

	// const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"))
	// const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"))

	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [title, setTitle] = useState('')
	const titleRef = useRef('')

	useEffect(() => {
		var dateToggleList = ["#datepickerToggle", "#datepickerToggle2"];
		dateToggleList.forEach(function(dateToggle){
			if(window.jQuery(dateToggle).length>0){
				window.jQuery(dateToggle).datepicker({
					language: 'ko',
					autoClose: true,
					onSelect: function onSelect(fd, date) {
						var year = date.getFullYear();
						var month = date.getMonth() + 1;
						month = month < 10 ? "0" + month : month;
						var day = date.getDate();
						day = day < 10 ? "0" + day : day;
						window.jQuery(dateToggle).val(year + "-" + month + "-" + day);
					}
				});
			}
		});

		window.jQuery('#datepickerToggle').val(dayjs().format("YYYY-MM-DD"))
		window.jQuery('#datepickerToggle2').val(dayjs().format("YYYY-MM-DD"))
	}, [writeMode])

	const changeDate = (date) => {
		switch (date) {
			case 'today': {
				window.jQuery('#datepickerToggle').val(dayjs().format("YYYY-MM-DD"))
				window.jQuery('#datepickerToggle2').val(dayjs().format("YYYY-MM-DD"))
			} break;
			case 7: {
				window.jQuery('#datepickerToggle').val(dayjs().set('day', -7).format("YYYY-MM-DD"))
				window.jQuery('#datepickerToggle2').val(dayjs().format("YYYY-MM-DD"))
			} break;
			case 30: {
				window.jQuery('#datepickerToggle').val(dayjs().set('month', dayjs().month() - 1).format("YYYY-MM-DD"))
				window.jQuery('#datepickerToggle2').val(dayjs().format("YYYY-MM-DD"))
			} break;
			case 90: {
				window.jQuery('#datepickerToggle').val(dayjs().set('month', dayjs().month() - 3).format("YYYY-MM-DD"))
				window.jQuery('#datepickerToggle2').val(dayjs().format("YYYY-MM-DD"))
			} break;
			case 180: {
				window.jQuery('#datepickerToggle').val(dayjs().set('month', dayjs().month() - 6).format("YYYY-MM-DD"))
				window.jQuery('#datepickerToggle2').val(dayjs().format("YYYY-MM-DD"))
			} break;
			case 365: {
				window.jQuery('#datepickerToggle').val(dayjs().set('year', dayjs().get('year')-1).format("YYYY-MM-DD"))
				window.jQuery('#datepickerToggle2').val(dayjs().format("YYYY-MM-DD"))
			} break;

		}
	}

	const goSearch = () => {
		setStartDate(window.jQuery('#datepickerToggle').val())
		setEndDate(window.jQuery('#datepickerToggle2').val())
		setTitle(titleRef.current.value)
	}



	return [(
		<div className="inp_wrap">
			<label htmlFor="datepickerToggle">등록일</label>
			<div className="custom_date w_180 marleft_16">
				<input type="text" className="date w_full" id="datepickerToggle" readOnly />
			</div>
			<span className="tilde">~</span>
			<div className="custom_date w_180">
				<input type="text" className="date w_full" id="datepickerToggle2" readOnly />
			</div>

			<ul className="date_radio clear marleft_14">
				<li>
					<input type="radio" name="dateRadio" className="ch_radio hide" id="dateRadio1" defaultChecked />
					<label htmlFor="dateRadio1" className="date_label" onClick={()=> changeDate('today')}>오늘</label>
				</li>
				<li>
					<input type="radio" name="dateRadio" className="ch_radio hide" id="dateRadio2" />
					<label htmlFor="dateRadio2" className="date_label" onClick={()=> changeDate(7)}>일주일</label>
				</li>
				<li>
					<input type="radio" name="dateRadio" className="ch_radio hide" id="dateRadio3" />
					<label htmlFor="dateRadio3" className="date_label"  onClick={()=> changeDate(30)}>1개월</label>
				</li>
				<li>
					<input type="radio" name="dateRadio" className="ch_radio hide" id="dateRadio4" />
					<label htmlFor="dateRadio4" className="date_label"  onClick={()=> changeDate(90)}>3개월</label>
				</li>
				<li>
					<input type="radio" name="dateRadio" className="ch_radio hide" id="dateRadio5" />
					<label htmlFor="dateRadio5" className="date_label"  onClick={()=> changeDate(180)}>6개월</label>
				</li>
				<li>
					<input type="radio" name="dateRadio" className="ch_radio hide" id="dateRadio6" />
					<label htmlFor="dateRadio6" className="date_label"  onClick={()=> changeDate(365)}>1년</label>
				</li>
			</ul>

			<div className="break_section marleft_60">
				<label>검색어</label>
				<input type="text" className="w_394 marleft_16" placeholder="검색어를 입력해주세요." ref={titleRef} />
				<button className="black_btn w_100 marleft_1" onClick={()=>goSearch()}>검색</button>
			</div>
		</div>
	),startDate, endDate, title]
}
export default useSearch
