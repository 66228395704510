import React from 'react'
import { useTranslation } from 'react-i18next'

const AIFarm = () => {

	const { t, i18n } = useTranslation()

	const lang = i18n.language === 'ko'

	const findImage = (path) => {
		let imgArray = path.split('images/')
		let img = imgArray[imgArray.length - 1]

		return require(`MainAssets/assets/images/${img}`)
	}

	return lang ? (
		<div id="business_panel5" className="business_panel">
			<div className="business_img img5">
				<div className="business_txt">
					<strong>AI Farm</strong>
					<p>AI Farm의 여러 분야 가운데 양돈 농장의 모돈이 분만하는 분만사를 비롯한 농장 내 구축된 CCTV를 통해 모돈의 활력 징후 및 분만 여부를 검출하는 인공지능(AI)기반의 영상 분석으로
						양돈 생산성과 효율을 극대화하여 높은 부가가치 창출과 양돈 산업의 무인화를 목표로 솔루션을 공급하고 있습니다.</p>
				</div>
			</div>
			<div className="sub_con">
				<strong className="business_tit">왜 필요할까요?</strong>
				<p className="business_use">수많은 분만사에서 불규칙, 동시 다발적으로 발생하는 분만에 대해 대응이 어렵고, 피크타임 내 대응하지 못하면 저체중, 체온하락, 압사, 양수와 태막에
					의한 질식사 등 다양한 이유로 폐사율이 증가합니다. <span className="highlighter">양돈 수익과 직결되는 자돈의 건강 및 성장 기대치를 위해 Vision AI를 활용하여 실시간으로 모돈 분만 상태를 확인합니다.</span>
				</p>
				<div className="bsn_martop_80">
					<strong className="business_tit">이렇게 활용할 수 있습니다.</strong>
					<ul className="business_dot">
						<li>모돈 농장의 다양한 생산성 지표 향상을 위한 영상 데이터 확보</li>
						<li>다양한 생산성 지표 정보의 데이터화에 최적화된 현장 맞춤형 솔루션</li>
						<li>양돈 산업의 무인화</li>
						<li>양돈 관리를 위한 최적화된 솔루션</li>
					</ul>
				</div>
				<div className="bsn_martop_80">
					<strong className="business_tit bsn_martop_80">구축 사이트</strong>
					<ul className="business_dot">
						<li>글로벌 곡물 기업 스마트팜 사업 추진 (2020~)</li>
					</ul>
				</div>
				<div className="bsn_btmimg">
					<img src={findImage('../assets/images/img_bsn05.png')} alt=""/>
				</div>
			</div>
		</div>
	) : (
		<>
			<div id="Ebusiness_panel5" className="Ebusiness_panel">
				<div className="business_img img5">
					<div className="business_txt">
						<strong>AI Farm</strong>
						<p>It develops and supplies products with the aim of creating high added value and unmanned pig industry by
							maximizing pig production and efficiency through artificial intelligence (AI)-based video analytics that
							detects the vitality and delivery of pig through CCTV built on the farm, including pig farms.</p>
					</div>
				</div>
				<div className="sub_con">
					<strong className="business_tit">Background</strong>
					<ul className="business_dot">
						<li>Inability to respond properly to irregular and simultaneous farrowing in numerous maternity barn</li>
						<li>If it fails to respond within Peak time (30 minutes), the mortality rate increases due to underweight,
							decreased body temperature, crushed death, suffocation by amniotic fluid and placenta, etc.
						</li>
						<li>Increasing the need for solutions for the health and growth expectations of funds directly related to
							pig income
						</li>
					</ul>
					<strong className="business_tit">Necessity</strong>
					<ul className="business_dot">
						<li>Optimization of sow's standing/lying state(vital signs) and farrowing detection through real-time input
							video by developing deep learning-based object detection and action recognition technology
						</li>
						<li>Real-time sharing of multi-channel real-time processing and processing results</li>
						<li>Improvement of various productivity indicators such as safe farrowing and self-health through rapid care
							within "Peak time"
						</li>
					</ul>
					<strong className="business_tit">Solution Competitiveness</strong>
					<ul className="business_dot marbtm_100">
						<li>A field-specific solution that detects the condition of sow and farrow events with deep learning-based
							detectors and reduces misdetection through the development of post-processing classifiers through Action
							Recognition(AR)
						</li>
						<li>Through multi-channel video analytics processing, it is optimized for data collection of various
							productivity indicator information such as the vital signs of sow, the number of piglets, and the health
							status of piglets
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default AIFarm
