import React,{useState, useEffect, useRef} from 'react';
import noImage from 'AdminAssets/assets/images/noimg.png'
import Alert from '../../../../Modal/Component/Alert'
import NewsApi from '../../../../Api/NewsApi'
import CommonAxios from '../../../../Axios/CommonAxios'

const PostNews = (props) => {

	const fileRef = useRef()
	const titleRef = useRef()
	const [title, setTitle] = useState('')
	const urlRef = useRef()
	const [url, setUrl] = useState('')
	const reportRef = useRef()
	const [report, setReport] = useState('')


	const [fileName, setFileName] = useState('')
	const [fileSrc, setFileSrc] = useState(noImage)
	const [isUpload, setIsUpload] = useState(false)
	const [fileData, setFileData] = useState(null)

	const [disabled, setDisabled] = useState(true)


	let permitExtensionList = ['PNG','JPG','GIF']

	const RemoveFile = () => {
		setFileName('')
		setFileSrc(noImage)
		setIsUpload(false)
		setFileData(null)
		fileRef.current.value = ''
	}

	useEffect(()=> {
		(title && url && report && isUpload) ? setDisabled(false) : setDisabled(true)
	},[title, url, report, isUpload])

	const fileHandler = (e) => {
		if (e.target.files && e.target.files[0]) {
			if (10 === -1 || (10 > 0 && 10 * 1024 * 1024 >= e.target.files[0].size)) {
				const currentFileExtension = getExtension(e.target.files[0].name)
				if (permitExtensionList.filter(list => list.toUpperCase() === currentFileExtension.toUpperCase()).length > 0) {
					const fileReader = new FileReader()
					setFileData(e.target.files[0])
					const file = e.target.files[0]
					fileReader.readAsDataURL(file)
					fileReader.onload = (e) => {
						let img = new Image()
						img.src = fileReader.result
						img.onload = () => {
							if (img.width >= 300 && img.height >= 400) {
								setFileSrc(e.target.result)
								setIsUpload(true)
								// setTagSrc(reader.result)
							} else uploadErrHandler('size')
						}
					}
					setFileName(file.name)
				} else uploadErrHandler('extension')
			} else uploadErrHandler('fileSize')
		}
	}

	const getExtension = name => {
		if (!name) return ''
		const names = name.split('.')
		if (names.length > 1) return names[names.length - 1]
		else return ''
	}

	const uploadErrHandler = (exception) => {
		let contents = ''
		switch (exception) {
			case 'size': contents = '최소 사이즈는 300x400 입니다.'
				break;
			case 'extension': contents = '이미지파일만 업로드 가능합니다.'
				break;
			case 'fileSize': contents = '파일의 크기는 10MB 까지 업로드 가능합니다.'
		}

		window.popupManager.open(Alert,{
			contents,
			onClose: () => {RemoveFile()}
		})
	}

	const writeNews = () => {
		let data = new FormData();
		let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
		data.append('file',fileData)
		data.append('title', titleRef.current.value)
		data.append('url',urlRef.current.value)
		data.append('reportDate',reportRef.current.value)
		data.append('createAdmin',`${userInfo.name}(${userInfo.id})`)

		NewsApi.writeNews(data)
			.then(()=> {
				props.setWriteMode(0)
			})
	}

	const updateNews = () => {
		let data = new FormData();
		let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
		fileData && data.append('file',fileData)
		data.append('no',props.updateNumber)
		data.append('title', titleRef.current.value)
		data.append('url',urlRef.current.value)
		data.append('reportDate',reportRef.current.value)
		data.append('createAdmin',`${userInfo.name}(${userInfo.id})`)

		NewsApi.updateNews(data)
			.then(()=> {
				props.setWriteMode(0)
			})
	}

	useEffect(() => {
		if (props.writeMode === 3 && props.updateNumber !== -1) {
			NewsApi.getDetailNews({ no: props.updateNumber })
				.then((res) => {
					titleRef.current.value = res.data.data.title
					setTitle(res.data.data.title)
					urlRef.current.value = res.data.data.url
					setUrl(res.data.data.url)
					reportRef.current.value = res.data.data.reportDate
					setReport(res.data.data.reportDate)

					setIsUpload(true)

					setFileSrc(CommonAxios.defaults.baseURL+`/api/file/${res.data.data.no}`)
				})
		}
	}, [props.writeMode])

	return (
		<div className="table_wrap">
			<table className="type2 txt_left1 txt_content">
				<colgroup>
					<col width="259px" />
						<col width="*" />
				</colgroup>
				<tbody>
				<tr>
					<th className="required">제목</th>
					<td>
						<input type="text" className="w_full" ref={titleRef} placeholder="제목을 입력해주세요." onChange={()=>setTitle(titleRef.current.value)} />
					</td>
				</tr>
				<tr>
					<th className="required">썸네일 업로드</th>
					<td>
						<div className="upload_box clear">
							<div className="thumbnail_box">
								<img src={fileSrc} alt="thumbnail" />
							</div>

							<ul>
								<li>썸네일 사이즈 300 x 400</li>
								<li>최대 용량 10MB</li>
								<li className="enroll_btn_area">
									<label htmlFor="imgEnroll" className="blue_btn w_130">이미지 업로드</label>
									<input id="imgEnroll" ref={fileRef} type="file" className="hide" onChange={fileHandler} />
								</li>
							</ul>
						</div>
					</td>
				</tr>
				<tr>
					<th className="required">연결 URL</th>
					<td>
						<input type="text" className="w_full" ref={urlRef} placeholder="연결할 URL을 입력해주세요." onChange={()=> setUrl(urlRef.current.value)} />
					</td>
				</tr>
				<tr>
					<th className="required">보도일</th>
					<td>
						<input type="text" className="w_full" ref={reportRef} placeholder="보도일을 입력해주세요. 예)2021-10-15" onChange={()=> setReport(reportRef.current.value)} />
					</td>
				</tr>
				</tbody>
			</table>

			<div className="btn_center martop_20">
				<button type="button" className={`blue_btn w_100 ${disabled ? 'disabled' : ''}`} onClick={()=>props.writeMode === 1 ? writeNews() : updateNews()} disabled={disabled}>등록</button>
				<button type="button" className="white_btn w_100" onClick={() => props.setWriteMode(0)}>취소</button>
			</div>
		</div>
	)
}

export default PostNews
