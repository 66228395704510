import React, { useEffect, useState } from 'react'
import useProSearch from 'Hooks/useProSearch'
import ProApi from '../../../../Api/ProApi'
import useSearch from '../../../../Hooks/useSearch'
import usePage from '../../../../Hooks/usePage'
import useList from '../../../../Hooks/useList'
import useProList from '../../../../Hooks/useProList'
import NoticeApi from '../../../../Api/NoticeApi'
import Alert from '../../../../Modal/Component/Alert'
import PostNotice from '../Notice/PostNotice'
import DetailNotice from '../Notice/DetailNotice'
import PostProcurement from './PostProcurement'
import Confirm from '../../../../Modal/Component/Confirm'
import DetailProcurement from './DetailProcurement'

const Procurement = () => {

	const [writeMode, setWriteMode] = useState(0)

	const [data, setData] = useState([])

	const [pageCount, setPageCount] = useState(1)
	const [listCount, setListCount] = useState(0)
	const [allChecked, setAllChecked] = useState(false)
	const [updateNumber, setUpdateNumber] = useState(-1)

	const [selectNo, setSelectNo] = useState(null)
	const [pageComponent, selectedPage] = usePage(pageCount)

	const [emptyText, setEmptyText] = useState('')

	const [list, checkList] = useProList(data, setSelectNo, setWriteMode, listCount, selectedPage, allChecked)
	const [searchComponent, startDate, endDate, value, division] = useProSearch(writeMode)

	useEffect(() => {
		setPageCount(1)
	}, [startDate, endDate])

	useEffect(() => {
		writeMode === 0 && getData(startDate, endDate, value, division, selectedPage)
	}, [writeMode])

	useEffect(() => {
		getData(startDate, endDate, value, division, selectedPage)
	}, [startDate, endDate, value, division, selectedPage])

	const getData = (startDate, endDate, value, division, page) => {
		let params = { startDate, endDate, searchValue: value, division, page }
		ProApi.searchList(params)
			.then(res => {
				if (res.data.data.procurement && (res.data.data.procurement.length > 0 || listCount > 0)) {
					setData(res.data.data.procurement)
				} else {
					setEmptyText('등록된 조달우수가 없습니다. <br /> 조달우수 등록버튼을 통해 등록해주세요.')
				}
				setPageCount(res.data.data.allPage)
				setListCount(res.data.data.count)
			})
	}

	useEffect(() => {
		console.log(selectNo)
	}, [selectNo])

	const deleteList = () => {
		if (checkList.length === 0) {
			window.popupManager.open(Alert, {
				contents: '삭제할 게시물을 선택해주세요'
			})
		} else {
			window.popupManager.open(Confirm, {
				contents: '삭제하시겠습니까?',
				onConfirm: () => {
					ProApi.deletePro(checkList)
						.then(res => {
							window.popupManager.open(Alert, {
								contents: '삭제가 완료되었습니다.',
								onClose: () => {
									getData('', '', '', '', selectedPage)
									setPageCount(1)
								}
							})
						})
				}
			})
		}
	}

	return (
		writeMode === 0 ? <>
			<div className="title_box clear">
				<h2 className="title left">조달우수 관리</h2>

				<ul className="right">
					<li>HOME</li>
					<li>조달우수 관리</li>
				</ul>
			</div>

			{!emptyText && searchComponent}

			<div className="table_wrap martop_20">

				{!emptyText ? <table className="type1 notice_list tdpd_5 onclick">
					<colgroup>
						<col width="50px"/>
						<col width="50px"/>
						<col width="*"/>
					</colgroup>
					<thead>
					<tr>
						<th>
							<input type="checkbox" name="inpChk" className="ch_check hide allcheck" id={'allChk'}
							       onChange={() => setAllChecked(!allChecked)}/>
							<label htmlFor="allChk" className="label single"/>
						</th>
						<th>번호</th>
						<th>등록일</th>
						<th>구분</th>
						<th>품목</th>
						<th>물품식별번호</th>
						<th>품명</th>
						<th>모델명</th>
						<th>제품(상세/구성)규격</th>
						<th>기자재</th>
						<th>단가(원)</th>
						<th>등록자</th>
					</tr>
					</thead>
					<tbody>
					{list}

					</tbody>

				</table> : <li dangerouslySetInnerHTML={{ __html: emptyText }}/>}

				{!emptyText && pageComponent}

				<div className="btn_bottom btn_center">
					{!emptyText && <button type="button" className="white_btn w_70 confirm_btn" onClick={() => deleteList()}>삭제</button>
					}					<button type="button" className="blue_btn w_130" onClick={() => {
						setWriteMode(1)
					}}>조달우수 등록
					</button>
				</div>
			</div>
		</> : writeMode === 1 || writeMode === 3 ? <PostProcurement writeMode={writeMode} setWriteMode={setWriteMode}
		                                                            updateNumber={updateNumber}/> :
			<DetailProcurement detail={selectNo}
			                   setWriteMode={setWriteMode} setUpdateNumber={setUpdateNumber}/>
	)
}

export default Procurement
