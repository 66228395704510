import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import useDataSearch from '../../../../Hooks/useDataSearch'

const Procurement = (props) => {

	const [selectedState, setSelectedState] = useState('main')

	const SELECT = [
		{ label: '물품식별번호', value: 'ino' },
		{ label: '모델명', value: 'model_name' },
		{ label: '단가', value: 'price' }
	]
	const [main, setMain] = useState([])
	const [sub, setSub] = useState([])

	const data1 = {
		select : SELECT,
		data: props.data1 && props.data1,
		setState: setMain
	}

	const data2 = {
		select : SELECT,
		data: props.data2 && props.data2,
		setState: setSub
	}

	const [component1, component2] = useDataSearch(data1,data2, selectedState)


	useEffect(() => {
		props.data1 && setMain(props.data1)
	}, [props.data1, selectedState])

	useEffect(() => {
		props.data2 && setSub(props.data2)
	}, [props.data2, selectedState])

	return (
		<div id="business_panel1" className="business_panel">
			<div className="position_nav">
				<ul className="sub_nav">
					<li>조달우수</li>
					<li>우수제품</li>
				</ul>
				<h2 className="sub_title">우수제품</h2>
			</div>
			<p className="sub_txt martop_70">CCTV 카메라로부터 수신된 영상을 현장제어부에 설치한 엣지형 영상분석장치를 통해 사람/차량을검출/추적/분류하며, 설정된 영상분석 이벤트
				감지 시 관제 센터 내 담당 운영자에게 실시간 알람 발생, 관련 이벤트 정보를 저장하여 사후에 효율적인 비디오 검색을 할 수 있는 시스템으로 생활안전 및 방범, 지능형 교통시스템, 산업안전,
				BI, Retail, 스마트시티등 사회안전, 범죄 예방 및 공공감시를 위하여 다양한 장소에 설치가능하며, 사건, 사고등 여러상황에 적용하여 활용할수 있는딥러닝(DCNN) 기반의 엣지형
				영상분석장치</p>

			<div className="small_tab_btn martop_70">
				<button data-target="article_panel1" className={selectedState === 'main' ? 'active' : ''}
				        onClick={() => setSelectedState('main')}>주품목
				</button>
				<button data-target="article_panel2" className={selectedState === 'sub' ? 'active' : ''}
				        onClick={() => setSelectedState('sub')}>추가선택품목
				</button>
			</div>

			{/* 우수제품 - 주품목 */}
			<div id="article_panel1" className={selectedState !== 'main' ? 'article_panel dis_none' : ''}>
				<div className="clear">
					{/*<div className="product_inp right">*/}
					{/*	<select className="w_150" ref={selectRef}>*/}
					{/*		{SELECT.map(item => (*/}
					{/*			<option key={item.value} value={item.value}>{item.label}</option>*/}
					{/*		))}*/}
					{/*	</select>*/}
					{/*	<input type="text" className="w_280" placeholder="검색어를 입력해주세요" ref={searchRef1}/>*/}
					{/*	<button className="skyblue_btn w_100 marleft_10" onClick={() => search(props.data1, setMain, searchRef1)}>검색</button>*/}
					{/*</div>*/}
					{component1}
				</div>

				<div className="table_type1 article martop_40">
					<div className="thead">
						<span className="w_200 M30per">물품식별번호</span>
						<span className="w_220 M30per">모델명</span>
						<span className="w_minus620 M_disnone">구성</span>
						<span className="w_200 M30per">단가</span>
					</div>

					<div className="tbody">
						{main && main.length !== 0 && main.map(item => (
							<div className="tbody_tr" key={item.no}>
								<span className="w_200 M30per">{item.ino}</span>
								<span className="w_220 M40per">{item.model_name}</span>
								<span className="w_minus620 txt_left M_disnone">{item.product_standard}</span>
								<span className="w_200 M30per txt_right">{item.price}</span>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* 우수제품 - 추가선택품목 */}
			<div id="article_panel2" className={selectedState !== 'sub' ? 'article_panel dis_none' : ''}>
				<div className="clear">
					{/*<div className="product_inp right">*/}
					{/*	<select className="w_150">*/}
					{/*		<option>물품식별번호</option>*/}
					{/*		<option>품명</option>*/}
					{/*		<option>단가</option>*/}
					{/*	</select>*/}
					{/*	<input type="text" className="w_280" placeholder="검색어를 입력해주세요" ref={searchRef2}/>*/}
					{/*	<button className="skyblue_btn w_100 marleft_10" onClick={() => search(props.data2, setSub, searchRef2)}>검색</button>*/}
					{/*</div>*/}
					{component2}
				</div>

				<div className="table_type1 article martop_40">
					<div className="thead">
						<span className="w_200 M30per">물품식별번호</span>
						<span className="w_220 M40per">품명</span>
						<span className="w_minus820 M_disnone">상세규격</span>
						<span className="w_240 M_disnone">모델명</span>
						<span className="w_160 M30per">단가</span>
					</div>
					<div className="tbody">
						{sub && sub.length !== 0 && sub.map(item => (
							<div className="tbody_tr" key={item.no}>
								<span className="w_200 M30per">{item.ino}</span>
								<span className="w_220 M40per">{item.product_name}</span>
								<span className="w_minus820 txt_left M_disnone">{item.product_standard}</span>
								<span className="w_240 M_disnone">{item.model_name}</span>
								<span className="w_160 M30per txt_right">{item.price}</span>
								{/*<span className="w_160 M30per txt_right"></span>*/}
							</div>
						))}
					</div>
				</div>
			</div>

		</div>
	)
}

export default Procurement
