import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const VideoSurveliance = (props) => {

	const { t, i18n } = useTranslation()

	const lang = i18n.language === 'ko'

	const findImage = (path) => {
		let imgArray = path.split('images/')
		let img = imgArray[imgArray.length - 1]

		return require(`MainAssets/assets/images/${img}`)
	}

	const ref220b = useRef()
	const ref120b = useRef()

	useEffect(() => {
		if (props.hash && ref220b.current && ref120b.current) {
			let location = props.hash === '#220b' ? ref220b.current.offsetTop : ref120b.current.offsetTop
			window.scrollTo({ top: location })
		}
	}, [props.hash])

	return lang ? (
		<div id="business_panel1" className="business_panel">
			<div className="business_img img1">
				<div className="business_txt">
					<strong>영상 보안</strong>
					<p>
						사회가 복잡해지고 고도화되면서 보안을 향상 하기 위한 새로운 기술 도입이 증가하고 있습니다.
						<br />
						시설 경비, 대 테러 방지, 출입 통제등 다양한 영상 보안 영역에서 AI Vision 기술을 기반으로 한 다양한 솔루션을
						제공하고 있습니다.
					</p>
				</div>
			</div>
			<div className="sub_con">
				<strong className="business_tit">왜 필요할까요?</strong>
				<p className="business_use">
					CCTV의 설치는 해마다 증가하고 있습니다. 영상 감시에 사용되는 CCTV는 사건, 사고를 인지하기 위한 모니터링 용도로
					주로 활용되지만 한정적인 감시 인력과 자원으로 인해 사고나 사건 징후의 포착이 어렵습니다. CCTV를 통한 위기 대응
					능력과 사고의 골든타임을 확보하면서,{" "}
					<span className="highlighter">
						안전의 사각지대를 줄이기 위해 CCTV의 영상을 자동으로 분석해 사전에 정의된 이벤트를 검출하여 빠른 검색 등을
						통해 관제 효율을 증가
					</span>
					시킬 수 있습니다.
				</p>
				<div className="bsn_martop_80">
					<strong className="business_tit">이렇게 활용할 수 있습니다.</strong>
					<ul className="business_dot">
						<li>재난감시를 위한 솔루션 및 통합시스템 제공</li>
						<li>국가보안시설(청와대), GOP, 군/항만/공항 등 특수사업의 외곽경계 및 감시</li>
						<li>국내 주요 문화재 침입 및 화재, 주요삼림 산불 감시</li>
						<li>생활 방범, 어린이 보호, 아동 안전 및 주민의 안전보호를 위한 최적화된 솔루션</li>
					</ul>
				</div>
				<div className="bsn_martop_80">
					<strong className="business_tit bsn_martop_80">구축 사이트</strong>
					<div className="clear bsn_whalfBox">
						<div className="left w_halfminus_30">
							<strong className="tit_02">지자체</strong>
							<ul className="small_list marbtm_33">
								{/*20240221 클래스 변경('.marbtm_100' -> '.marbtm_33')*/}
								{/*20240221 텍스트 내용 변경 */}

								<li>- 스마트시티 (10 +)</li>
								<li className="plus">
									+ 부천, 오산, 인천, 세종, 부산(남구, 강서), 구리, 양주, 시흥, 의정부, 고양(일산, 능곡), 화성시, 함안군
								</li>
								<li>- 선별관제 (30 +)</li>
								<li className="plus">
									+ 순천, 수원, 용인, 하남, 화성, 의정부, 안산, 구리, 시흥, 부천, 안양, 군포, 청주, 오산, 공주, 고성,
									양주, 광주, 인천 계양, 부산(북구, 남구, 강서구, 해운대구), 전주
								</li>
								<li className="plus">+ 서울 : 구로, 강서, 동작, 은평, 금천, 관악, 동대문</li>
								<li>- 범죄 예방 등 :</li>
								<li className="plus">
									서울 성북구/영등포구/광진구/용산구/강남구/서대문구
									<br />
									경기도 남양주시/성남시/용인시/시흥시/수원시/이천시/안성시
									<br />
									경북 영주시/경남 양산시/밀양시/진주시/거제시/창녕군/거창군
									<br />
									울산 울주군/전북 전주시/충남 서산시/태안군
								</li>
							</ul>

							<strong className="tit_02">문화재</strong>
							<ul className="small_list marbtm_33">
								{/*20240221 클래스 변경('.marbtm_100' -> '.marbtm_33')*/}
								{/*20240221 텍스트 내용 추가 및 변경 */}
								<li>- 박정희대통령 생가 지능형 영상분석 감시시스템 설치(2023년)</li>
								<li>- 문화재청 기록관 환경개선 공사(2023년)</li>
								<li>- 4대궁, 조선왕릉 CCTV Full HD성능개선 진행 (2017년~현재)</li>
								<li>- 4대궁, 종묘 성능개선 및 유지보수 (2014년~현재, 1200채널)</li>
								<li>- 숭례문 CCTV 시스템 구축 및 유지보수 (2013년~현재)</li>
								<li>- 조선왕릉 종합경비시스템 (2010년〜현재, 1400채널, 24개릉)</li>
							</ul>
							
							<strong className="tit_02">군/경 분야</strong>
							<ul className="small_list marbtm_20">
								{/*20240221 텍스트 내용 추가*/}
								<li>- 육군사관학교 솔루션 공급(2023년)</li>
								<li>- 국방부 군수사령부 혁신제품 납품설치(2023년)</li>
								<li>- LH인천지역본부 육군공수여단 현대화사업(2023년)</li>
								<li>- 부산진경찰서 사건기록보관실 구축사업 혁신제품 납품(2023년)</li>
								<li>- 전남 신안경찰서 청사 방호체계 구축(2023년)</li>
								<li>- 해군제1함대사령부</li>
								<li>- 항포구 고성능 CCTV 구축 사업 수주</li>
								<li>- 90정보통신단 (용산) 납품</li>
								<li>- 스마트 부대 구축 </li>
								<li>- 청와대 주요 관저 및 경내 CCTV시스템</li>
								<li>- 중요시설 1단계, 2단계 구축사업 납품</li>
								<li>- 서산 공군비행장 방호시설용 납품</li>
								<li>- GOP 중서부 카메라 시스템</li>
								<li>- GOP 중거리 카메라(육군,해병대) 시스템</li>
							</ul>
						</div>

						<div className="right w_halfminus_30">
							<strong className="tit_02">방범</strong>
							<ul className="small_list marbtm_20">
								{/*20240221 텍스트 내용 추가 및 변경 */}
								<li>- 박정희대통령 생가 지능형 영상분석 감시시스템 설치(2023년)</li>
								<li>- 문화재청 기록관 환경개선 공사(2023년)</li>
								<li>- LGU+ 지능형 CCTV 클라우드 상용화 서비스(~현재)</li>
								<li>- KT 지능형 CCTV 클라우드 상용화 서비스(~현재)</li>
								<li>- CJ 대한통운 스마트 물류 영상분석 계약(~2018년)</li>
							</ul>

							<strong className="tit_02">공공분야</strong>
							<ul className="small_list">
								{/* 20240221 텍스트 내용 추가 및 변경 */}
								<li>- 광화문광장 조성공사(2023년)</li>
								<li>- 평택브레인시티 공공폐수처리시설 납품(2023년)</li>
								<li>- 정보통신산업진흥원/천안의료원(2023년)</li>
								<li>- 고양시 화훼종합유통센터 통합보안시스템 구축(2023년)</li>
								<li>- 안산시 공영주차장, 대구 청소년수련관 주차장 현대화사업(2023년)</li>
								<li>- 광주보훈병원 납품(2023년)</li>
								<li>- 수원시 불법주정차단속 카메라 신규설치 및 성능개선(2023년)</li>
								<li>- 진주시 취수장 및 정수장/광명시 노온정수장(2023년) </li>
								<li>- 산불감시카메라 구축(강원도 횡성군/강릉시/철원군, 전남 장흥군)(2023년) </li>
								<li>- 서울시 로봇과학관 건립 통신공사(2023년) </li>
								<li>- 제주도 미세먼지 감시용 CCTV 설치공사(2023년)</li>
								<li>- 화성시 스마트그린도시 정보화 사업(2023년)</li>
								<li>- 환경부 홍수대책상황실 서버 구축사업(2023년)</li>
								<li>- 충남교육청 초중고등학교 지능형 영상감시시스템 구축(32개교)(2023년)</li>
								<li>- 전남교육청 초중고등학교 지능형 영상감시시스템 구축(13개교)(2023년)</li>
								<li>- 광주교육청 고등학교. 특수학교 지능형영상분석장치 납품설치(3개교)(2023년)</li>
								<li>- KBS 재난CCTV자동송출시스템 구축 (영상분석 선별) - 지진(2023년)</li>
								<li>- 국토교통부, 국토안전관리원, 한국산업안전보건공단, 서울디지털재단 산업안전 솔루션 납품(22년~)</li>
								<li>- 강원소방본부 교량자살방지솔루션 납품(22년)</li>
								<li>- 한국수력원자력 방사능 TLD감지 시스템</li>
								<li>- 서울교통공사 2호선 역사 지능형영상분석 구축(2019~20년)</li>
								<li>- 인천공항 승무원 자동출입국심사대 구축(2019년~)</li>
								<li>- 전국 국립자연휴양림 지능형CCTV 구축사업(2017~2019년)</li>
								<li>- 한국가스공사/난방공사/서부발전/태안발전/남동발전/중부발전/한국농어촌공사</li>
								<li>
									- 한국수력원자력, 서울교통공사, 인천공항, 국립자연휴양림, 한국가스공사/난방공사/서부발전/태안발전,
									강원랜드, 서울대공원
								</li>
								<li>- 평창동계올림픽 지능형영상분석 400채널 납품(2018년)</li>
								<li>- 평창동계올림픽 고정형 드론 영상분석 과제(2018년)</li>
								<li>- 강원랜드 재난안전 지능형 납품(2017년)</li>
								<li>- 부산항만공사 감천항/신항/인천항만공사 연안항/인천 내항 1·8부두/국제터미널</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="product_panel blue_back" ref={ref120b}>
				<div className="sub_con">
					<div className="product_box clear">
						<strong className="business_tit">주요 제품</strong>
						<div className="product_txt w_half">
							<strong>120B(AI 영상보안)</strong>
							<p>
								영상 내 이동하는 객체(사람, 차량 등)을 자동으로 감지하고 추적한 후 정해진 이벤트 감지를 수행할 수 있는
								지능형 영상 감시 소프트웨어입니다. 분석을 향상할 수 있는 모션 기반 및 딥러닝 기반 객체 검출, 감지 및
								추적을 동시에 제공할 뿐만 아니라 인식된 보행자의 속성, 차량의 종류를 실시간으로 분석하여 제공합니다.
							</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage("../assets/images/product_img01.png")} alt="" />
						</div>
					</div>
					<strong className="business_tit">주요 특징</strong>
					<div className="clear">
						<ul className="business_dot type2">
							<li>
								<span>모션 /DCNN 기반 객체에 대한 검출 및 분류 지원</span>
								<p>- 일반객체(사람, 차량)</p>
								<p>- 오버헤드뷰(사람)</p>
							</li>
							<li>비주얼 객체 추적 기술 탑재</li>
							<li>
								원거리 객체 검출 기술 향상을 위한 다중 관심 영역에 대한{" "}
								<span className="dis_block">DNN 기반 객체 검출</span>
							</li>
						</ul>
						<ul className="business_dot type2">
							<li>
								<span>영상관리/PTZ제어</span>
								<p>- CCTV 영상 입력: RTSP/ONVIF 포맷 지원</p>
								<p>- 카운팅, 히트맵, Dwell 표시</p>
								<p>- 영상 녹화 / 검색 / 재생 / 내보내기 지원</p>
								<p>- PTZ 카메라 제어: 조이스틱, 포인트 앤 클릭 제어</p>
							</li>
						</ul>
					</div>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit">대표 이벤트</strong>
						<ul className="event_list">
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_01.png")} alt="" />
									<span>배회</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_02.png")} alt="" />
									<span>경계선 통과</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_03.png")} alt="" />
									<span>방향성이동</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_04.png")} alt="" />
									<span>버려짐</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_05.png")} alt="" />
									<span>연기</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_06.png")} alt="" />
									<span>쓰러짐</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_07.png")} alt="" />
									<span>체류</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_08.png")} alt="" />
									<span>군집</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_09.png")} alt="" />
									<span>폭력</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_10.png")} alt="" />
									<span>불꽃</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="product_panel blue_back" ref={ref220b}>
				<div className="sub_con">
					<div className="product_box clear">
						<div className="product_txt w_half">
							<strong>220B(AI 자동추적)</strong>
							<p>
								마스터 카메라(고정형 카메라)와 슬레이브 카메라(PTZ 카메라)의 조합을 통해 마스터 카메라에서 객체를 감지한
								후 연동된 슬레이브 카메라를 통해 감지된 객체를 추적하는 자동 PTZ 추적 기능을 제공합니다. PTZ 카메라의
								광학 줌을 이용하여 감지된 물체에 대한 확대 영상을 실시간으로 제공합니다.
							</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage("../assets/images/product_img02.png")} alt="" />
						</div>
					</div>

					<strong className="business_tit">주요 특징</strong>
					<div className="clear">
						<ul className="business_dot type2">
							<li>M/S 기반 자동 PTZ 추적</li>
							<li>
								<span>N개의 마스터 카메라와 M개의 슬레이브 카메라 연동 제공</span>
								<p>- 마스터 카메라 : 고정형 카메라, 이벤트 감지</p>
								<p>- 슬레이브 카메라 : PTZ 카메라, 추적 기능</p>
							</li>
							<li>
								<span>슬레이브 카메라에 의한 확대영상 실시간 제공 : 광학줌 사용</span>
								<p>- 독자적인 지능형 벡터 추적 기술</p>
								<p>- PTZ 카메라와의 양방향 통신을 통한 부드럽고 정밀한 자동 추적</p>
							</li>
						</ul>
						<ul className="business_dot type2">
							<li>영상 기반의 마스터/슬레이브 카메라 보정 기능 제공(특허기술)</li>
							<li>카메라 자동 캘리브레이션 기술 지원</li>
							{/*230130 HJ 텍스트 추가*/}
							<li>
								<span>PTZ to PTZ</span>
								<p>- PTZ 연계를 통한 광역감시 기능 제공</p>
							</li>
						</ul>
					</div>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit">대표 이벤트</strong>
						<ul className="event_list">
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_01.png")} alt="" />
									<span>배회</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_02.png")} alt="" />
									<span>경계선 통과</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_03.png")} alt="" />
									<span>방향성이동</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_04.png")} alt="" />
									<span>버려짐</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_05.png")} alt="" />
									<span>연기</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_06.png")} alt="" />
									<span>쓰러짐</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_07.png")} alt="" />
									<span>체류</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_08.png")} alt="" />
									<span>군집</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_09.png")} alt="" />
									<span>폭력</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_10.png")} alt="" />
									<span>불꽃</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div id="Ebusiness_panel1" className="Ebusiness_panel">
			<div className="business_img img1">
				<div className="business_txt">
					<strong>Video Surveillance</strong>
					<p>
						CCTV, which is mainly used for video surveillance, does not recognize signs of accidents in advance, and it
						is difficult to monitor due to too many CCTVs.
						<br />
						The AI-based CCTV system, which automatically analyzes CCTV videos to actively respond to accidents and
						enables pre-signs to be detected, is the main roleof smart city these days.
						<br />
						Intellivix provides an all-in-one intelligent video monitoring solution that automatically analyzes
						real-time video input from CCTV video to detect/track objects, detect predefined events, and
						recording/playback/search.
					</p>
				</div>
			</div>
			<div className="sub_con">
				<div className="clear">
					<div className="left w_half m_wfull">
						<strong className="business_tit">Background</strong>
						<ul className="business_dot">
							<li>
								It's too difficult to capture the sign because of too many CCTV screens. There is no standarad for
								important events so that regular monitoring and manpower are required.
							</li>
						</ul>
					</div>
					<div className="left w_half m_wfull">
						<strong className="business_tit Mmartop_40">Necessity</strong>
						<ul className="business_dot">
							<li>Increase the control efficiency of a small number of controllers</li>
							<li>Need to detect signs of an incident or accident</li>
							<li>Rapid response through the quick search</li>
							<li>Resolve the security gap with more CCTV</li>
						</ul>
					</div>
				</div>
				<strong className="business_tit bsn_martop_80">Solution Competitiveness</strong>
				<ul className="business_dot marbtm_100">
					<li>
						All-in-one integrated video processing platform that allows video rece/storage/distribution/search/playback
						to multi-channel real-time video analytics
					</li>
					<li>
						Multi-channel (CCTV) simultaneous vision-AI processing technology optimized for Intel CPUs and NVIDIA GPUs
					</li>
					<li>Motion and DNN-based object detection/classification/tracking technology</li>
					<li>Ability to solve vision-AI problems accumulated through various experiences</li>
					<li>Variety of predefined event detection technology</li>
					<li>Pre/post processing algorithm technology for video analytics</li>
					<li>API for video analytics metadata that can be used externally</li>
					<li>
						High-performance vision-AI technology verified through accredited institution certification - Korea Internet
						& Security Association (KISA) 1st Intelligent CCTV Quality Certificate
					</li>
				</ul>
				<strong className="business_tit bsn_martop_80">Reference</strong>
				<div className="cpn_box">
					<img src={findImage("../assets/images/icon/business_05.png")} alt="" />
					<div className="business_box ver_top">
						<strong className="tit_01">Surveillance</strong>
						<strong className="tit_02">Local Government</strong>
						<ul className="small_list">
							{/* 20240221 텍스트 변경  */}
							<li>- Smart City Project (10 +)</li>
							<li className="plus">
								+ Bucheon, Osan, Incheon, Sejong, Busan (Nam-gu, Gangseo), Guri, Yangju, Siheung, Ui government, Goyang
								(Ilsan, Neunggok), Hwaseong-si, Haman-gun
							</li>
							<li>- Smart Monitoring system(30 +)</li>
							<li className="plus">
								+ Suncheon, Suwon, Yongin, Hanam, Hwaseong, Uijeongbu, Ansan, Guri, Siheung, Bucheon, Anyang, Gunpo,
								Cheongju, Osan, Gongju, Goseong, Yangju, Gwangju, Incheon Gyeyang, Busan (Bukgu, Nam-gu, Gangseo-gu,
								Haeundae-gu), Jeonju
							</li>
							<li className="plus">+ Seoul: Guro, Gangseo, Dongjak, Eunpyeong, Geumcheon, Gwanak, Dongdaemun</li>
							<li>- Crime Prevention Project : </li>
							<li className="plus">
								Seongbuk-gu/Yeongdeungpo-gu/Gwangjin-gu/Yongsan-gu/Gangnam-gu/Seodam-gu, Seoul
								Namyangju-si/Seongnam-si/Yongin-si/Siheung-si/Suwon-si/Icheon-si/Ansung-si, Gyeonggi-do Yeongju-si,
								Gyeongsangbuk-do / Yangsan-si / Miryang-si / Jinju-si / Geoje-si / Miryang-si / Changnyeong-gun /
								Geochang-gun
							</li>
							<li className="plus">Ulju-gun, Ulsan / Jeonju-si, Jeollabuk-do / Seosan-si, Chungnam / Taean-gun</li>
						</ul>
						<strong className="tit_02">National Heriatage</strong>
						<ul className="small_list">
							{/* 20240221 텍스트 추가 */}
							<li>- Intelligent Video Analysis Surveillance System for President Park Chung-hee's Birthplace</li>
							<li>- Environmental Improvement of Cultural Heritage Administration Archives</li>
							<li>- 4 Great Palaces and the Royal Tombs of Joseon Dynasty Full HD quality improvements (2017~)</li>
							<li>- 4 Great Palaces and Jongmyo : CCTV quality improvement and maintainance (2014~ , 1200CH)</li>
							<li>- Sungnyemun CCTV system establishment and maintenance (2013~)</li>
							<li>- Royal Tombs of the Joseon Dynasty : Total Security Guard system (2010~, 1400CH, 24 tombs)</li>
						</ul>
						<strong className="tit_02">Military</strong>
						<ul className="small_list">
							<li>- Smart military system (2021)</li>
							<li>- Seosan Air force Airfield protective facilities (2018)</li> {/*230224 HJ 위치 수정 */}
							<li>- Important military facilities deployment (2017~)</li>
							<li>- GOP mid-west intelligent CCTV system (2014~2015)</li>
							<li>- Presidential Office intelligent CCTV system (2012~)</li> {/*230224 HJ 위치 수정 */}
						</ul>
						<strong className="tit_02">Public</strong>
						<ul className="small_list">
							{/* 20240221 텍스트 추가 */}
							<li>
								- Ministry of Land, Infrastructure and Transport, Korea Land Safety Management Agency, Korea
								Occupational Safety and Health Agency, Seoul Digital Foundation Workplace safety solution project
								Gangwon Fire Headquarters Bridge Suicide Prevention Solution
							</li>
							<li>- Korea Hydro &amp; Nuclear Power Radioactivity TLD Detection System</li>
							<li>- Construction of Intelligent Image Analysis of Seoul Transportation Corporation Line 2</li>
							<li>- Incheon Airport Flight Attendant Automatic Immigration Checkpoint</li>
							<li>
								- The National Natural Recreation Forest Intelligence Service in the National Natural Recreation Area
							</li>
							<li>- Intelligent Image Analysis for PyeongChang Winter Olympics</li>
							<li>- Gangwon Land Disaster Safety Solution</li>
							<li>- public wastewater treatment facilities in Pyeongtaek Brain City</li>
							<li>- Establishment of Integrated Security System for Flower Distribution Center in Goyang City</li>
							<li>- Ansan City Public Parking Lot and Daegu Youth Training Center Parking Lot Modernization Project</li>
							<li>- performance improvement of illegal parking enforcement cameras in Suwon City</li>
							<li>
								- stablishment of an intelligent video surveillance system for elementary, middle and high schools in
								Chungnam Office of Education
							</li>

							<li>- Gangwon Fire headquarters suicide prevention system (2022)</li>
							<li>- Seoul Metro Station video analytics system (2019~2022)</li>
							<li>
								- KHNP, Seoulmetro, Incheon Airport, National Recreation Forest Management Office, Korea Gas Coporation,
								Kangwon Land
							</li>
							<li>- Pyeongchang Winter Olympics : Smart Video Analytics, 400CH supply (2018)</li>
							<li>- Busan Port Authority/ Gamcheon Port / Sin Port / International termial Video Analytics Solution</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="Eproduct_panel blue_back" ref={ref120b}>
				<div className="sub_con">
					<div className="product_box clear">
						<strong className="business_tit">Product</strong>
						<div className="product_txt w_half">
							<strong>120B(AI Event Detection)</strong>
							<p>
								It is an intelligent video monitoring software that can automatically detect and track moving objects
								(person, vehicle, etc.) in the video and then detect defined events.
								<br />
								It not only provides motion-based and deep learning-based object detection and tracking that can improve
								analytics but also analyzes and provides recognized pedestrian properties and vehicle types in real
								time.
							</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage("../assets/images/product_img01.png")} alt="" />
						</div>
					</div>

					<strong className="business_tit">Features</strong>
					<div className="clear">
						<ul className="business_dot type2">
							<li>
								<span>Motion/DCNN based object detection and classification</span>
								<p>- Object(person, vehicle)</p>
								<p>- Overhead view(person)</p>
							</li>
							<li>Visual object tracking technology</li>
							<li>Long-distance object detection</li>
						</ul>
						<ul className="business_dot type2">
							<li>
								<span>Video management/PTZ control</span>
								<p>- CCTV video input: RTSP/ONVIF format support</p>
								<p>- Counting, heat map, dwell display</p>
								<p>- Video recording/search/playback/export support</p>
								<p>- PTZ camera control: joystick, point and click</p>
							</li>
						</ul>
					</div>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit">Main Event</strong>
						<ul className="event_list">
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_01.png")} alt="" />
									<span>Loitering</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_02.png")} alt="" />
									<span>Path passing</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_03.png")} alt="" />
									<span>directional moving</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_04.png")} alt="" />
									<span>abandoned</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_05.png")} alt="" />
									<span>smoke</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_06.png")} alt="" />
									<span>falling</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_07.png")} alt="" />
									<span>dwell time</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_08.png")} alt="" />
									<span>crowding</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_09.png")} alt="" />
									<span>violence</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_10.png")} alt="" />
									<span>flame</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="Eproduct_panel blue_back" ref={ref220b}>
				<div className="sub_con">
					<div className="product_box clear">
						<div className="product_txt w_half">
							<strong>220B(PTZ Tracking)</strong>
							<p>
								It provides automatic PTZ tracking that detects objects from the master camera through a combination of
								a master camera (fixed camera) and a slave camera (PTZ camera) and tracks objects detected through a
								linkedd slave camera. It uses the optical zoom of the PTZ camera to provide an enlarged image of the
								detected object in real time.
							</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage("../assets/images/product_img02.png")} alt="" />
						</div>
					</div>

					<strong className="business_tit">Features</strong>
					<div className="clear">
						<ul className="business_dot type2">
							<li>Auto PTZ tracking based M/S</li>
							<li>
								<span>Link M master cameras and N slave cameras</span>
								<p>- Master camera: fixed camera, event detection</p>
								<p>- Slave camera: PTZ camera, tracking function </p>
							</li>
							<li>
								<span>Zoom-in video provided in real-time by slave camera</span>
								<p>- Own intelligent vector tracking technology</p>
								<p>- Smooth and precise auto tracking through full duplex with PTZ camera</p>
							</li>
						</ul>
						<ul className="business_dot type2">
							<li>M/S camera calibration function based on the video(patent technology)</li>
							<li>Camera auto calibration support</li>
						</ul>
					</div>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit">Main Event</strong>
						<ul className="event_list">
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_01.png")} alt="" />
									<span>Loitering</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_02.png")} alt="" />
									<span>Path passing</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_03.png")} alt="" />
									<span>directional moving</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_04.png")} alt="" />
									<span>abandoned</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_05.png")} alt="" />
									<span>smoke</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_06.png")} alt="" />
									<span>falling</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_07.png")} alt="" />
									<span>dwell time</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_08.png")} alt="" />
									<span>crowding</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_09.png")} alt="" />
									<span>violence</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_10.png")} alt="" />
									<span>flame</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VideoSurveliance
