import React, {useEffect, useState} from 'react'
import NoticeApi from '../../../../Api/NoticeApi'

const DetailNotice = (props) => {
	const [data, setData] = useState({})

	useEffect(()=> {
		NoticeApi.detailNotice(props.detail)
			.then(res => {
				setData(res.data.data)
			})
	},[props.detail])

	const deleteNotice = () => {
		let no = [props.detail]
		NoticeApi.deleteNotice(no)
			.then(()=> {
				props.setWriteMode(0)
			})
	}

	return (
		<div className="table_wrap">
			<table className="type2 notice_detail layout_fixed">
				<colgroup>
					<col width="259px" />
						<col width="*" />
							<col width="259px" />
								<col width="*" />
				</colgroup>
				<tbody>
				<tr>
					<th>등록일</th>
					<td>{data.createDate}</td>
					<th>등록자</th>
					<td>{data.createAdmin}</td>
				</tr>
				<tr>
					<th>제목</th>
					<td colSpan="3">{data.title}</td>
				</tr>
				<tr className="h_631">
					<th>내용</th>
					<td colSpan="3">
						<div className="h_full">
							<iframe title ="공지사항 상세" srcDoc={data.content} style={{width:'100%', height:'100%', border:'0px', top:'0px',left:'0px'}}></iframe>
						</div>
					</td>
				</tr>
				</tbody>
			</table>

			<div className="btn_center martop_20">
				<button type="button" className="blue_btn w_100" onClick={()=> {props.setWriteMode(3)
					props.setUpdateNumber(props.detail)
				}}>수정</button>
				<button type="button" className="white_btn w_100" onClick={()=>deleteNotice()}>삭제</button>
				<button type="button" className="white_btn w_100" onClick={()=>props.setWriteMode(0)}>목록으로</button>
			</div>
		</div>
	)
}

export default DetailNotice
