import React, {useEffect, useState} from 'react'

const useList = (List, selectNo, writeMode, count, page, allChk) => {

	const [checkList, setCheckList] = useState([])


	useEffect(() => {
		if (window.jQuery("[id^='inpChk']").length > 0) {
			window.jQuery("[id^='inpChk']").map(item => {
				window.jQuery("[id^='inpChk']")[item].checked = allChk
			})
			allChk ? setCheckList(checkList.concat(List.map(item => (item.no)))) : setCheckList([])
		}
	}, [allChk])




	const checkHandler = (no, e) => {
		e.target.checked ? setCheckList(checkList.concat(no)) : setCheckList(checkList.filter(item => item !== no))
	}

 return (
	 [List.map((item, index) => (
		 <tr key={item.no}>
			 <td>
				 <input type="checkbox" name="inpChk" className="ch_check hide" id={`inpChk${item.no}`} onChange={(e)=>checkHandler(item.no, e)} />
				 <label htmlFor={`inpChk${item.no}`} className="label single" />
			 </td>
			 <td>{count - (page * 10 + index)}</td>
			 <td>{item.createDate}</td>
			 <td className="ellipsis"><button onClick={()=>{selectNo(item.no)
				 writeMode(2)
			 }}>{item.title}</button></td>
			 <td>{item.createAdmin}</td>
		 </tr>
		 )
	 ), checkList]

 )
}

export default useList
