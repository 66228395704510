import CommonAxios from 'Axios/CommonAxios'

export default {
	searchList (params) {
		return CommonAxios(({
			url: '/api/procurement/search/list',
			method: 'GET',
			params
		}))
	},

	deletePro (data) {
		return CommonAxios(({
			url: 'api/procurement/delete',
			method: 'DELETE',
			data
		}))
	},

	insertPro (data) {
		return CommonAxios(({
			url: 'api/procurement/insert',
			method: 'POST',
			data
		}))
	},
	detailPro (params) {
		return CommonAxios(({
			url: 'api/procurement/detail',
			method: 'GET',
			params
		}))
	},

	getList () {
		return CommonAxios(({
			url: 'api/procurement/list',
			method: 'GET'
		}))
	}
}
