import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const BI = (props) => {

	const { t, i18n } = useTranslation()

	const lang = i18n.language === 'ko'

	const findImage = (path) => {
		let imgArray = path.split('images/')
		let img = imgArray[imgArray.length - 1]

		return require(`MainAssets/assets/images/${img}`)
	}

	const ref220f = useRef()

	useEffect(()=> {
		if (props.hash && ref220f.current) {
			let location = ref220f.current.offsetTop
			window.scrollTo({top: location})
		}
	},[])

	return lang ? (
		<div id="business_panel4" className="business_panel">
			<div className="business_img img4">
				<div className="business_txt">
					<strong>BI</strong>
					<p>매장의 경영전략에 필요한 다양한 영상을 분석하여 최적의 의사결정을 내리는데 도움을 줄 수 있는 bi솔루션을 제공합니다</p>
				</div>
			</div>
			<div className="sub_con">
				<strong className="business_tit">왜 필요할까요?</strong>
				<p className="business_use">오프라인 매장을 이용하는 소비자의 요구가 다양화되면서 출시 및 진열된 제품의 고객 반응 정보를 고객의 이동 동선이나, 진열 상품 내 체류 시간 등을
					분석하여 최적의 의사결정을 수립이 필요합니다. <span className="highlighter">무인 점포의 증가에 따라 매장 고도화를 위한 다양한 비전 AI 기술에 대한 수요는 증가하고 있습니다.</span>
				</p>
				<div className="bsn_martop_80">
					<strong className="business_tit">이렇게 활용할 수 있습니다.</strong>
					<ul className="business_dot">
						<li>프렌차이즈 매장, 전국구 직영 매장, 백화점, 쇼룸 등에서의 고객 이동 정보 확보</li>
						<li>출시 및 진열 제품들의 고객 반응을 분석하여 매장 내 프로모션 전략 강화에 활용</li>
						<li>얼굴 인식을 활용한 비대면 결제 솔루션 응용</li>
					</ul>
				</div>
				<div className="bsn_martop_80">
					<strong className="business_tit bsn_martop_80">구축 사이트</strong>
					<div className="clear">
						<ul className="business_dot type2">
							<li>
								<span>하나은행 BI 솔루션 구축 (2021년)</span>
								<p>- 하나은행 삼선교 지점 솔루션 구축</p>
								<p>- 하나은행 강남역 지점 솔루션 구축</p>
							</li>
							<li>
								<span>LGU+ Business Intelligence (2020년~)</span>
								{/*230130 HJ 내용 추가*/}
								<p>- LGU+ 대전매장 BI 솔루션 구축</p>
								<p>- LGU+ 부산매장 BI 솔루션 구축</p>
								<p>- LGU+ 플래그십 강남매장 BI 솔루션 구축</p>
								<p>- LGU+ 종각매장 BI 솔루션 구축</p>
								<p>- LGU+ 대구매장 BI 솔루션 구축</p>
								<p>- LGU+ 광주매장 BI 솔루션 구축</p>
							</li>
						</ul>
						<ul className="business_dot type2">
							<li>
								<span>고양 현대모터스튜디오 지능형 영상분석 구축 (2017년)</span>
								<p>- 일산 고양 현대모터스튜디오 B3~3층 구축</p>
							</li>
							{/*230130 HJ 내용 추가*/}
							<li className="btm_1rem">
								<span>인터넷진흥원 무인매장 대상 보급형 보안솔루션 확산사업</span>
							</li>
							<li className="btm_1rem">
								<span>신한은행 AI 이상행동탐지 ATM 솔루션 공급</span>
							</li>
							<li className="bsn_btmimg">
								<div>
									<img src={findImage('../assets/images/img_bsn04.png')} alt=""/>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="product_panel blue_back" ref={ref220f}>
				<div className="sub_con">
					<div className="product_box clear">
						<strong className="business_tit">주요 제품</strong>
						<div className="product_txt w_half">
							<strong>220F (AI 얼굴인식)</strong>
							<p>CCTV 카메라를 이용하여 얼굴을 감지하고 추적한 후 나이 예측, 성별 판별, 표정 인식, 얼굴 인식을 수행합니다.</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage('../assets/images/product_img06.png')} alt=""/>
						</div>
					</div>

					<strong className="business_tit">주요 특징</strong>
					<div className="clear">
						<ul className="business_dot type2 li_marbtm20">
							<li>실시간 얼굴 검출 /인식 / 추적</li>
							<li>성별 판별(남/여) / 나이 예측(연령대) / 주목 시간 추정</li>
							<li>얼굴 분석결과 기반 통계 리스트 실시간 표출</li>
							<li>
								{/*230130 HJ 텍스트수정('얼굴 인식(FRS 연동 필요)' -> '얼굴 인식')*/}
								<span>얼굴 인식</span>
								<p>- 관심 인물 등록 및 인식 기능</p>
								<p>- Rank-5 표출 (인물 유사도 표출)</p>
							</li>
						</ul>
						<ul className="business_dot type2 li_marbtm20">
							<li>얼굴 등록 : Whitelist / Blacklist</li>
							<li>관심 인물 등록 및 자동 감지, 화면 내 리스트 표출</li>
							{/*230130 HJ 텍스트 삭제*/}
							{/*<li>얼굴 분석 결과의 통계 리포팅 실시간 표출</li>*/}
						</ul>
					</div>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit">대표 이벤트</strong>
						<ul className="event_list">
							<li>
								<div>
									<img src={findImage('../assets/images/icon/event_14.png')} alt=""/>
									<span>얼굴인식</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div id="Ebusiness_panel4" className="Ebusiness_panel">
			<div className="business_img img4">
				<div className="business_txt">
					<strong>Business Intelligence</strong>
					<p>BI solution analyzes, collects, and delivers the number of customers visiting the store in real time
						through CCTV.<br/>Through this, various meaningful statistical data can be created to help our
						customers.<br/>Accurate human information can be obtained with the development of Vision-AI technology and
						this information that is necessary for customer store operation that can be collected, processed, and used
						as an important tool for decision-making.</p>
				</div>
			</div>
			<div className="sub_con">
				<strong className="business_tit">Background</strong>
				<ul className="business_dot">
					<li>Need to reflect to diversification and advancement of consumer needs</li>
					<li>Needs for indicators that can maximize profits by collecting data basis for establishing management
						strategies
					</li>
					<li>Need information to help you make optimal decisions</li>
				</ul>
				<strong className="business_tit">Necessity</strong>
				<ul className="business_dot">
					<li>It is necessary to secure information on customer movement at franchise stores, department stores,
						supermarkets, etc.
					</li>
					<li>Need customer response information of products released and displayed</li>
				</ul>
				<strong className="business_tit">Solution Competitiveness</strong>
				<ul className="business_dot marbtm_100">
					<li>Accurate human detection and tracking technologies in a diagonal view and an overhead view</li>
					<li>Stable continuous object tracking technology</li>
					<li>Multiple camera and map-based object tracking technologies</li>
				</ul>
				<strong className="business_tit">Reference</strong>
				<div className="cpn_box">
					<img src={findImage('../assets/images/icon/business_02.png')} alt=""/>
					<div className="business_box">
						<strong className="tit_01">BI(Business Intelligence)</strong>
						{/*230224 HJ 컨텐츠 수정 및 추가 */}
						<ul className="small_list">
							<li>- Video analytics solution for Unmanned Store (KISA)</li>
							<li>- Shinhan Bank AI ATM Voice Phising Detection system</li>
							<li>- Hana Bank Business Intelligence (2021)<br/>Hana Bank Samsyeongyo / Gangnam Station center</li>
							<li>
								- LG U+ Business Intelligence (2020~)
								<br/>LGU+ Flagship Gangnam, Jongak, Daegu, Gwangju, Daejeon, Busan store
							</li>
							<li>- Hyundai Motor Studio Intelligent Video analytics system(2017)<br/>Ilsan-Goyang Hyundai motor studio B3-3</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="Eproduct_panel blue_back" ref={ref220f}>
				<div className="sub_con">
					<div className="product_box clear">
						<strong className="business_tit">Product</strong>
						<div className="product_txt w_half">
							<strong>220F(Face Recognition)</strong>
							<p>Perform the age prediction, gender distinction, facial expression recognition and face recognition
								after detecting and tracking faces using CCTV cameras.</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage('../assets/images/product_img06.png')} alt=""/>
						</div>
					</div>

					<strong className="business_tit">Features</strong>
					<div className="clear">
						<ul className="business_dot type2 li_marbtm20">
							<li>Face extraction / recognition / tracking in real time</li>
							<li>Gender distinction(male/female) / age prediction / attention time estimation</li>
							<li>Display the statistic list in real time based on the result of face analytics</li>
							<li>
								<span>Face recognition</span> {/*230224 HJ 텍스트 삭제('(FRS needs)') */}
								<p>- Person of interest registration and recognition function</p>
								<p>- Rank-5 display (similarity display)</p>
							</li>
						</ul>
						<ul className="business_dot type2 li_marbtm20">
							<li>Face registration</li>
							<li>Person of interest registration and auto detection, display the list</li>
							{/*<li>Display the statistic report of face analytics result in real time</li>*/} {/*230224 HJ 텍스트 삭제 */}
						</ul>
					</div>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit">Main Event</strong>
						<ul className="event_list">
							<li>
								<div>
									<img src={findImage('../assets/images/icon/event_14.png')} alt=""/>
									<span>Face recognition</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BI
