import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const IndustrialSafety = (props) => {
	const { t, i18n } = useTranslation()

	const lang = i18n.language === 'ko'

	const ref220s = useRef()

	const findImage = (path) => {
		let imgArray = path.split('images/')
		let img = imgArray[imgArray.length - 1]

		return require(`MainAssets/assets/images/${img}`)
	}

	useEffect(() => {
		if (props.hash && ref220s.current) {
			let location = ref220s.current.offsetTop
			window.scrollTo({ top: location })
		}
	}, [])

	return lang ? (
		<div id="business_panel2" className="business_panel">
			<div className="business_img img2">
				<div className="business_txt">
					<strong>산업 안전</strong>
					<p>산업/건설 현장에서 가장 중요한 가치는 '안전'입니다. AI Vision 기술을 기반으로 현장에서 발생하는 각종 안전 사고 징후 및 사고를 빠르게 인식하여 대응을 통해 안전 사고 제로를
						실현할 수 있는 다양한 솔루션을 제공합니다.</p>
				</div>
			</div>
			<div className="sub_con">
				<strong className="business_tit">왜 필요할까요?</strong>
				<p className="business_use">산업현장의 특성 상 인명사고가 일어날 가능성이 크기 때문에 현장 사고를 예방하기 위한 관련 비용은 매년 증가하고 있습니다.<br/><span
					className="highlighter">한정적인 안전 관리자를 AI Vision 기술로 보조하며 안전 사고 발생 시 이벤트 알림을 통해 위기 상황을 빈틈없이 관리하고 현장 대응성을 높여 골든 타임</span>을
					확보할 수 있도록 지원합니다.</p>
				<div className="bsn_martop_80">
					<strong className="business_tit">이렇게 활용할 수 있습니다.</strong>
					<ul className="business_dot">
						<li>현장 내 작업자 안전 보호를 위한 최적화된 솔루션</li>
						<li>야간 시간대 대형 시설 위해요소(화재, 연기) 감시</li>
						<li>중장비 작업 반경 내 사람 보호</li>
					</ul>
				</div>
				<div className="bsn_martop_80">
					<strong className="business_tit bsn_martop_80">구축 사이트</strong>
					<div className="clear">
						<ul className="business_dot type2 mar_0">
							{/* 20240221 텍스트 추가 및 순서 변경 */}
							{/* 2023년도  */}
                            <li>KPX케미칼 공장 위험성 추적 영상분석 시스템 구축(2023년)</li>
                            <li>LGCNS 데이터센터 배터리실 화재감시 구축 (가산, 부산, 상암, 인천, 하남)(2023년)</li>
                            <li>LG디스플레이 AI지능형 솔루션 구축 (구미, 파주)(2023년)</li>
                            <li>LG전자 창원 고위험 AI 지능형 CCTV 영상분석시스템(2023년)</li>
                            <li>LG전자 창원 스마트파크2 AI 영상분석시스템 구축(2023년)</li>
                            <li>LG전자 창원 물류 AI CCTV 영상분석시스템(2023년)</li>
                            <li>LG이노텍 광주 안전환경팀 지능형 CCTV(2023년)</li>
                            <li>LG에너지솔루션 AI Safety 구축 (대전기술원, 폴란드, 중국난징)(2023년)</li>
                            <li>SK에코플랜트 컨베이어 협착 방지 시스템 구축 (용인반도체클러스터 수직구/단지조성 AI크라샤, GTX-A 13공구, 시화MTV현장)(2023년)</li>
                            <li>국토안전관리원(수도권, 강원, 중부, 호남지사) 스마트 안전장비 지원 사업(2023년)</li>
                            <li>국토교통부(대전청) 스마트 안전장비 제작 및 설치(물품구매)(2023년)</li>
                            <li>경상남도 건축공사장 스마트 안전장비 임차 및 운영관리(2023년)</li>
                            <li>루시아 청담 현장 CCTV 시스템 설치공사 및 영상분석 시스템 도입(2023년)</li>
                            <li>베트남 하노이 "스마트 안전 체험장 구축" 영상분석 시스템 구축(2023년)</li>
                            <li>삼성물산 지능형 영상분석 시스템 구축 (기흥현장, 서소문현장)(2023년)</li>
                            <li>삼성엔지니어링 사우디 현장 지능형 영상분석 시스템 구축(2023년)</li>
                            <li>베올리아 강원도 원주 현장 지능형 영상분석 도입(2023년)</li>
                            <li>GS칼텍스 안전개선목적 AI CCTV(2023년)</li>
                            <li>울산산재병원 안전관리 모니터링 시스템 구매(2023년)</li>
                            <li>반도건설 현장 이동형 CCTV 지능형 영상분석 시스템(2023년)</li>
                            <li>한국도로공사 전북본부 이동형 CCTV 지능형 영상분석 시스템 도입(2023년)</li>
                            <li>금강수도사업단 산업안전 지능형 영상분석 시스템(2023년)</li>
                            <li>진해문화센터 건립공사 지능형 영상분석 시스템 구축(2023년)</li>
                            <li>포스코 HY클린메탈 차세대재해예방플랫폼 테스트베드 구축(2023년)</li>
                            <li>포스코 외각 출입차량 번호인식 솔루션(2023년)</li>
                            <li>포스코홀딩스 지능형 이동형CCTV (AI-BOX타입)(2023년)</li>
                            <li>한국농어촌공사 지능형 영상분석 시스템 구축(2023년)</li>
                            <li>한국바스프 울산/화성공장 지능형 CCTV구축 사업(2023년)</li>
                            <li>한림기계 산업안전 지능형 영상분석 솔루션 구축(2023년)</li>
                            <li>현대자동차 강남빌딩 지능형영상분석 구축(2023년)</li>
                            <li>깨끗한나라 청주공장 안전환경 모니터링시스템(2023년)</li>
                             {/* 2022년도  */}
                            <li>국토안전관리원(수도권, 강원, 중부, 호남지사) 스마트 안전장비 지원 사업(2022년)</li>
                            <li>국토교통부(서울청, 대전청) 스마트 안전장비 지원 사업(2022년)</li>
                            <li>서울디지털재단 시각지능 기반 중소규모 건축공사장 위험요소 관제 용역(2022년)</li>
						</ul>
						<ul className="business_dot type2 mar_0">
							{/* 20240221 텍스트 추가 및 순서 변경 */}
							<li>LG화학 석유화학 지능형 CCTV기반 영상 분석 체계 구축(2022년)</li>
                            <li>SK에코플랜트 AI 컨베이어 협착 방지 시스템 구축(2022년)</li>
                            <li>삼성물산 화성현장 AI기반 안전관리 시스템 구축(2022년)</li>
                            <li>한화솔루션 케미칼부문 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>코오롱글로벌 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>현대엘리베이터 스마트 방재시스템 구축(2022년)</li>
                            <li>GS파워 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>LG생활건강(청주) 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>LG이노텍(베트남 법인) 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>LG에너지 솔루션(오창) 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>LGCNS 상암클라우드센터 지능형 화재감지 솔루션 구축(2022년)</li>
                            <li>LG전자 창원공장 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>LG전자 폴란드 AI Safety솔루션 구축(2022년)</li>
                            <li>LG디스플레이 파주공장 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>티케이지 휴켐스 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>흥화건설 지능형 영상분석 시스템 구축(2022년)</li>
                            <li>계룡건설(한국은행 현장) 지능형 영상분석 시스템 구축(2022년) </li>
                            {/* <li>S&amp;I 코퍼레이션 산업안전 솔루션 구축(2021년)</li> */}
                            <li>LG디스플레이 파주 P10공구 산업안전 솔루션 구축(S&amp;I)(2021년)</li>
                            <li>DL이앤씨 현장통합관리 플랫폼 구축(2021년)</li>
                            <li>기아자동차(소하, 화성, 광주) 중대재해 예방 시스템 구축(2021년)</li>
                            <li>LG디스플레이, LG전자, LG이노텍 공장안전 솔루션 공급(2021년)</li>
                            <li>인천공항 건설안전 솔루션 공급(2021년)</li>
                            <li>현대제철 공장안전 솔루션 공급(2021년)</li>
                            <li>국토안전관리원 건설안전 12개 시범사업 공급(2021년)</li>
                            {/* <li>월드컵대교 산업안전 솔루션 공급(2021년)</li> */}
                            <li>한국철도공사 지능형 승강장 사업(2021년)</li>
                            <li>한국수력원자력 양양 양수 발전소 공장안전 솔루션 공급(2021년)</li>
                            <li>GS건설 산업안전 솔루션 공급(2020년)</li>
                            <li>LG화학 대산공장 지능형 영상 분석 시스템 구축(2020년)</li>
                            <li>삼천리 노출배관 상시 모니터링 시스템 구축(2020년)</li>
                            <li>삼성중공업 지능형 영상분석 솔루션 도입(2020년)</li>
                            <li>포스코(포항) 선박화재 감시 시스템 구축(2020년)</li>
                            <li>한국조선해양 지능형 영상분석 시스템 시범 구축 사업(2020년)</li>
                            {/* <li>삼성물산 산업안전솔루션 시스템 구축(2020년)</li>  */}
                            {/* <li>삼성엔지니어링 탕정종합상황실 시스템 구축(2020년)</li>  */}
                            <li>삼성디스플레이 탕정종합상황실 시스템 구축(삼성물산/삼성엔지니어링)(2020년)</li>
                            <li>한화토탈 산업안전 솔루션 구축(2019년)</li>
                            <li>SK에너지 주유소 차량 추적 솔루션 공급(2019년)</li>
						</ul>
						{/*<ul className="business_dot type2 mar_0">*/}
						{/*	<li>한화토탈 산업안전 솔루션 구축(2019년)</li>*/}
						{/*	<li>LG디스플레이, LG전자, LG이노텍 공장안전 솔루션 공급(2021년)</li>*/}
						{/*	<li>인천공항 건설안전 솔루션 공급(2021년)</li>*/}
						{/*	<li>현대제철 공장안전 솔루션 공급(2021년)</li>*/}
						{/*	<li>국토안전관리원 건설안전 12개 시범사업 공급(2021년)</li>*/}
						{/*	/!*2022 10 27 삭제*!/*/}
						{/*	/!*<li>월드컵대교 산업안전 솔루션 공급(2021년)</li>*!/*/}
						{/*	<li>한국철도공사 지능형 승강장 사업(2021년)</li>*/}
						{/*	<li>양양 양수 발전소 공장안전 솔루션 공급(2021년)</li>*/}
						{/*	<li>GS건설 산업안전 솔루션 공급(2020년)</li>*/}
						{/*	<li>SK에너지 주유소 차량 추적 솔루션 공급(2019년)</li>*/}
						{/*</ul>*/}
					</div>
				</div>
			</div>


			<div className="product_panel blue_back" ref={ref220s}>
				<div className="sub_con">
					<div className="product_box clear">
						<strong className="business_tit">주요 제품</strong>
						<div className="product_txt w_half">
							<strong>220S (AI 산업안전)</strong>
							<p>CCTV와 같은 카메라 장치에서 획득한 영상 데이터를 기반으로 AI 딥러닝 알고리즘을 적용하여 건설현장에서 효율적인 안전 관리를 위한 영상 데이터를 다양하게 활용할 수
								있습니다.</p>
						</div>
						<div className="product_imgBox w_half">
							<img src={findImage('../assets/images/product_img03.png')} alt=""/>
						</div>
					</div>

					<strong className="business_tit">주요 특징</strong>
					<div className="clear">
						<ul className="business_dot type2">
							<li>
								<span>안전장비 미착용 감지</span>
								<p>- 안전모, 안전화, 하네스, 안전고리 등</p>
							</li>
							<li>
								<span>긴급 상황 감지</span>
								<p>- 작업장 내 작업자 쓰러짐, 달리기, SOS인식과 같은 비정상적 행동 인식</p>
							</li>
							<li>
								<span>위험지역 침입 감지</span>
								<p>- 중장비 작업 반경 이내 작업자 또는 차량 진입 시 알람 발생</p>
								<p>- 개구부 낙상, 중장비 근접, 출입통제구역 침입 시 긴급 대처</p>
							</li>
						</ul>
						<ul className="business_dot type2">
							<li>
								<span>위험상황 감지</span>
								<p>- 화재, 위험물질 누수(스팀, 액체), 특정 시설 온도 등을 통해 위험상황 알림</p>
							</li>
							<li>
								<span>이동형CCTV</span>
								<p>- 이동형CCTV 영상분석</p>
								<p>- LTE 통신으로 서버와 통신 실시간 분석 알람</p>
								<p>- 사각 지역 탐지 및 스피커 및 경광등으로 위험 상황 즉시 알림</p>
								<p>- 불꽃 감지 센서와의 연동으로 감지율 향상</p>
							</li>
						</ul>
					</div>
				</div>
				<div className="event_box">
					<div className="sub_con">
						<strong className="event_tit">대표 이벤트</strong>
						<ul className="event_list">
							<li>
								<div>
									<img src={findImage('../assets/images/icon/event_10.png')} alt=""/>
									<span>연기/불꽃</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage('../assets/images/icon/event_06.png')} alt=""/>
									<span>작업자 쓰러짐</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage('../assets/images/icon/event_11.png')} alt=""/>
									<span>구조 신호</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage('../assets/images/icon/event_12.png')} alt=""/>
									<span>중장비 인식</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage('../assets/images/icon/event_03.png')} alt=""/>
									<span>위험지역 침입</span>
								</div>
							</li>
							<li>
								<div>
									<img src={findImage('../assets/images/icon/event_13.png')} alt=""/>
									<span>안전장비 착용<span className="dis_block">(안전모, 안전조끼)</span></span>
								</div>
							</li>
							{/*230130 HJ 중장비 이벤트 추가*/}
							<li>
								<div>
									<img src={findImage("../assets/images/icon/event_16.png")} alt=""/>
										<span>중장비 협착</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	) : (
		<>
			<div id="Ebusiness_panel2" className="Ebusiness_panel">
				<div className="business_img img2">
					<div className="business_txt">
						<strong>Industrial Safety</strong>
						<p>The most important value in industrial/construction sites is 'safety'. In order to prevent safety
							accidents that may occur at various industrial sites, IntelliVIX provides solutions to prevent accidents
							by detecting situations such as wearing personal protective equipment, dangerous areas, and access to
							vehicles in real time CCTV video. IntelliVIX is realizing a safe industrial/construction site by quickly
							detecting and transmitting various signs and accidents occurring at the site so that the central control
							center can respond immediately.</p>
					</div>
				</div>
				<div className="sub_con">
					<div className="clear">
						<div className="left w_half m_wfull">
							<strong className="business_tit">Background</strong>
							<ul className="business_dot">
								<li>Demand for industrial safety solutions based on Vision-AI technology has increased recently</li>
								<li>Increase in related costs to prevent on-site safety accidents</li>
								<li>Limited number of safety managers</li>
								<li>Rapid response within peak time when the accident occurs</li>
							</ul>
						</div>
						<div className="left w_half m_wfull">
							<strong className="business_tit Mmartop_40">Necessity</strong>
							<ul className="business_dot">
								<li>Detection of various possible hazards in the workplace and prevention of safety accidents</li>
								<li>Assistance to safety management skills</li>
								<li>Speed up on-site response to emergencies</li>
							</ul>
						</div>
					</div>
					<strong className="business_tit bsn_martop_80">Solution Competitiveness</strong>
					<ul className="business_dot marbtm_100">
						<li>Provides personal protective equipment detection: safety helmets, safety vests, and safety clothing</li>
						<li>Smoke/flame events detection, staying people in dangerous areas, access to work vehicles detection and
							action recognition detection are provided
						</li>
						<li>Experienced in building various sites</li>
						<li>Enable to optimize for each sites</li>
					</ul>
					<strong className="business_tit bsn_martop_80">Reference</strong>
					<div className="cpn_box">
						<img src={findImage('../assets/images/icon/business_04.png')} alt=""/>
						<div className="business_box ver_top">
							<strong className="tit_01">Industrial Safety</strong>
							{/*20240221 텍스트 변경 */}
							<div className="clear">
								<div className="left w_half m_wfull">
									<ul className="small_list m_pd0">

										{/* <li class="letter_sp">- Korea authority of Land & Infrastrcuture safety smart protective equipment (2022)</li>
										<li class="letter_sp">- Ministry of Land, Infrastructure and transport smart protective equipment (2022)</li>
										<li>- Seoul Digital foundation smart construction monitoring system (2022)</li>
										<li>- LG Chem, Petrochemistry intelligent video analytics system(2022)</li>
										<li>- Skecoplant Prevention of conveyor constriction system (2022)</li>
										<li>- Samsungcnt AI Safety management system(2022)</li>
										<li>- Hanwha Solutions(Chemical) video analytics system (2022)</li>
										<li>- Kolon Global video analytics system(2022)</li>
										<li>- Hyundai Elevator smart disaster prevention system(2022)</li>
										<li>- GS Power Video analytics system(2022)</li>
										<li>- LG H&H Video analytics system(2022)</li>
										<li>- Lginnotech (Vietnam Branch) video analytics system(2022)</li>
										<li>- LGCNS Sangam Cloud center intelligent fire detection solution (2022)</li>
										<li>- LG Electronics Factory(Changwon) video analytics system(2022)</li>
										<li>- LG Electronics Poland AI Safety Solution (2022)</li>
										<li>- S&I coporation industrial safety system (2021)</li>
										<li>- DL E&C industrial safety system (2021)</li> */}

										<li>- Ministry of Land, Infrastructure and Transport, Korea Land Safety Management Agency, Korea KPX Chemical Plant Risk Tracking Image Analysis System (2023)</li>
										<li>- LGCNS Data Center Battery Room Fire Monitoring (Gasan, Busan, Sangam, Incheon, Hanam) (2023)</li>
										<li>- LG Display AI Intelligent Solution (Gumi, Paju) (2023)</li>
										<li>- LG Electronics Changwon High-Risk AI Intelligent CCTV Image Analysis System (2023)</li>
										<li>- LG Electronics Changwon Smart Park 2 AI Image Analysis System (2023)</li>
										<li>- LG Electronics Changwon Logistics AI CCTV Image Analysis System (2023)</li>
										<li>- LG Innotek Gwangju Safety and Environment Team Intelligent CCTV (2023)</li>
										<li>- LG Energy Solution AI Safety (2023)</li>
										<li>- SK Eco Plant conveyor stenosis prevention system (Yongin Semiconductor Cluster Vertical Zone/ Complex Creation AI Krasha, GTX-A 13, Sihwa MTV Site) (2023)</li>
										<li>- The Korea Land Safety Management Agency (2023) Smart Safety Equipment Support Project (Metropolitan Area, Gangwon, Jungbu, Honam Branch)</li>
										<li>- Ministry of Land, Infrastructure and Transport (Daejeon Office) Smart safety equipment (2023)</li>
										<li>- Gyeongsangnam-do Construction Site Smart Safety Equipment (2023)</li>
										<li>- Lucia Cheongdam CCTV System Installation Construction and Video Analysis System Introduction (2023)</li>
										<li>- "Smart Safety Experience Center" Image Analysis System in Hanoi, Vietnam (2023)</li>
										<li>- Samsung C&T Intelligent Video Analysis System (Giheung Site, Seosomun Site) (2023)</li>
										<li>- Samsung Engineering Saudi Field Intelligent Image Analysis System (2023)</li>
										<li>- Intelligent Image Analysis (2023) in Wonju, Gangwon-do</li>
										<li>- AI CCTV for Safety Improvement of GS Caltex (2023)</li>
										<li>- Ulsan Sanjae Hospital Safety Management Monitoring System (2023)</li>
										<li>- Bando Construction Site Mobile CCTV Intelligent Image Analysis System (2023)</li>
										<li>- Mobile CCTV Intelligent Image Analysis System (2023) at Jeonbuk Headquarters of Korea Expressway Corporation</li>
										<li>- Geumgang Waterworks Group Industrial Safety Intelligent Image Analysis System (2023)</li>
										<li>- An intelligent image analysis system (2023) for the construction of Jinhae Cultural Center</li>
										<li>- POSCO HY Clean Metal Next Generation Disaster Prevention Platform Testbed (2023)</li>
										<li>- POSCO External Vehicle Number Recognition Solution (2023)</li>
										<li>- POSCO Holdings Intelligent Mobile CCTV (AI-BOX Type) (2023)</li>
										<li>- Korea Rural Community Corporation's Intelligent Image Analysis System (2023)</li>
										<li>- Intelligent CCTV (2023) at BASF Ulsan/Hwasong plant in Korea</li>
										<li>- Hallym Machinery Industrial Safety Intelligent Image Analysis Solution (2023)</li>
										<li>- Intelligent Image Analysis of Hyundai Motor's Gangnam Building (2023)</li>
										<li>- Clean Country Cheongju Factory Safety Environment Monitoring System (2023)</li>
										<li>- The Korea Land Safety Management Agency (2022) Smart safety equipment (Seoul Metropolitan Area, Gangwon, Jungbu, Honam Branch)</li>


									</ul>
								</div>
								<div className="left w_half m_wfull">
									<ul className="small_list m_pd0">
										{/*20240221 텍스트 변경 */}
										{/* <li>- KIA Motors industrial safety system (2021)</li>
										<li>- LG Display, LG Electronics, LG innotech Factory Safety system(2021)</li>
										<li>- Incheon International Airport Industrial Safety system(2021)</li>
										<li>- Hyundai steel Industrial safety system (2021)</li>
										<li>- Korea Authority of Land & Infrastructure safety system(2021)</li>
										<li>- Worldcup Bridge industrial safety system(2021)</li>
										<li>- Korail Intelligent Platform intelligent Video analytics system(2021)</li>
										<li>- Yang-Yang, Yangsu Power Plant Industrial Safey(2021)</li>
										<li>- LG Chem. Intelligent video analytics system (2020)</li>
										<li>- Samsung Heavy Industries intelligent video analytics system (2020)</li>
										<li>- POSCO vessel fire detection system (2020)</li>
										<li>- Korea Shipbuilding & Offshore Engineering intelligent video analytics system</li>
										<li>- Samsung C&T industrial safety system (2020)</li>
										<li>- Samsung Engineering intelligent video analytics system (2020)</li>
										<li>- GS E&C Industrial Safety system(2020)</li>
										<li>- Hanhwa-Total industrial safety system (2019)</li>
										<li>- SK Gas Station Vehicle Tracking solution(2019)</li> */}
										<li>- Ministry of Land, Infrastructure and Transport (Seoul, Daejeon) Smart safety equipment (2022)</li>
										<li>- Risk Factors for Small and Medium-Sized Building Construction Sites Based on Visual Intelligence (2022) of the Seoul Digital Foundation</li>
										<li>- LG Chem Petrochemical Intelligent CCTV-based Image Analysis System (2022)</li>
										<li>- SK Eco Plant AI conveyor stenosis prevention system (2022)</li>
										<li>- Samsung C&T's Hwaseong site AI-based safety management system (2022)</li>
										<li>- Intelligent Image Analysis System (2022) in Hanwha Solution Chemical Division</li>
										<li>- Kolon Global Intelligent Image Analysis System (2022)</li>
										<li>- Hyundai Elevator Smart Disaster Prevention System (2022)</li>
										<li>- GS Power Intelligent Image Analysis System (2022)</li>
										<li>- LG Household & Health Care (Cheongju) Intelligent Image Analysis System (2022)</li>
										<li>- LG Innotek (Vietnam Corporation) Intelligent Image Analysis System (2022)</li>
										<li>- LG Energy Solution (Ochang) Intelligent Image Analysis System (2022)</li>
										<li>- LGCNS Sangam Cloud Center Intelligent Fire Detection Solution (2022)</li>
										<li>- LG Electronics' Changwon Plant Intelligent Image Analysis System (2022)</li>
										<li>- LG Electronics Poland AI Safety Solution (2022)</li>
										<li>- LG Display Paju Plant Intelligent Image Analysis System (2022)</li>
										<li>- T.K. Huchems Intelligent Imaging Analysis System (2022)</li>
										<li>- Heunghwa Construction Intelligent Image Analysis System (2022)</li>
										<li>- Gyeryong Construction (Bank of Korea site) Intelligent video analysis system (2022)</li>
										<li>- LG Display Paju P10 Tool Industrial Safety Solution (S&I) (2021)</li>
										<li>- DL E&C Field Integrated Management Platform (2021)</li>
										<li>- Kia Motors Corporation (Soha, Hwaseong, Gwangju) Serious Disaster Prevention System (2021)</li>
										<li>- LG Display, LG Electronics, LG Innotek Factory Safety Solution (2021)</li>
										<li>- Incheon Airport Construction Safety Solution (2021)</li>
										<li>- Hyundai Steel Plant Safety Solution (2021)</li>
										<li>- 12 pilot projects for construction safety by the Korea Land Safety Management Agency (2021)</li>
										<li>- Korea Railroad Corporation's Intelligent Platform (2021)</li>
										<li>- Korea Hydro & Nuclear Power Co.'s Yangyang Pumping Station Factory Safety Solution (2021)</li>
										<li>- GS Engineering & Construction Industrial Safety Solution (2020)</li>
										<li>- LG Chem Daesan Plant Intelligent Image Analysis System (2020)</li>
										<li>- Samchully Exposure Piping Regular Monitoring System (2020)</li>
										<li>- Samsung Heavy Industries Intelligent Video Analysis Solution (2020)</li>
										<li>- POSCO (Pohang) Ship Fire Monitoring System (2020)</li>
										<li>- Korea Shipbuilding & Marine Engineering Intelligent Image Analysis System (2020)</li>
										<li>- Samsung Display Tangjeong General Situation Room System (Samsung C&T/Samsung Engineering) (2020)</li>
										<li>- Hanwha Total Industrial Safety Solution (2019)</li>
										<li>- SK Energy Gas Station Vehicle Tracking Solution (2019)</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="Eproduct_panel blue_back" ref={ref220s}>
					<div className="sub_con">
						<div className="product_box clear">
							<strong className="business_tit">Product</strong>
							<div className="product_txt w_half">
								<strong>220S(Industial Safety)</strong>
								<p>Utilize the video data for efficient safety management at construction sites through the application
									of AI deep learning algorithm based on the video data obtained from camera devices such as CCTVs.</p>
							</div>
							<div className="product_imgBox w_half">
								<img src={findImage('../assets/images/product_img03.png')} alt=""/>
							</div>
						</div>

						<strong className="business_tit">Features</strong>
						<div className="clear">
							<ul className="business_dot type2">
								<li>
									<span>No safety equipment detection</span>
									<p>- Safety helmet, shoes, loop</p>
								</li>
								<li>
									<span>Emergency detection</span>
									<p>- Abnormal action recognition such as falling, running, SOS action in the area</p>
								</li>
								<li>
									<span>Dangerous area intrusion detection</span>
									<p>- Alert the alarm when workers or vehicles enter within the working range of heavy equipment </p>
									<p>- Handle Opening falling, heavy equipment approach, restricted area intrusion </p>
								</li>
							</ul>
							<ul className="business_dot type2">
								<li>
									<span>Danger situation detection</span>
									<p>- Alert the alarm through the fire, dangerous substance and facility temperature</p>
								</li>
								<li>
									<span>Movable CCTV</span>
									<p>- Movable CCTV video analytics</p>
									<p>- Communication with server through LTE , real time analytics alarm</p>
									<p>- Blind spot detection and alert the alarm in dangerous situation by speaker and warning light</p>
									<p>- Improvement of detection rate through the linkage of flame detection sensor</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="event_box">
						<div className="sub_con">
							<strong className="event_tit">Main Event</strong> {/*230224 HJ 텍스트 수정('event' -> 'Event') */}
							<ul className="event_list">
								<li>
									<div>
										<img src={findImage('../assets/images/icon/event_10.png')} alt=""/>
										<span>Smoke/Flame</span>
									</div>
								</li>
								<li>
									<div>
										<img src={findImage('../assets/images/icon/event_06.png')} alt=""/>
										<span>Falling</span>
									</div>
								</li>
								<li>
									<div>
										<img src={findImage('../assets/images/icon/event_11.png')} alt=""/>
										<span>SOS</span>
									</div>
								</li>
								<li>
									<div>
										<img src={findImage('../assets/images/icon/event_12.png')} alt=""/>
										<span>Heavy equipment recognition</span>
									</div>
								</li>
								<li>
									<div>
										<img src={findImage('../assets/images/icon/event_03.png')} alt=""/>
										<span>Dangerous area intrusion</span>
									</div>
								</li>
								<li className="txt_line3">
									<div>
										<img src={findImage('../assets/images/icon/event_13.png')} alt=""/>
										<span>Safety equipment wearing<span className="dis_block">(safety helemet, vest)</span></span>
									</div>
								</li>
								{/*230224 HJ 추가 */}
								<li>
									<div>
										<img src={findImage("../assets/images/icon/event_16.png")} alt=""/>
											<span>Heavy equipment constriction</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>

			</div>
		</>
	)

}

export default IndustrialSafety
