import React, { useState, useRef, useEffect } from 'react'
import StackModal from '../Stack/StackModal'
import NumberUtils from '../../Utils/NumberUtils'
import AdminApi from '../../Api/AdminApi'
import Alert from './Alert'
import NoticeApi from '../../Api/NoticeApi'

const AdminModal = (props) => {

	const nameRef = useRef()
	const [name, setName] = useState('')

	const gradeRef = useRef()
	const [grade, setGrade] = useState(0)

	const phoneRef = useRef()
	const [phone, setPhone] = useState('')

	const idRef = useRef()
	const [id, setId] = useState('')

	const pwdRef = useRef()
	const [pwd, setPwd] = useState('')

	const [disabled, setDisabled] = useState(true)

	const [adminInfo, setAdminInfo] = useState({})

	const emailRegExp = new RegExp(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i)

	const myUpdate = () => {
		let data = {
			no: props.no,
			password: pwd
		}
		AdminApi.modifyAdmin(data).then(res => {
			props.onClose()
		})
	}

	useEffect(() => {
		props.adminNo > -1 && props.detailAdmin && AdminApi.detailAdmin({ no: props.adminNo })
			.then((res) => {
				setAdminInfo(res.data.data)
			})
	}, [props.adminNo])

	const otherUpdate =() => {
		let data = {
			grade,
			password: pwd,
			no: props.adminNo
		}
		AdminApi.modifyAdmin(data).then(res => {
			props.onClose()
		})
	}


	const save = () => {

		if (emailRegExp.test(id)) {
			let data = {
				name,
				grade,
				phone: NumberUtils.mobilePhoneFormatter(phone),
				id: id.split('@')[0],
				password: pwd,
				email: id
			}
			AdminApi.signAdmin(data)
				.then(()=> {
					props.onClose()
				})
		} else {
			window.popupManager.open(Alert, {
				contents: '아이디는 이메일 형식으로<br />입력해주세요.'
			})
		}


	}


	useEffect(() => {

			props.adminNo ? (pwd && (grade !== 0 && grade !== '0')) ? setDisabled(false): setDisabled(true) : props.myInfo ? pwd ? setDisabled(false) : setDisabled(true) : (name && (grade !== 0 && grade !== '0') && phone && id && pwd) ? setDisabled(false) : setDisabled(true)

	}, [name, grade, phone, id, pwd])


	const OPTIONS = [
		{ value: 0, name: '선택' },
		{ value: 1, name: '마스터' },
		{ value: 2, name: '일반' }
	]

	const onChangeHandler = (ref, setState) => {
		setState(ref.current.value)
	}

	return (
		<StackModal {...props}>
			<div className="modal_wrap enroll_modal active">
				<div className="modal_section w_650">
					<div className="modal_header clear">
						<strong className="modal_title left">{props.myInfo ? '관리자 상세' : '관리자 등록'}</strong>
						<button type="button" className="hide_btn close_modal right" onClick={() => props.onClose()}/>
					</div>
					<div className="modal_contents">
						<table className="type2 txt_left1">
							<colgroup>
								<col width="150px"/>
								<col width="*"/>
							</colgroup>
							<tbody>
							<tr>
								<th className="required">이름</th>
								<td>
									{
										props.detailAdmin ? <label>{adminInfo.name}</label> :
											props.myInfo ? <label>{props.name}</label> : <input type="text" className="w_full"
											                                                    placeholder="이름을 입력해주세요."
											                                                    onChange={() => onChangeHandler(nameRef, setName)}
											                                                    ref={nameRef}/>

									}
								</td>
							</tr>
							<tr>
								<th className="required">등급</th>
								<td>

									{props.myInfo ? <label>{props.grade}</label> : <select className="select w_full" onChange={()=> onChangeHandler(gradeRef, setGrade)} ref={gradeRef}>
										{OPTIONS.map(item => (
											<option value={item.value}
											        defaultValue={0}
											        key={item.value}
											>{item.name}</option>
										))}
									</select>}

								</td>
							</tr>
							<tr>
								<th className="required">핸드폰</th>
								<td>
									{props.detailAdmin ? <label>{adminInfo.phone}</label> :
										props.myInfo ? <label>{props.phone}</label> : <input type="number" className="w_full" maxLength={11} onInput={window.checkInputNum}
										                                                     placeholder="핸드폰 번호를 입력해주세요." ref={phoneRef} onChange={()=>onChangeHandler(phoneRef, setPhone)}/>
									}
								</td>
							</tr>
							<tr>
								<th className="required">아이디</th>
								<td>
									{props.detailAdmin ? <label>{adminInfo.id}</label> :
										props.myInfo ? <label>{props.id}</label> : <input type="text" className="w_full"
										                                                  placeholder="아이디를 입력해주세요." ref={idRef} onChange={()=>onChangeHandler(idRef, setId)}/>
									}
								</td>
							</tr>
							<tr>
								<th className="required">비밀번호</th>
								<td>
									<input type="text" className="w_full" placeholder="비밀번호를 입력해주세요." onChange={()=> onChangeHandler(pwdRef, setPwd)} ref={pwdRef}/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div className="btn_wrap">
						<button type="button" className={`blue_btn w_100 ${disabled ? 'disabled' : ''}`} onClick={() => props.myInfo ? myUpdate() : props.adminNo ? otherUpdate() : save()} disabled={disabled}>저장
						</button>
						<button type="button" className="white_btn w_100 close_modal" onClick={() => props.onClose()}>취소</button>
					</div>
				</div>
			</div>
		</StackModal>
	)
}

export default AdminModal
