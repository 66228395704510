import CommonAxios from '../Axios/CommonAxios'

export default {
	postMail (data) {
		return CommonAxios(({
			url: 'api/mail/',
			method: 'POST',
			contentType: 'multipart/form-data',
			data
		}))
	}
}
