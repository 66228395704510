import React,{useEffect, useState} from 'react'
import NoticeApi from '../../../../Api/NoticeApi'
import ProApi from '../../../../Api/ProApi'
import Confirm from '../../../../Modal/Component/Confirm'
import NumberUtils from '../../../../Utils/NumberUtils'
import Alert from '../../../../Modal/Component/Alert'

const DetailProcurement = (props) => {

	const [data, setData] = useState({})

	useEffect(()=> {
		let params = { no: props.detail }
		ProApi.detailPro(params)
			.then(res => {
				setData(res.data.data)
			})
	},[props.detail])

	const deleteNotice = () => {
		let no = [props.detail]
		ProApi.deletePro(no)
			.then(()=> {
				props.setWriteMode(0)
			})
	}

	const subject = (d,s) => {
		switch (s) {
			case 'main' : {
				if (d === 'PRO' || d === 'MAS') {
					return `${d === 'PRO' ? '우수제품' : 'MAS'} / 주품목`
				} return 'SW / 응용과학용 소프트웨어'
			}
			case 'sub' : {
				if (d === 'PRO' || d === 'MAS') {
					return `${d === 'PRO' ? '우수제품' : 'MAS'} / 추가선택품목`
				} return 'SW / 통신 소프트웨어'
			}
		}
	}

	const onUpdate = () => {
		window.popupManager.open(Confirm, {
			contents: '수정하시겠습니까?',
			onConfirm: () => {
				props.setWriteMode(3)
				props.setUpdateNumber(props.detail)
			}
		})
	}


	return (
		<>
			<div className="title_box clear">
				<h2 className="title left">조달우수 상세</h2>

				<ul className="right">
					<li>HOME</li>
					<li>조달우수 관리</li>
					<li>조달우수 상세</li>
				</ul>
			</div>

			<div className="table_wrap">
				<table className="type2 notice_detail txt_content">
					<colgroup>
						<col width="259px"/>
						<col width="*"/>
						<col width="259px"/>
						<col width="*"/>
					</colgroup>
					<tbody>
					<tr>
						<th>구분/품목</th>
						<td>{subject(data.division, data.subject)}</td>
						<th>물품식별번호</th>
						<td>{data.ino}</td>
					</tr>
					<tr>
						<th>품명</th>
						<td>{data.product_name}</td>
						<th>모델명</th>
						<td>{data.model_name}</td>
					</tr>
					<tr>
						<th>기자재</th>
						<td>{data.equipment}</td>
						<th>단가(원)</th>
						<td>{data.price}</td>
					</tr>
					<tr>
						<th>제품(상세/구성)규격</th>
						<td colSpan="3">{data.product_standard}</td>
					</tr>
					</tbody>
				</table>

				<div className="btn_center martop_20">
					<button type="button" className="blue_btn w_100 confirm_btn" onClick={()=>onUpdate()}>수정</button>
					<button type="button" className="white_btn w_100" onClick={()=>props.setWriteMode(0)}>목록으로</button>
				</div>
			</div>
		</>
	)
}

export default DetailProcurement
