import react,{useState, useEffect, useRef} from 'react'
import logo from 'AdminAssets/assets/images/logo.png'
import AdminApi from '../../../Api/AdminApi'
import {useHistory} from 'react-router-dom'
import Alert from '../../../Modal/Component/Alert'

const Login = () => {

	const idRef = useRef()
	const pwdRef = useRef()
	const history = useHistory()
	const saveIdRef = useRef()

	useEffect(()=> {
		if(saveIdRef.current && localStorage.getItem('saveId') && idRef.current )	{
			saveIdRef.current.checked = true
			idRef.current.value = localStorage.getItem('saveId')
		}
	},[])

	const postLogin = () => {



		AdminApi.postLogin(idRef.current.value, pwdRef.current.value)
			.then(res => {
				sessionStorage.setItem('auth',res.data.data.token)
				sessionStorage.setItem('userInfo',JSON.stringify(res.data.data.admin))
				saveIdRef.current.checked ? localStorage.setItem('saveId',idRef.current.value) : localStorage.removeItem('saveId')

				history.push('/admin/main')
			})
			.catch(e => {
				window.popupManager.open(Alert, {
					contents: '로그인 실패되었습니다.<br />입력 정보를 확인해주세요'
				})
			})
	}

	return (
		<div id="wrap">
			<main className="login">
				<div className="login_box">
					<div>
						<h2 className="login_logo">
							<img src={logo} alt="IntelliVIX logo" />
						</h2>
						<p className="fs_43 f_ebold txt_center col_black martop_33">관리자로그인</p>
						<p className="fs_19 col_grey2 txt_center martop_10">인텔리빅스 홈페이지 관리 시스템입니다.</p>

						<div className="form martop_30">
							<p>
								<i className="user_id_icon" />
								<input id="userId" type="text" className="user_id w_full" placeholder="아이디를 입력해주세요." ref={idRef} />
							</p>
							<p className="martop_15">
								<i className="user_pw_icon" />
								<input id="userPw" type="password" className="user_pw w_full" placeholder="비밀번호를 입력해주세요." ref={pwdRef} />
							</p>

							<input type="checkbox" className="ch_check hide" id="inpChk1" ref={saveIdRef} />
							<label htmlFor="inpChk1" className="label marleft_10 martop_20">아이디 저장</label>

							<p className="martop_17">
								<button className="blue_btn login w_full f_ebold alert_btn" onClick={()=>postLogin()}>로그인</button>
								{/*}	<!-- 비활성화 스타일입니다. -->
								<!-- <button class="blue_btn login w_full f_ebold alert_btn disabled">로그인</button> --> */}
							</p>
						</div>
						<p className="martop_17 col_grey2 fs_16">※ 로그인 실패 시 관리자에게 문의해주세요.</p>
					</div>
					<div className="login_footer">
						<p>Copyright 2021 Intellivix Co., Ltd. All rights reserved.</p>
					</div>
				</div>


				<div className="modal_wrap alert">
					<div className="modal_section">
						<button className="hide_btn delete_modal" />
						<p className="modal_text" />
						<div className="btn_wrap">
							<button type="button" className="blue_btn w_100 delete_modal">확인</button>
						</div>
					</div>
				</div>
			</main>
		</div>
	)
}

export default Login
