import React,{useEffect, useState} from 'react';
import NewsApi from '../../../../Api/NewsApi'
import News from './index'
import NoticeApi from '../../../../Api/NoticeApi'

const DetailNews = (props) => {

	const [imagePath, setImagePath] = useState('')
	const [data, setData] = useState({})

	useEffect(() => {
		NewsApi.getImage(props.detail)
			.then(res=> {
				const url = window.URL.createObjectURL(new Blob([res.data]))
				setImagePath(url)
			})
		let params = { no: props.detail }
		NewsApi.getDetailNews(params)
			.then(res => {
				setData(res.data.data)
			})


	}, [])



	const deleteNews = () => {
		NewsApi.deleteNews([props.detail])
			.then(()=> {
				props.setWriteMode(0)
			})
	}


	return (
		<div className="table_wrap">
			<table className="type2 notice_detail txt_content">
				<colgroup>
					<col width="259px" />
						<col width="*" />
				</colgroup>
				<tbody>
				<tr>
					<th>제목</th>
					<td>
						{data.title}
					</td>
				</tr>
				<tr>
					<th>썸네일 업로드</th>
					<td>
						<div className="upload_box clear">
							<div className="thumbnail_box">
								<img src={imagePath} alt="thumbnail" />
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<th>연결 URL</th>
					<td>
						{data.url}
					</td>
				</tr>
				<tr>
					<th>보도일</th>
					<td>
						{data.reportDate}
					</td>
				</tr>
				</tbody>
			</table>

			<div className="btn_center martop_20">
				<button type="button" className="blue_btn w_100" onClick={()=> {props.setWriteMode(3)
					props.setUpdateNumber(props.detail)
				}}>수정</button>
				<button type="button" className="white_btn w_100" onClick={()=> deleteNews()}>삭제</button>
				<button type="button" className="white_btn w_100" onClick={()=>props.setWriteMode(0)}>목록으로</button>
			</div>
		</div>
	)
}

export default DetailNews
