import logo from './logo.svg'
import React, { useEffect } from 'react'
import { withPopups } from 'Libs/popupManager'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import 'AdminAssets/assets/css/datepicker.css'
import AdminRouter from 'Router/AdminRouter'
import MainRouter from './Router/MainRouter'
import { useTranslation } from 'react-i18next'
window.location.pathname.startsWith('/admin') ? import('AdminAssets/assets/css/common.css') : import('MainAssets/assets/css/all.css')
// window.location.pathname.startsWith('/main') && import('MainAssets/assets/css/all.css')

// const mediaQuery = window.matchMedia('(max-width: 1024px)')
//
// async function handleTabletChange(e) {
// 	// Check if the media query is true
// 	e.matches && await
// }
// handleTabletChange(mediaQuery)
// // Register event listener
// mediaQuery.addListener(handleTabletChange)
//
// // Initial check
//
//

const App = withPopups()((props) => {
	const { t, i18n} = useTranslation()

	useEffect(() => {
		window.popupManager = props.popupManager
		//CommonAxios.defaults.baseURL = window.winScript.Call_BaseUrl()
		localStorage.getItem('language') && i18n.changeLanguage(localStorage.getItem('language'))
		console.log(window.location)		
				
		// if (window.location && window.location.protocol === 'http:') {
		// 	let arrLoc = window.location.href.replace('http','https')
		// 	if (arrLoc.includes("com")) {
		// 		arrLoc = window.location.href.replace("com", "ai");
		// 	} 			
		// 	window.location.replace(arrLoc)
		// }
		// else {			
		// 	if (window.location.href.includes("com")) {
		// 		let arrLoc = window.location.href.replace("com", "ai");				
		// 		window.location.replace(arrLoc);
		// 	}
		// }

	}, [])

	return (
		<BrowserRouter>
			<Route exact path="/admin*" component={AdminRouter}  />
			<Route exact path="/*" component={MainRouter} />

		</BrowserRouter>
	)
})

export default App
