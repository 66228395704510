import React, { useEffect, useRef, useState } from 'react'
import { Map, MapMarker } from 'react-kakao-maps-sdk'
import { useTranslation } from 'react-i18next'

const useKakaoMap = () => {

	const { kakao } = window

	const {t, i18n} = useTranslation()
	const [markers, setMarkers] = useState({})
	const [map, setMap] = useState()

	const mapRef = useRef()

	useEffect(() => {
		if (!map) return
		const geocoder = new kakao.maps.services.Geocoder()
		geocoder.addressSearch('서울특별시 서초구 효령로34길 4, 6층', (r, s) => {
			if (s === kakao.maps.services.Status.OK) {
				const coords = new kakao.maps.LatLng(r[0].y, r[0].x)

				setMarkers({
					position: {
						lat: r[0].y,
						lng: r[0].x
					}
				})
				map.setCenter(coords)
			}

		})
		mapRef.current && observeSize()
	}, [map])

	useEffect(()=> {
		mapRef.current && observeSize()
	},[])

	const observeSize = () => {
		const ro = new ResizeObserver(entries => {
			entries.forEach(entry => {
				const { width, height } = entry.contentRect
				map && map.relayout();
			})
		})

		ro.observe(mapRef.current)
	}

	return (
		<div className="map_img" ref={mapRef}>
			{/*<img src={map} alt="" className="img_map" />*/}
			<Map className="map_img" style={i18n.language === 'en' ? {width: '100%', height: '35rem'} : {width: '100%'}} center={{ lat: 33.5563, lng: 126.79581 }} onCreate={setMap} level={2}>
				{markers && markers.position && <MapMarker position={markers.position}>
					<div>
						<div style={{color:"#000", fontSize: '15px'}}>서울 서초구 효령로34길</div>
					</div>
				</MapMarker>}
			</Map>
		</div>
	)
}

export default useKakaoMap
