import React, { useEffect, useState } from 'react'
import useDataSearch from '../../../../Hooks/useDataSearch'

const SW = (props) => {

	const [selectedState, setSelectedState] = useState('main')
	const SELECT1 = [
		{ label: '물품식별번호', value: 'ino' },
		{ label: '모델명', value: 'model_name' },
		{ label: '단가', value: 'price' }
	]
	// 	<option>물품식별번호</option>
	// <option>모델명</option>
	// <option>기자재</option>
	// <option>단가</option>


	const [main, setMain] = useState([])
	const [sub, setSub] = useState([])

	const data1 = {
		select : SELECT1,
		data: props.data1 && props.data1,
		setState: setMain
	}

	const data2 = {
		select : SELECT1,
		data: props.data2 && props.data2,
		setState: setSub
	}

	const [component1, component2] = useDataSearch(data1,data2, selectedState)


	useEffect(() => {
		props.data1 && setMain(props.data1)
	}, [props.data1, selectedState])

	useEffect(() => {
		props.data2 && setSub(props.data2)
	}, [props.data2, selectedState])
	return (
		<div id="business_panel3" className="business_panel">
			<div className="position_nav">
				<ul className="sub_nav">
					<li>조달우수</li>
					<li>SW</li>
				</ul>
				<h2 className="sub_title">상용소프트웨어(S/W)</h2>
			</div>

			<div className="small_tab_btn martop_70">
				<button data-target="article_panel5" className={selectedState === 'main' ? 'active' : ''} onClick={()=> setSelectedState('main')}>응용<span className="M_disnone">과학용 소프트웨어</span>
				</button>
				<button data-target="article_panel6" className={selectedState === 'sub' ? 'active' : ''} onClick={()=> setSelectedState('sub')}>통신 <span className="M_disnone">소프트웨어</span></button>
			</div>

			{/* SW - 응용 */}
			<div id="article_panel5" className={selectedState !== 'main' ? 'article_panel dis_none' : ''}>
				<div className="clear">
					{component1}
				</div>

				<div className="table_type1 article martop_40">
					<div className="thead">
						<span className="w_200 M30per">물품식별번호</span>
						<span className="w_240 M40per">모델명</span>
						<span className="w_minus450 M_disnone">제품규격</span>
						<span className="w_200 M30per">단가</span>
					</div>

					<div className="tbody">
						{main.length !== 0 && main.map(item => (<div className="tbody_tr" key={item.no}>
							<span className="w_200 M30per">{item.ino}</span>
							<span className="w_240 M40per">{item.model_name}</span>
							<span className="w_minus450 txt_left M_disnone">{item.product_standard}</span>
							<span className="w_200 M30per txt_right">{item.price}</span>
						</div>))}
					</div>
				</div>
			</div>

			{/* SW - 통신 */}
			<div id="article_panel6" className={selectedState !== 'sub' ? 'article_panel dis_none' : ''}>
				<div className="clear">
					{component2}
				</div>

				<div className="table_type1 article martop_40">
					<div className="thead">
						<span className="w_200 M30per">물품식별번호</span>
						<span className="w_220 M40per">모델명</span>
						<span className="w_minus620 M_disnone">제품규격</span>
						<span className="w_200 M30per">단가</span>
					</div>

					<div className="tbody">
						{sub.length !== 0 && sub.map(item =>
							(<div className="tbody_tr" key={item.no}>
							<span className="w_200 M30per">{item.ino}</span>
							<span className="w_220 M40per">{item.model_name}</span>
							<span className="w_minus620 txt_left M_disnone">{item.product_standard}</span>
							<span className="w_200 M30per txt_right">{item.price}</span>
						</div>)
						)}
					</div>
				</div>
			</div>

		</div>
	)
}

export default SW
