import React from 'react'
import sign from 'MainAssets/assets/images/ceo_name.png'
import sign_eng from 'MainAssets/assets/images/ceo_name_eng.png'
import { useTranslation } from 'react-i18next'

const CEOMessage = () => {

	const { t, i18n } = useTranslation()

	 return i18n.language === 'ko' ? (
		<>
			<div className="ceo_box">
				<div className="sub_con">
					<p className="title_txt">앞선 경쟁력, 최고의 가치를 전달하는<br/>Vision AI 선도기업 인텔리빅스</p>
					<div className="ceo_name">
						<span className="line_txt">인텔리빅스 대표이사</span>
						<img src={sign} alt=""/>
					</div>
				</div>
			</div>

			<div className="ceo_txt sub_con">
				<p className="ceo_txtTit">CCTV 관련 기술의 급속한 발달과 인공지능 기술의 발달로 인하여<span className="dis_block">이제껏 그 누구도 경험하지 못했던 빠른 IT 서비스 및 환경의 변화를 맞고 있습니다.</span>
				</p>
				<p>기업의 비즈니스 활동에서부터 일상 생활의 편리함에 이르기까지 모든 분야에서 CCTV 및 인공지능 기술을 빼놓고 이야기 할 수 없는 중요한 부분이 되었습니다.</p>
				<p>창립 후 현재까지 저희는 Vision AI 원천 기술 개발 전문기업으로서 성장해 왔으며, 최고의 연구인력, 풍부한 경험, 다양한 노하우를 기반으로 영상분석 관련 전문기업으로서의 경쟁력 확보를 위해
					끊임없는 도전을 하여 왔습니다.</p>
				<p>우리에게 변화는 또 하나의 도전이며 세상의 중심에서 남들보다 조금 더 앞으로 나아갈 수 있도록 성공적인 변화와 발전을 위해 노력할 것이며, 저희는 고객, 주주, 구성원이 모두 만족할 수 있도록
					지속적으로 노력하고 있습니다.</p>
				<p>기술력과 신뢰성이 검증된 영상분석 관련 제품을 바탕으로 과감한 조직 혁신과 고객에 대한 서비스를 더욱 밀착시켜 행복과 더 나은 미래를 위한 글로벌 전문 강소 기업으로 거듭나겠습니다.</p>
				<p>건강한 기업문화 속에 스스로 가치를 창조하는 창의정신과 실천적 도전 정신을 바탕으로 고객에게 신뢰받는 든든한 기업인 인텔리빅스의 새로운 변화와 도전을 애정과 관심을 가지고 지켜봐 주십시오.</p>
				<p>감사합니다.</p>
			</div>
		</>
	) : (
		<>
		 <div className="ceo_box">
			 <div className="sub_con">
				 <p className="title_txt">Specialized in Vision AI Technology</p>
				 <div className="ceo_name">
					 <span className="line_txt">CEO</span>
					 <img src={sign_eng} alt="" />
				 </div>
			 </div>
		 </div>

	<div className="ceo_txt sub_con">
		<p className="ceo_txtTit">Thank you for visiting website of intellivix Co.,Ltd.</p>
		<p>At present, the development of CCTV related technology and artificial intelligence technology has brought about
			rapid IT service and environment change that no one has ever experienced. From the business activities of the
			company to the conveniences of our life, CCTV and artificial intelligence technologies have become an important
			part of story.</p>
		<p>intellivix has been continuously challenging to secure competitiveness as a professional image analysis company
			based on the best research personnel, rich experience and various know-how. Change is another challenge for us,
			and we will strive for successful change and development so that we can go a little further than others at the
			center of the world. We are constantly striving to ensure that our customers, shareholders and members are
			satisfied.</p>
		<p>We will become a global professional company for happiness and a better future by further strengthening our
			organizational innovation and customer service with our technical and reliable video analysis related
			products.</p>
		<p>Please keep in mind the new changes and challenges of the company that is trusted by the customers with the
			spirit of creativity that creates value for themselves and the spirit of practical challenge.</p>
		<p>Thank you.</p>
	</div>
			</>
	 )
}

export default CEOMessage
