import React,{useState, useEffect} from 'react'
import NewsApi from '../../../Api/NewsApi'
import CommonAxios from '../../../Axios/CommonAxios'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const NewsRoom = () => {

	const [data, setData] = useState([])
	const [page, setPage] = useState(0)
	const [totalPage, setTotalPage] = useState(0)

	const [emptyText, setEmptyText] = useState('')

	const { t, i18n} = useTranslation()

	useEffect(()=> {
		let params = {
			page,
			size: 4
		}
		NewsApi.getNews(params)
			.then(res => {
				if (res.data.data.news.length > 0 || res.data.data.count > 0) {
					setData(data.concat(res.data.data.news))
				} else {
					setEmptyText('등록된 소식이 없습니다')
				}

				setTotalPage(res.data.data.allPage)
			})
	},[page])

	const NewsList = () => data.map(item => {
		return (
			<li key={item.no}>
				<div className="img_box"><img src={CommonAxios.defaults.baseURL+`/api/file/${item.no}`} alt=""/></div>
				<div className="list_txt">
					<em>{item.reportDate}</em>
					<p>{item.title}</p>
					<a href={item.url}  target='_blank'>View Detail</a>
				</div>
			</li>
		)
	})

	return (
		<main className="sub_page kor">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>{i18n.language === 'ko' ? '뉴스룸' : 'Newsroom'}</li>
				</ul>
				<h2 className="sub_title">{i18n.language === 'ko' ? '뉴스룸' : 'Newsroom'}</h2>
				<p className="title_txt">{i18n.language === 'ko' ? '언론 속 인텔리빅스 소식입니다.' : 'Latest News'}</p>
				<ul className="news_list clear">
					{!emptyText ? <NewsList/> : <li className="news_empty">{emptyText}</li>}
				</ul>
				<div className="btn_center martop_40 clear">
					{!emptyText && <button className="skyblue_btn w_130"
					         onClick={() => page + 1 < totalPage && setPage(page + 1)}>{i18n.language === 'ko' ? '더보기' : 'Read More'}</button>
					}				</div>
			</div>

			<div className="sub_imgBox img2">
				<div className="main_middle">
					<em className="img_tit">{i18n.language === 'ko' ? 'Vision AI 전문 기업 인텔리빅스' : 'Vision AI company IntelliVIX'}</em>
					<p className="img_txt">{i18n.language === 'ko' ? '앞선 경쟁력, 최고의 가치를 전달하는 Vision AI 선도기업 인텔리빅스' : "Specialized in Vision AI Technology"}</p>
				</div>
			</div>

		</main>
)
}

export default NewsRoom
