import CommonAxios from '../Axios/CommonAxios'

export default {

	writeNews (data) {
		return CommonAxios(({
			url: '/api/news/write',
			method: 'POST',
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}))
	},

	getNews (params) {
		return CommonAxios(({
			url: 'api/news/list',
			method: 'GET',
			params
		}))
	},

	getImage (newsNo) {
		return CommonAxios(({
			url: 'api/file/'+newsNo,
			method: 'GET',
			responseType: 'blob'
		}))
	},

	getDetailNews (params) {
		return CommonAxios(({
			url: 'api/news/detail',
			method: 'GET',
			params
		}))
	},
	deleteNews (data) {
		return CommonAxios(({
			url: `api/news/delete`,
			method: 'DELETE',
			data
		}))
	},

	updateNews (data) {
		return CommonAxios(({
			url: 'api/news/modify',
			method: 'PUT',
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}))
	}




}
// /api/file/{newsNo}
// /api/news/delete
// 	/api/news/list
// /api/news/modify
// /api/news/write
