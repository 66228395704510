import React, { useEffect, useState } from 'react'
import usePage from '../../../../Hooks/usePage'
import useProList from '../../../../Hooks/useProList'
import useProSearch from '../../../../Hooks/useProSearch'
import useInstallSearch from '../../../../Hooks/useInstallSearch'
import useInstallList from '../../../../Hooks/useInstallList'
import ProApi from '../../../../Api/ProApi'
import Alert from '../../../../Modal/Component/Alert'
import Confirm from '../../../../Modal/Component/Confirm'
import InstallApi from '../../../../Api/InstallApi'
import PostProcurement from '../Procurement/PostProcurement'
import DetailProcurement from '../Procurement/DetailProcurement'
import PostInstall from './PostInstall'
import DetailInstall from './DetailInstall'

const Install = () => {

	const [writeMode, setWriteMode] = useState(0)

	const [data, setData] = useState([])

	const [pageCount, setPageCount] = useState(1)
	const [listCount, setListCount] = useState(0)
	const [allChecked, setAllChecked] = useState(false)
	const [updateNumber, setUpdateNumber] = useState(-1)

	const [selectNo, setSelectNo] = useState(null)
	const [pageComponent, selectedPage] = usePage(pageCount)

	const [emptyText, setEmptyText] = useState('')

	const [list, checkList] = useInstallList(data, setSelectNo, setWriteMode, listCount, selectedPage, allChecked)
	const [searchComponent, startDate, endDate, searchValue, searchType] = useInstallSearch(writeMode)

	useEffect(() => {
		setPageCount(1)
	}, [startDate, endDate])

	useEffect(() => {
		writeMode === 0 && getData(startDate, endDate, searchValue, searchType, selectedPage)
	}, [writeMode])

	useEffect(() => {
		getData(startDate, endDate, searchValue, searchType, selectedPage)
	}, [startDate, endDate, searchValue, searchType, selectedPage])

	const getData = (startDate, endDate, searchValue, searchType, page) => {
		let params = { startDate, endDate, searchValue, searchType, page }
		InstallApi.searchList(params)
			.then(res => {
				if (res.data.data.install && (res.data.data.install.length > 0 || listCount > 0)) {
					setData(res.data.data.install)
				} else {
					setEmptyText('등록된 설치파일 무결성 정보가 없습니다.. <br /> 설치파일 무결성 등록버튼을 통해 등록해주세요.')
				}
				setPageCount(res.data.data.allPage)
				setListCount(res.data.data.count)
			})
	}

	useEffect(() => {
		console.log(selectNo)
	}, [selectNo])

	const deleteList = () => {
		if (checkList.length === 0) {
			window.popupManager.open(Alert, {
				contents: '삭제할 게시물을 선택해주세요'
			})
		} else {
			window.popupManager.open(Confirm, {
				contents: '삭제하시겠습니까?',
				onConfirm: () => {
					InstallApi.deleteInstall(checkList)
						.then(res => {
							window.popupManager.open(Alert, {
								contents: '삭제가 완료되었습니다.',
								onClose: () => {
									getData('', '', '', '', selectedPage)
									setPageCount(1)
								}
							})
						})
				}
			})
		}
	}

	return (
		writeMode === 0 ? <>
			<div className="title_box clear">
				<h2 className="title left">설치파일 무결성 관리</h2>

				<ul className="right">
					<li>HOME</li>
					<li>설치파일 무결성 관리</li>
				</ul>
			</div>

			{!emptyText && searchComponent}

			<div className="table_wrap martop_20">

				{ !emptyText ? <table className="type1 notice_list tdpd_5 onclick">
					<colgroup>
						<col width="50px"/>
						<col width="50px"/>
						<col width="100px"/>
						<col width="140px"/>
						<col width="*"/>
						<col width="100px"/>
					</colgroup>
					<thead>
					<tr>
						<th>
							<input type="checkbox" name="inpChk" className="ch_check hide allcheck" id={'allChk'}
							       onChange={() => setAllChecked(!allChecked)}/>
							<label htmlFor="allChk" className="label single"/>
						</th>
						<th>번호</th>
						<th>등록일</th>
						<th>모델명</th>
						<th>버전</th>
						<th>방식</th>
						<th>해쉬값</th>
						<th>등록자</th>
					</tr>
					</thead>
					<tbody>
					{list}
					</tbody>
				</table> : <li dangerouslySetInnerHTML={{__html: emptyText}} />}

				{ !emptyText && pageComponent}

				<div className="btn_bottom btn_center">
					{!emptyText &&<button type="button" className="white_btn w_70 confirm_btn" onClick={() => deleteList()}>삭제</button>
					}					<button type="button" className="blue_btn w_180" onClick={() => {
						setWriteMode(1)
					}}>설치파일 무결성 등록</button>
				</div>
			</div>
		</> : writeMode === 1 || writeMode === 3 ? <PostInstall writeMode={writeMode} setWriteMode={setWriteMode} updateNumber={updateNumber} /> :
			<DetailInstall detail={selectNo}
			                   setWriteMode={setWriteMode} setUpdateNumber={setUpdateNumber}/>
	)
}

export default Install
