import React, { useEffect, useState } from 'react'
import ProApi from '../../../../Api/ProApi'
import Confirm from '../../../../Modal/Component/Confirm'
import InstallApi from '../../../../Api/InstallApi'

const DetailInstall = (props) => {

	const [data, setData] = useState({})

	useEffect(()=> {
		let params = { no: props.detail }
		InstallApi.detailInstall(params)
			.then(res => {
				setData(res.data.data)
			})
	},[props.detail])

	const deleteNotice = () => {
		let no = [props.detail]
		InstallApi.deleteInstall(no)
			.then(()=> {
				props.setWriteMode(0)
			})
	}


	const onUpdate = () => {
		window.popupManager.open(Confirm, {
			contents: '수정하시겠습니까?',
			onConfirm: () => {
				props.setWriteMode(3)
				props.setUpdateNumber(props.detail)
			}
		})
	}

	return (
		<>
			<div className="title_box clear">
				<h2 className="title left">설치파일 무결성 상세</h2>

				<ul className="right">
					<li>HOME</li>
					<li>설치파일 무결성 관리</li>
					<li>설치파일 무결성 상세</li>
				</ul>
			</div>

			<div className="table_wrap">
				<table className="type2 notice_detail txt_content">
					<colgroup>
						<col width="259px"/>
						<col width="*"/>
						<col width="259px"/>
						<col width="*"/>
					</colgroup>
					<tbody>
					<tr>
						<th>모델명</th>
						<td>{data.model_name}</td>
						<th>방식</th>
						<td>{data.type}</td>
					</tr>
					<tr>
						<th>버전</th>
						<td colSpan="3">{data.version}</td>
					</tr>
					<tr>
						<th>해쉬값</th>
						<td colSpan="3">{data.hash}</td>
					</tr>
					</tbody>
				</table>

				<div className="btn_center martop_20">
					<button type="button" className="blue_btn w_100 confirm_btn"  onClick={()=>onUpdate()}>수정</button>
					<button type="button" className="white_btn w_100" onClick={()=>props.setWriteMode(0)}>목록으로</button>
				</div>
			</div>
		</>
	)

}

export default DetailInstall
