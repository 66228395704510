import React from 'react';
import CommonAxios from '../../../../Axios/CommonAxios'

const AIEdgeBox = () => {

	const findImage = (path) => {
		let imgArray = path.split('images/')
		let img = imgArray[imgArray.length - 1]

		return require(`MainAssets/assets/images/${img}`)
	}

	const downloadPDF = () => {

	}
  
	const download = () => {

		const pdfUrl = 'https://intellivix.ai/VIXAI_0400.pdf';

	   // 임시 링크 엘리먼트 생성
	   const link = document.createElement('a');
	   link.href = pdfUrl;
	   link.download = 'VIXAI-0400.pdf'; // 다운로드될 파일명
	   document.body.appendChild(link);
	   
	   // 링크 클릭하여 다운로드 시작
	   link.click();
	   
	   // 임시 링크 엘리먼트 제거
	   document.body.removeChild(link);
	};

	const download2 = () => {

		const pdfUrl = 'https://intellivix.ai/AI_Edge_Box.pdf';

	   // 임시 링크 엘리먼트 생성
	   const link = document.createElement('a');
	   link.href = pdfUrl;
	   link.download = 'AI_Edge_Box.pdf'; // 다운로드될 파일명
	   document.body.appendChild(link);
	   
	   // 링크 클릭하여 다운로드 시작
	   link.click();
	   
	   // 임시 링크 엘리먼트 제거
	   document.body.removeChild(link);
	};
	return (
		<main className="sub_page kor">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>제품과 기술</li>
					<li>AI Edge Box</li>
				</ul>
				{/*  20240221 텍스트 수정('AI Edge Box' -> 'AI Edge Box(VIXOne)') */}
				<h2 className="sub_title">AI Edge Box(VIXOne)</h2>
			</div>

			<div className="ai_img">
				<div className="business_txt">
					<p>별도의 서버를 거치지 않고 디바이스 자체에서 다수의 카메라에 대한 딥러닝 영상분석 기능 수행을 지원하는 엣지형 영상분석 장치입니다.<br/><br/>
						네트워크가 연결된 엣지에 설치해 CCTV카메라로 부터 수신된 영상을 영상 분석 장치를 통해 사람, 차량을 검출/추적/분류하며 설정된 영상분석 이벤트 발생 시 탐지 및 표출하여 사용자에게 실시간
						알림 기능을 제공합니다.<br/><br/>
						인텔리빅스가 보유한 서버 기반의 AI 영상분석 기술을 AI Edge Box에 집약해 구현함으로써 AI Edge Box에서 침입감지, 화재감지, 산업안전 이벤트, PTZ 자동 추적 등 다양한
						영상분석 이벤트를 감지할 수 있습니다.<br/><br/>이벤트를 엣지에서 검출할 경우 불필요한 영상 전송을 최소화할 수 있기 때문에 네트웍 트래픽 부하를 낮추며 운용 센터의 비용 절감에 기여할
						수 있습니다.</p>
						{/* 20240221 nep 인증마크 추가 */}
					<div className="nep_mark">
						<img src={findImage("../assets/images/nepmark.png")} alt=""/>
						<p>NEP(New Excellent Product) 인증</p>
					</div>
				</div>
			</div>

			<div className="ai_con">
				<div className="sub_con">
					<strong className="ai_tit">시스템 구성도</strong>
					<img src={findImage("../assets/images/ai_chart.png")} alt="" />
				</div>
			</div>

			<div className="ai_list sub_con">
				<div className="clear">
					<div className="ai_txt left w_half">
						<strong>비전AI 영상분석</strong>
						<p>
							- 딥러닝 기반 고성능 영상분석 지원<br/>
							- DNN 기반 검출, 보행자, 차량 종류 분류 ,번/얼굴 인식 지원<br/>
							- 목적에 맞는 다양한 이벤트 분석 기능 지원
						</p>
						<div className="ai_txtimg">
							<img src={findImage("../assets/images/ai_img01.png")} alt="" />
						</div>
					</div>
					<div className="ai_txt left w_half">
						<strong>Simple & Easy</strong>
						<p>
							- 편리하고 직관적인 웹 기반 인터페이스 제공<br/>
							- 언제 어디서나 편리한 설치
						</p>
						<div className="ai_txtimg">
							<img src={findImage("../assets/images/ai_img02.png")} alt="" />
						</div>
					</div>
				</div>
				<div className="ai_txt">
					<strong>자동PTZ 추적 지원</strong>
					<p>
						- 마스터 카메라에서 객체 감지 및 추적 후 슬레이브 카메라로 감지된 객체 추적하여 확대하는 PTZ 자동 추적 및 제어 지원<br/>
						- 프리셋 이동 및 투어링 동시 지원
					</p>
					<div className="clear">
						<div className="ai_btmimg left">
							<span className="camera_tag">고정형 카메라</span>
							<img src={findImage("../assets/images/ai_img03.png")} alt="" />
								<em>이벤트 감지</em>
						</div>
						<div className="ai_btmimg right">
							<span className="camera_tag">PTZ 카메라</span>
							<img src={findImage("../assets/images/ai_img04.png")} alt="" />
								<em>감지된 객체 추적</em>
						</div>
					</div>
				</div>
			</div>

			<div className="catalogue_con">
				<div className="sub_con">
					{/* 20240221 텍스트 수정('카탈로그' -> '다운로드') */}
					<strong className="catalogue_tit">다운로드</strong>
					<p>인텔리빅스의 AI Edge Box를 더 자세히 알아보고 싶다면 카탈로그를 다운로드 해보세요.</p>
					<div className="btn_box">
					{/* <a href={CommonAxios.defaults.baseURL+`/api/file/pdf`}><button>소개자료 다운로드</button></a> */}
					<button onClick={download2}>소개자료 다운로드</button>
					{/* 20240221 매뉴얼 다운로드 버튼 추가 및 수정 */}
					{/* <a href={CommonAxios.defaults.baseURL+`/VIXAI-0400.pdf`} download="VIXAI-0400.pdf"><button className="btn_green">매뉴얼 다운로드</button></a> */}
					<button className="btn_green" onClick={download}>매뉴얼 다운로드</button>
					</div>
				</div>
			</div>


		</main>
	)
}

export default AIEdgeBox
