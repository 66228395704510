import { useEffect } from 'react'
import { useShowModal } from '../../Hooks/useShowModal'

//
// function getLastPopup () {
// 	const openPopups = window.popupManager.popups.filter((popup) => popup.isOpen)
// 	return openPopups.slice(-1)[0]
// }

const StackModal = (props) => {

	const { isShow, showModal, blindModal } = useShowModal()

	useEffect(() => {
		window.addEventListener('popstate', onPageMove)
		showModal()
		const currentPopup = window.popupManager.getLastPopup()
		window.popupManager.subscribeOnPopupsChange(() => {
			const lastPopup = window.popupManager.getLastPopup()
			lastPopup === currentPopup ? showModal() : blindModal()
		})
		window.jQuery(document).on("click", "#mainDimLayer", function (e) {
			blindModal()
			props.onClose();
		});
	}, [])

	const onPageMove = () => {
		blindModal()
		props.onClose()
	}

	return (
		props.isOpen && isShow ?
			<>
				<div id='mainDimLayer' />
				{props.children}
			</> : null
	)
}

export default StackModal
