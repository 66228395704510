import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { notOnlyUseKR, onlyUseKR } from '../../../Reducer/useOnlyKR'
import InstallApi from '../../../Api/InstallApi'

const Install = () => {

	const dispatch = useDispatch()

	const [data, setData] = useState([])


	useEffect(()=> {
		dispatch(onlyUseKR(true))
		InstallApi.getListInstall()
			.then(res => {
				setData(res.data.data)
			})

		return () => {
			dispatch(notOnlyUseKR(false))
		}

	},[])


	return (
		<main className="sub_page kor">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>인텔리빅스</li>
					<li>고객문의</li>
					<li>설치파일 무결성 확인</li>
				</ul>
				<h2 className="sub_title">설치파일 무결성 확인</h2>
				<p className="title_txt">설치파일 무결성 확인 정보를 전달합니다.</p>

				<div className="scroll_1000">
					<div className="table_type1 article martop_40">
						<div className="thead">
							<span className="w_150">모델명</span>
							<span className="w_200">버전</span>
							<span className="w_100">방식</span>
							<span className="w_minus450">해쉬값</span>
						</div>
						<div className="tbody">

							{/*	<!-- 20220303 -->
								<!-- <div class="tbody_tr">
								<span>등록된 공지사항이 없습니다.</span>
								</div> --> */}

							{data.length === 0 ? (<div className="tbody_tr">
								<span>등록된 설치파일 정보가 없습니다.</span>
							</div>) : data.map(item => (
								<div className="tbody_tr" key={item.no}>
									<span className="w_150">{item.model_name}</span>
									<span className="w_200">{item.version}</span>
									<span className="w_100">{item.type}</span>
									<span className="w_minus450">{item.hash}</span>
								</div>
								)
							)}
						</div>
					</div>
				</div>

				{/* *<!-- <ul class="paging">
					<li class="paging_first"><a href=""></a></li>
					<li class="paging_prev"><a href=""></a></li>
					<li class="paging_num active"><a href="">1</a></li>
					<li class="paging_num"><a href="">2</a></li>
					<li class="paging_num"><a href="">3</a></li>
					<li class="paging_num"><a href="">4</a></li>
					<li class="paging_num"><a href="">5</a></li>
					<li class="paging_next"><a href=""></a></li>
					<li class="paging_last"><a href=""></a></li>
					</ul> --> */}
			</div>

			<div className="sub_imgBox img1">
				<div className="main_middle">
					<em className="img_tit">Vision AI 전문 기업 인텔리빅스</em>
					<p className="img_txt">앞선 경쟁력, 최고의 가치를 전달하는 Vision AI 선도기업 인텔리빅스</p>
				</div>
			</div>

		</main>
	)
}

export default Install
