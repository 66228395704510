import React, { useEffect, useState } from 'react'

const useAdminList = (List, count, page, allChk, selectNo, detailAdmin) => {

	const [checkList, setCheckList] = useState([])

	useEffect(() => {
		if (window.jQuery('[id^=\'inpChk\']').length > 0) {
			window.jQuery('[id^=\'inpChk\']').map(item => {
				window.jQuery('[id^=\'inpChk\']')[item].checked = allChk
			})
			allChk ? setCheckList(checkList.concat(List.map(item => (item.no)))) : setCheckList([])
		}
	}, [allChk])

	const checkTest = (no, e) => {
		e.target.checked ? setCheckList(checkList.concat(no)) : setCheckList(checkList.filter(item => item !== no))
	}

	return ([List.map((item, index) => (<tr key={item.no}>
			<td>
				<input type="checkbox" name="inpChk" className="ch_check hide" id={`inpChk${item.no}`}
				       onChange={(e) => checkTest(item.no, e)}/>
				<label htmlFor={`inpChk${item.no}`} className="label single"/>
			</td>
			<td>{count - (page * 10 + index)}</td>
			<td>{item.create_date}</td>
			<td onClick={()=>{selectNo(item.no)
				detailAdmin(true)
			}

			}>{item.name}</td>
			<td>{item.grade === 1 ? '마스터' : '일반'}</td>
			<td>{item.phone}</td>
			<td>{item.email}</td>
		</tr>)), checkList]
	)
}

export default useAdminList
