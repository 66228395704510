import CommonAxios from '../Axios/CommonAxios'

export default {
	postNotice (data) {
		return CommonAxios(({
			url: '/api/notice/write',
			method: 'POST',
			data
		}))
	},

	getNotice (params) {
		return CommonAxios(({
			url: 'api/notice/list',
			method: 'GET',
			params
		}))
	},

	detailNotice (no) {
		return CommonAxios(({
			url: `api/notice/`,
			method: 'GET',
			params: {
				no
			}
		}))
	},
	deleteNotice (data) {
		return CommonAxios(({
			url: `api/notice/delete`,
			method: 'DELETE',
			data
		}))
	},

	modifyNotice (data) {
		return CommonAxios(({
			url: `api/notice/modify`,
			method: 'PUT',
			data
		}))
	},

	mainDetailNotice (no) {
		return CommonAxios(({
			url: 'api/notice/detail',
			method: 'GET',
			params: {
				no
			}
		}))
	}
}
