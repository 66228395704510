import React, { useEffect } from 'react'
import highFlier01 from 'MainAssets/assets/images/icon/highflier_01.png'
import highFlier02 from 'MainAssets/assets/images/icon/highflier_02.png'
import highFlier03 from 'MainAssets/assets/images/icon/highflier_03.png'
import highFlier04 from 'MainAssets/assets/images/icon/highflier_04.png'
import { useDispatch } from 'react-redux'
import { notOnlyUseKR, onlyUseKR } from '../../../Reducer/useOnlyKR'

const Introduction = () => {

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(onlyUseKR(true))

		return () => {
			dispatch(notOnlyUseKR(false))
		}

	}, [])

	return (
		<main className="sub_page kor">
			<div className="sub_con">
				<ul className="sub_nav">
					<li>인재채용</li>
				</ul>
				<h2 className="sub_title">인재채용</h2>
			</div>

			<div className="highflier_box">
				<h3>인재상</h3>
				<ul className="highflier_list sub_con">
					<li>
						<div>
							<img src={highFlier01} alt=""/>
							<strong>정직</strong>
							<p>정직으로 고객을<br/>움직이는 인재</p>
						</div>
					</li>
					<li>
						<div>
							<img src={highFlier02} alt=""/>
							<strong>열정</strong>
							<p>열정으로 미래를<br/>창조하는 인재</p>
						</div>
					</li>
					<li>
						<div>
							<img src={highFlier03} alt=""/>
							<strong>창의력</strong>
							<p>창의력으로<br/>기회를 만드는 인재</p>
						</div>
					</li>
					<li>
						<div>
							<img src={highFlier04} alt=""/>
							<strong>융합</strong>
							<p>융합하여<br/>하나될 수 있는 인재</p>
						</div>
					</li>
				</ul>
			</div>

			<div className="culture_box">
				<div className="sub_con">
					<h3>복리후생</h3>
					<p>인텔리빅스는 직원들에게 다양한 복리후생을 제공하고 있습니다.<br/>임직원 모두 행복하게 일할 수 있는 회사를 만들어 나가기 위하여 최선을 다하겠습니다.</p>
					<ul className="culture_list">
						<li>
							<div>
								<span>01</span>
								{/* 20240221 텍스트 수정('샌드위치 휴일 제도 운영' -> '슈퍼샌드위치 데이 (개인연차 차감 없는 전사 공통휴무)')  */}
								<p>슈퍼샌드위치 데이<br/>(개인연차 차감 없는<br/>전사 공통휴무)</p>
							</div>
						</li>
						<li>
							<div>
								<span>02</span>
								<p>대명 / 한화리조트<br/>아난티코브 예약 지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>03</span>
								<p>휴양시설 이용비<br/>지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>04</span>
								<p>경조 휴가 및<br/>경조금 지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>05</span>
								<p>장기근속자<br/>휴가 및 포상 지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>06</span>
								<p>매달 우수사원 포상<br/>인텔리빅스<br/>영웅상제도(연 1회)</p>
							</div>
						</li>
						<li>
							<div>
								<span>07</span>
								<p>부모<br/>부양금 지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>08</span>
								<p>자녀 입학<br/>축하금 지급</p>
							</div>
						</li>
						<li>
							<div>
								<span>09</span>
								{/* 20240221 텍스트 수정('생일 상품권 지급' -> '생일 휴가 & 상품권 지급') */}
								<p>생일 휴가 &amp; 상품권<br/>지급</p>
							</div>
						</li>
						<li>
							<div>
								<span>10</span>
								<p>크리스마스<br/>선물 지급</p>
							</div>
						</li>
						<li>
							<div>
								<span>11</span>
								<p>명절 기념<br/>선물 제공</p>
							</div>
						</li>
						<li>
							<div>
								<span>12</span>
								<p>다양한 교육 프로그램<br/>직무교육(OJT)<br/>외국어 교육 지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>13</span>
								<p>전시회/세미나<br/>참관 지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>14</span>
								<p>도서 구매비<br/>지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>15</span>
								<p>자격증 취득<br/>축하 지원금</p>
							</div>
						</li>
						<li>
							<div>
								<span>16</span>
								<p>직무보상<br/>특허 출원 장려/포상</p>
							</div>
						</li>
						<li>
							<div>
								<span>17</span>
								<p>다양한 행사 운영<br/>(산행, 무비데이, 사생대회, 워크샵 등)</p>
							</div>
						</li>
						<li>
							<div>
								<span>18</span>
								<p>동호회<br/>활동 지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>19</span>
								<p>추천 채용<br/>보상금 지급</p>
							</div>
						</li>
						<li>
							<div>
								<span>20</span>
								{/*230130 HJ 텍스트 수정('청년내일채움공제 운영' -> '임직원 건강검진 지원')*/}
								<p>임직원 건강검진 지원</p>
							</div>
						</li>
						<li>
							<div>
								<span>21</span>
								{/*230130 HJ 텍스트 수정('우수직원 스톡옵션' -> '신규입사자 웰컴키트 지급') */}
								<p>신규 입사자<br/>웰컴키트 지급</p>
							</div>
						</li>
						<li>
							<div>
								<span>22</span>
								{/* 20240221 텍스트 수정('탄력근무제 시행 (8-5/9-6/10-7) 자율선택' -> '시차출근제 시행(8-5/9-6/10-7)') */}
								<p>시차출퇴근제 시행<br/>(8-5/9-6/10-7)</p>
							</div>
						</li>
						<li>
							<div>
								<span>23</span>
								{/* 20240221 텍스트 수정('Family Friday 금요일 조기 퇴근 (월 1회)' -> '간식 & 커피 & 음료 무한 제공') */}
								<p>간식 &amp; 커피 &amp; 음료<br/>무한 제공</p>
							</div>
						</li>
						<li>
							<div>
								<span>24</span>
								{/* 20240221 텍스트 수정('간식 & 커피 & 음료 무한 제공' -> 'More +') */}
								<p>More +</p>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div className="sub_con txt_center">
				<p className="title_txt">인텔리빅스와 함께 Vision AI 기술로 더 나은 사회를 만들어갈 인재를 찾습니다.</p>
				<div className="introduction_box">
					<a
						href="https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=RWlZdHF4K1Y2bDlyTUkzRzhINUFkZz09"
						target="_blank">사람인 채용공고</a>
					<a href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/illisis3883" target="_blank">잡코리아 채용공고</a>
				</div>
			</div>
		</main>
	)
}

export default Introduction
